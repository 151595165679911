
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select';
import { Modal, Button, Form } from 'react-bootstrap';
import { Table } from 'react-bootstrap'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import companylogo from '../../../../assets/images/logo2.png';

function Leadprogressreport() {
    const [leadprogressingData, setLeadprogressingData] = useState([])
    const [users, setUsers] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [stateFilter, setStateFilter] = useState([]);
    const [cityFilter, setCityFilter] = useState([]);
    const [areaFilter, setAreaFilter] = useState([]);
    const [usernameFilter, setUsernameFilter] = useState([]);
    const [executiveFilter, setExecutiveFilter] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedLeadprogressingData, setSelectedLeadprogressingData] = useState(null);

    const fetchLeadprogressing = () => {
        axios.get('https://test-server-o94j.onrender.com/leadprocessing/prospect')
            .then(response => response.data)
            .then(data => setLeadprogressingData(data))
            .catch(error => console.error('Error fetching prospect', error))
    };

    const fetchUsers = () => {
        axios.get('https://test-server-o94j.onrender.com/user/users')
            .then(response => response.data)
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    };

    const fetchState = () => {
        axios.get('https://test-server-o94j.onrender.com/state/states')
            .then(response => response.data)
            .then(data => setState(data))
            .catch(error => console.error('Error fetching state:', error))
    }

    const fetchCity = () => {
        axios.get('https://test-server-o94j.onrender.com/city/cities')
            .then(response => response.data)
            .then(data => setCity(data))
            .catch(error => console.error('Error fetching cities', error))
    }

    const fetchArea = () => {
        axios.get('https://test-server-o94j.onrender.com/area/areas')
            .then(response => response.data)
            .then(data => setArea(data))
            .catch(error => console.error('Error fetching areas', error))
    }

    useEffect(() => {
        fetchLeadprogressing();
        fetchState();
        fetchCity();
        fetchArea();
        fetchUsers();
    }, [])

    const handleStateFilterChange = selectedOptions => {
        setStateFilter(selectedOptions);
    };

    const handleCityFilterChange = selectedOptions => {
        setCityFilter(selectedOptions);
    };

    const handleAreaFilterChange = selectedOptions => {
        setAreaFilter(selectedOptions);
    };

    const handleUsernameFilterChange = selectedOptions => {
        setUsernameFilter(selectedOptions);
    };

    const handleExecutiveChange = selectedOptions => {
        setExecutiveFilter(selectedOptions)
    };

    const handleStartDateChange = date => {
        setStartDate(date);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
    };

    const filteredItems = leadprogressingData.filter(item => {
        if (startDate && endDate) {
            const itemDateTime = new Date(item.createdAt).getTime();
            const startDateTime = new Date(startDate).getTime();
            const endDateTime = new Date(endDate).getTime();
            return itemDateTime >= startDateTime && itemDateTime <= endDateTime;
        }
        return true;
    }).filter(item =>
        (stateFilter.length === 0 || stateFilter.some(state => state.value === item.leadDetails.leadserveyDetails.State)) &&
        (cityFilter.length === 0 || cityFilter.some(city => city.value === item.leadDetails.leadserveyDetails.City)) &&
        (areaFilter.length === 0 || areaFilter.some(area => area.value === item.leadDetails.leadserveyDetails.Area)) &&
        (usernameFilter.length === 0 || usernameFilter.some(username => username.value === item.leadDetails.leadserveyDetails.username)) &&
        (executiveFilter.length === 0 || executiveFilter.some(username => username.value === item.leadDetails.username))
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleShowPreview = (leadprogressingData) => {
        setSelectedLeadprogressingData(leadprogressingData);
        setShowPreview(true);
    };

    const handleClosePreview = () => setShowPreview(false);

    const downloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });

        const logo = new Image();
        logo.src = companylogo;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        logo.onload = async function () {
            canvas.width = logo.width;
            canvas.height = logo.height;
            ctx.drawImage(logo, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            doc.addImage(dataURL, 'PNG', 5, 2, 18, 12);


            doc.setFontSize(12);
            const companyName = 'PRIME FRESH LIMITED (Vashi/DC)';
            const companyNameX = 24;
            const companyNameY = 6;
            doc.text(companyName, companyNameX, companyNameY);

            const companyNameWidth = doc.getTextWidth(companyName);
            const lineX1 = companyNameX;
            const lineX2 = companyNameX + companyNameWidth;
            const lineY = companyNameY + 1;
            doc.line(lineX1, lineY, lineX2, lineY);

            const leadReportText = 'Lead Prospect Reports';
            const leadReportTextX = companyNameX;
            const leadReportTextY = lineY + 5;
            doc.setFontSize(12);
            doc.text(leadReportText, leadReportTextX, leadReportTextY);

            doc.setFontSize(12)
            const currentDate = new Date().toLocaleDateString('en-IN');
            doc.text('Date: ' + currentDate, doc.internal.pageSize.width - 15, 7, null, null, 'right');

            const tableColumns = [
                "LsId", "LsDate", "BussinessName / CustomerName", "Area", "City", "State", "Mobile", "Email"
            ];
            const tableRows = [];
            filteredItems.forEach((item, index) => {
                const lsId = item.leadDetails.leadserveyDetails.BizCatge === 'General Trade' ? `GT/${item.leadDetails.leadserveyDetails.leadserveyid}` : `MT/${item.leadDetails.leadserveyDetails.leadserveyid}`;
                const lsDate = new Date(item.leadDetails.leadserveyDetails.createdAt).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: '2-digit' });
                const rowData = [
                    lsId,
                    lsDate,
                    `${item.leadDetails.leadserveyDetails.CustName} / ${item.leadDetails.leadserveyDetails.FName + item.leadDetails.leadserveyDetails.LName}`,
                    item.leadDetails.leadserveyDetails.Area,
                    item.leadDetails.leadserveyDetails.City,
                    item.leadDetails.leadserveyDetails.State,
                    { content: item.leadDetails.leadserveyDetails.MobNum, fontStyle: 'courier', fontSize: '1rem' },
                    item.leadDetails.leadserveyDetails.EmailId,
                ];
                tableRows.push(rowData);
            });

            const totalRecords = tableRows.length;
            const recordsPerPage = 43;

            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: leadReportTextY + 3,
                margin: { left: 5 },
                didParseCell: function (data) {
                    const cell = data.cell;
                    if (tableColumns.includes(data.cell.raw)) {
                        cell.styles.fillColor = [204, 204, 204];
                        cell.styles.textColor = [0, 0, 0];
                        cell.styles.fontSize = 8;
                        cell.styles.fontStyle = 'bold';
                        cell.styles.halign = 'center';

                    } else {
                        cell.styles.fillColor = [255, 255, 255];
                        cell.styles.textColor = [0, 0, 0];
                        cell.styles.fontSize = 6;
                        if (data.column.index === 1 || data.column.index === 6) {
                            cell.styles.font = 'courier';

                        }
                    }
                    cell.styles.lineWidth = 0.2;
                    cell.styles.lineColor = [0, 0, 0];
                },
                didDrawPage: function (data) {
                    const pageCount = doc.internal.getNumberOfPages();
                    const pageNumber = data.pageNumber;
                    const pageHeight = doc.internal.pageSize.height;

                    const startRecord = (pageNumber - 1) * recordsPerPage + 1;
                    const endRecord = Math.min(pageNumber * recordsPerPage, totalRecords);
                    const recordsText = `${startRecord} to ${endRecord}`;

                    const pageWidth = doc.internal.pageSize.width;
                    const marginRight = 15;
                    const marginLeft = 200;

                    doc.setFontSize(10);
                    doc.text(`Page ${pageNumber} of ${pageCount}`, pageWidth - marginLeft, pageHeight - 14, null, null, 'left');
                    doc.text(`No of Records: ${recordsText}`, pageWidth - marginRight, pageHeight - 14, null, null, 'right');
                }
            });

            doc.setLineWidth(0.5);
            doc.line(10, doc.internal.pageSize.height - 20, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 20);
            const preparedBy = 'admin';
            const currentDateFooter = new Date().toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });

            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const ipAddress = response.data.ip;
                const footerText = `Prepared by ${preparedBy} on ${currentDate} ${currentDateFooter} | IP Address: ${ipAddress}`;
                doc.setFontSize(10);
                doc.text('Prime Fresh Limited (Vashi/DC)', 10, doc.internal.pageSize.height - 9);
                doc.setFontSize(10);
                doc.text(footerText, 10, doc.internal.pageSize.height - 4);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }

            doc.save('lead_prospect_reports.pdf');
        };
    };

    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.book_new();

        const excelColumns = [
            "Sr.No", "LSID", "Date", "Time", "SubmittedBy", "CustName",
            "Since", "Yrs", "Biztype", "BizCatge", "FName", "LName", "Addr1", "Addr2",
            "State", "City", "Area", "Pin Code", "Country", "MobNum", "AltMobNum",
            "EmailId", "BizExp", "CurrAddSince", "BizHourFrom",
            "BizHourTo", "ApproxQtyBuyinKg", "DaPurVal",
            "KeySkus"
        ];

        XLSX.utils.sheet_add_aoa(worksheet, [excelColumns], { origin: 'A1' });

        const excelData = filteredItems.map((item, index) => {
            return [
                index + 1,
                item.leadDetails.leadserveyDetails.leadserveyid,
                new Date(item.createdAt).toLocaleDateString('en-IN'),
                new Date(item.createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
                item.leadDetails.username,
                item.leadDetails.leadserveyDetails.CustName,
                item.leadDetails.leadserveyDetails.Since,
                item.leadDetails.leadserveyDetails.Yrs,
                item.leadDetails.leadserveyDetails.BizType,
                item.leadDetails.leadserveyDetails.BizCatge,
                item.leadDetails.leadserveyDetails.FName,
                item.leadDetails.leadserveyDetails.LName,
                item.leadDetails.leadserveyDetails.Addr1,
                item.leadDetails.leadserveyDetails.Addr2,
                item.leadDetails.leadserveyDetails.State,
                item.leadDetails.leadserveyDetails.City,
                item.leadDetails.leadserveyDetails.Area,
                item.leadDetails.leadserveyDetails.PinCode,
                item.leadDetails.leadserveyDetails.Country,
                item.leadDetails.leadserveyDetails.MobNum,
                item.leadDetails.leadserveyDetails.AltMobNum,
                item.leadDetails.leadserveyDetails.EmailId,
                item.leadDetails.leadserveyDetails.BizExp,
                item.leadDetails.leadserveyDetails.CurrAddSince,
                item.leadDetails.leadserveyDetails.BizHourFrom,
                item.leadDetails.leadserveyDetails.BizHourTo,
                item.leadDetails.leadserveyDetails.ApproxQtyBuyinKg,
                item.leadDetails.leadserveyDetails.DaPurVal,
                item.leadDetails.leadserveyDetails.KeySkus.join(','),
            ];
        });
        XLSX.utils.sheet_add_aoa(worksheet, excelData, { origin: 'A2' });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Lead Survey Form');

        worksheet['!header'] = [
            '&CLead Processing Reports'
        ];

        XLSX.writeFile(workbook, 'lead_prospect_reports.xlsx');
    };

    const printUserDetails = () => {
        const printContent = document.getElementById('printleadprospect').innerHTML;
        const originalContent = document.body.innerHTML;

        const currentDate = new Date().toLocaleDateString('en-IN');

        const printableContentWithLogo = `
        <div style="font-family: Arial, sans-serif; margin-left: 20px;">
            <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                <img src="${companylogo}" alt="Company Logo" style="width: 100px; height: auto; margin-right: 20px;">
                    <div>
                        <h1 style="font-size: 18px; margin: 0;">Prime Fresh Limited (Vashi/DC)</h1>
                        <hr style="margin: 5px 0;">
                        <h2 style="font-size: 16px; margin: 0;">Lead Prospect Reports</h2>
                    </div>
                <div>
                    <h1 style="margin-left: 270px;">${currentDate}</h1>
                </div>
            </div>
            ${printContent}
        </div>
    `;
        const printableContentWithFooter = printableContentWithLogo;

        document.body.innerHTML = printableContentWithFooter;

        window.print();

        document.body.innerHTML = originalContent;
        window.location.reload();
    };


    function getWeekStartingDay() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = new Date().getDay();
        return days[currentDayIndex];
    }

    const currentTime = new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const currentDate = new Date().toLocaleDateString('en-IN');
    const weekStartingDay = getWeekStartingDay();

    return (
        <div>
            <h1 className='head' style={{ fontSize: '1.5rem' }}>LEAD-PROSPECT REPORTS</h1>
            <div className='leadserveyreport-table'>
                <div className='leadservey-pdfexcel-button'>
                    <Button onClick={downloadPDF}>
                        <i className="fa-solid fa-file-pdf fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={downloadExcel}>
                        <i className="fa-solid fa-file-excel fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={printUserDetails}>
                        <i className="fa-solid fa-print fa-fade fa-xl"></i>
                    </Button>
                </div>
                <div className='leadprocessingserveyreport-forms' style={{ fontSize: '0.8rem' }}>
                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter States</label>
                        <Select
                            className='mt-2 '
                            isMulti
                            placeholder='States'
                            options={state.map(state => ({
                                value: state.statename,
                                label: state.statename
                            }))}
                            value={stateFilter}
                            onChange={handleStateFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Cities</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Cities'
                            options={city.map(city => ({
                                value: city.cityname,
                                label: city.cityname
                            }))}
                            value={cityFilter}
                            onChange={handleCityFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Areas</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Areas'
                            options={area.map(area => ({
                                value: area.areaname,
                                label: area.areaname
                            }))}
                            value={areaFilter}
                            onChange={handleAreaFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Submitted</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Username'
                            options={users.map(user => ({
                                value: user.username,
                                label: user.username
                            }))}
                            value={usernameFilter}
                            onChange={handleUsernameFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Assigned</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Username'
                            options={users.map(user => ({
                                value: user.username,
                                label: user.username
                            }))}
                            value={executiveFilter}
                            onChange={handleExecutiveChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Prospect From :</label>
                        <Form.Group>
                            <Form.Control
                                className='mt-2'
                                type="date"
                                value={startDate}
                                onChange={(e) => handleStartDateChange(e.target.value)}
                            />
                        </Form.Group>
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Prospect To:</label>
                        <Form.Group>
                            <Form.Control
                                className='mt-2'
                                type="date"
                                value={endDate}
                                onChange={(e) => handleEndDateChange(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                </div>
                <Table className='mt-2' style={{ fontSize: '0.8rem' }} striped bordered hover responsive>
                    <thead>
                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <th>SR.NO</th>
                            <th>LSDATE</th>
                            <th>LSID</th>
                            <th>SUBMITTEDBY</th>
                            <th>ASSIGNEDDATE</th>
                            <th>ASSIGNEDTO</th>
                            <th>PROCESSDATE</th>
                            <th>BIZNAME</th>
                            <th>CUSTNAME</th>
                            <th>STATE</th>
                            <th>CITY</th>
                            <th>AREA</th>
                            <th>PREVIEW</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{new Date(item.leadDetails.leadserveyDetails.createdAt).toLocaleDateString('en-IN')}</td>
                                <td>{item.leadDetails.leadserveyDetails.leadserveyid}</td>
                                <td>{item.leadDetails.leadserveyDetails.username}</td>
                                <td>{new Date(item.leadDetails.createdAt).toLocaleDateString('en-IN')}</td>
                                <td>{item.leadDetails.username}</td>
                                <td>{new Date(item.leadDetails.date).toLocaleDateString('en-IN')}</td>
                                <td>{item.leadDetails.leadserveyDetails.CustName}</td>
                                <td>{item.leadDetails.leadserveyDetails.FName + item.leadDetails.leadserveyDetails.LName}</td>
                                <td>{item.leadDetails.leadserveyDetails.State}</td>
                                <td>{item.leadDetails.leadserveyDetails.City}</td>
                                <td>{item.leadDetails.leadserveyDetails.Area}</td>
                                <td>
                                    <button onClick={() => handleShowPreview(item)} className='btn btn-warning view'>
                                        <i className="fa-solid fa-eye fa-beat fa-xl"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="pagination-container">
                    {Array.from({ length: Math.ceil(filteredItems.length / itemsPerPage) }, (_, index) => (
                        <button
                            className="pagination-button mx-2"
                            key={index}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                <div className="total-records" style={{ textAlign: 'center', marginTop: '1rem', fontWeight: 'bold' }}>
                    Total No. of Records: {filteredItems.length}
                </div>
                <Modal show={showPreview} onHide={handleClosePreview} dialogClassName="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Lead Survey Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedLeadprogressingData && <LeadprogressingDataPreview leadprogressingData={selectedLeadprogressingData} />}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button variant="secondary" onClick={handleClosePreview}>
                            Back
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div id="printleadprospect" style={{ display: 'none', fontSize: '0.8rem' }}>
                    <Table>
                        <thead>
                            <tr style={{ fontSize: '0.8rem' }}>
                                <th>SR.NO</th>
                                <th>LSID</th>
                                <th>LSDATE</th>
                                <th>BussinessName / CustomerName</th>
                                <th>STATE</th>
                                <th>CITY</th>
                                <th>AREA</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredItems.map((item, index) => (
                                <tr key={index} style={{ fontSize: '0.5rem' }}>
                                    <td>{index + 1}</td>
                                    <td>{item.leadDetails.leadserveyDetails.leadserveyid}</td>
                                    <td>{new Date(item.leadDetails.leadserveyDetails.createdAt).toLocaleDateString('en-IN')}</td>
                                    <td>{item.leadDetails.leadserveyDetails.CustName} / {item.leadDetails.leadserveyDetails.FName + item.leadDetails.leadserveyDetails.LName}</td>
                                    <td>{item.leadDetails.leadserveyDetails.State}</td>
                                    <td>{item.leadDetails.leadserveyDetails.City}</td>
                                    <td>{item.leadDetails.leadserveyDetails.Area}</td>
                                    <td>{item.leadDetails.leadserveyDetails.EmailId}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="8" style={{ fontSize: '0.5rem' }}>
                                    Prepared by admin on {currentDate} {currentTime},{weekStartingDay}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

function LeadprogressingDataPreview({ leadprogressingData }) {
    return (
        <div style={{ fontSize: '0.8rem', display: 'flex', flexDirection: 'column' }}>
            <div className='form-details' style={{ lineHeight: '1' }}>
                <p className='company-details'>
                    <strong>Company Name : M/s.</strong> {leadprogressingData.leadDetails.leadserveyDetails.CustName} ({leadprogressingData.leadDetails.leadserveyDetails.Since})
                </p>
                <p className='bussiness-type'>
                    <strong>Bussiness Type : </strong> {leadprogressingData.leadDetails.leadserveyDetails.BizType}
                </p>
                <p className='bussiness-category'>
                    <strong>Bussiness Category : </strong> {leadprogressingData.leadDetails.leadserveyDetails.BizCatge}
                </p>
                <p className='bussiness-experience'>
                    <strong>Bussiness Experience : </strong> {leadprogressingData.leadDetails.leadserveyDetails.BizExp}
                </p>
                <p className='fl'>
                    <strong>Customer Name : Mr/Ms</strong> {leadprogressingData.leadDetails.leadserveyDetails.FName} {leadprogressingData.leadDetails.leadserveyDetails.LName}
                </p>
                <p className='addr1'>
                    <strong>Address :</strong>
                    <span className="address-data">
                        {leadprogressingData.leadDetails.leadserveyDetails.Addr1}, {leadprogressingData.leadDetails.leadserveyDetails.Addr2}
                    </span>
                </p>
                <p className='acpsc'>
                    {leadprogressingData.leadDetails.leadserveyDetails.Area}, {leadprogressingData.leadDetails.leadserveyDetails.City} - {leadprogressingData.leadDetails.leadserveyDetails.PinCode},  {leadprogressingData.leadDetails.leadserveyDetails.State} ({leadprogressingData.leadDetails.leadserveyDetails.Country})
                </p>
                <p className='MobNum'>
                    <strong>Mob :</strong> {leadprogressingData.leadDetails.leadserveyDetails.MobNum},  {leadprogressingData.leadDetails.leadserveyDetails.AltMobNum}
                </p>
                <p className='EmailId'>
                    <strong>Email</strong> {leadprogressingData.leadDetails.leadserveyDetails.EmailId}
                </p>
            </div>
            <hr />
            <div className='form-details2' style={{ lineHeight: '1' }}>
                <p className='current-address-since'>
                    <strong>Current Address Since Years : </strong> {leadprogressingData.leadDetails.leadserveyDetails.CurrAddSince}
                </p>
                <p className='bussiness-hour'>
                    <strong>Bussiness Hour : </strong> {leadprogressingData.leadDetails.leadserveyDetails.BizHourFrom} AM to {leadprogressingData.leadDetails.leadserveyDetails.BizHourTo} PM
                </p>
                <p className='approx-quantity-buy-in-kg'>
                    <strong>Approx Quantity Buy In Kg : </strong> {leadprogressingData.leadDetails.leadserveyDetails.ApproxQtyBuyinKg}
                </p>
                <p className='daily-purchase-value'>
                    <strong>Daily Purchase Value : </strong> {leadprogressingData.leadDetails.leadserveyDetails.DaPurVal}
                </p>
                <p className='key-skus'>
                    <strong>Key SKU's : </strong> {leadprogressingData.leadDetails.leadserveyDetails.KeySkus ? leadprogressingData.leadDetails.leadserveyDetails.KeySkus.join(',') : ''}
                </p>

            </div>
        </div>
    );
}

export default Leadprogressreport