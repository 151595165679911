import React, { useState, useEffect } from 'react';
import './Piechart.css';
import { Doughnut } from 'react-chartjs-2'; // Import Doughnut component
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function Piechart() {
    const [leadProcessingData, setLeadProcessingData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://test-server-o94j.onrender.com/leadprocessing/leadprocessing');
                const data = await response.json();
                setLeadProcessingData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const interestedCount = leadProcessingData.filter(item => item.interested).length;
    const notInterestedCount = leadProcessingData.filter(item => item.notInterested).length;

    const data = {
        labels: ['Interested', 'Not-Interested'],
        datasets: [
            {
                label: 'Leadservey Form',
                data: [interestedCount, notInterestedCount],
                backgroundColor: [
                    'rgba(7, 65, 240)',
                    'rgba(235, 70, 52)',
                ],
                borderColor: [
                    'rgba(250, 250, 250)',
                    'rgba(10, 10, 10)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Lead Survey Form',
                font: {
                    size: 16,
                },
            },
        },
    };

    return (
        <div className='piechart'>
            <Doughnut data={data} width={400} height={400} options={options} /> {/* Use Doughnut component */}
        </div>
    );
}

export default Piechart;


