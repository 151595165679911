import React, { useEffect } from 'react';
import { Carousel, Container, Row, Col, Button } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Testimonials.css';
import Banner1 from '../../../assets/images/leadsurvey.jpg';
import Banner2 from '../../../assets/images/farmers.jpg';

const Statistics = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <Container className='statistics-container mt-5' data-aos='fade-up' ref={ref}>
            <Row>
                <Col xs={12} md={3} className='statistic'>
                    <p>Employement Impact</p>
                    {inView && <h2><CountUp end={100000} duration={5} separator="," /></h2>}
                    <p>People Across India</p>
                </Col>
                <Col xs={12} md={3} className='statistic'>
                    <p>Farmer Connect</p>
                    {inView && <h2><CountUp end={110000} duration={5} separator="," /></h2>}
                    <p>Across 7 State</p>
                </Col>
                <Col xs={12} md={3} className='statistic'>
                    <p>Agriculture Markets</p>
                    {inView && <h2><CountUp end={85} duration={5} separator="," /></h2>}
                    <p>Across 18 State</p>
                </Col>
                <Col xs={12} md={3} className='statistic'>
                    <p>Trade Partner</p>
                    {inView && <h2><CountUp end={2400} duration={5} separator="," /></h2>}
                    <p>Across 8 State</p>
                </Col>
            </Row>
        </Container>
    );
};

const Testimonials = () => {
   
    useEffect(() => {
        AOS.init({ duration: 1200 });
    }, []);

    return (
        <div>
            <div className='leadsurvey'>
                <div className='leadsurvey-image' data-aos='fade-right'>
                    <img src={Banner1} alt='Lead Survey' />
                </div>
                <div className='leadsurvey-content' data-aos='fade-left'>
                    <div className='leadsurvey-heading'>
                        <h1>Lead Survey</h1>
                    </div>
                    <div className='leadsurvey-text'>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam sunt iste, totam error iure aspernatur recusandae pariatur id? Cumque quo assumenda nostrum! Excepturi optio, dicta eveniet quidem dolorem ipsa quis?</p>
                    </div>
                    <div className='leadsurvey-button'>
                        <Button>Login</Button>
                    </div>
                </div>
            </div>

            <Statistics />

            <div className='farming'>
                <div className='farming-image' data-aos='fade-left'>
                    <img src={Banner2} alt='Farmer Survey' />
                </div>
                <div className='farming-content' data-aos='fade-right'>
                    <div className='farming-heading'>
                        <h1>FARMER SURVEY</h1>
                    </div>
                    <div className='farming-text'>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam sunt iste, totam error iure aspernatur recusandae pariatur id? Cumque quo assumenda nostrum! Excepturi optio, dicta eveniet quidem dolorem ipsa quis?</p>
                    </div>
                    <div className='farming-button'>
                        <Button variant="success">Login</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
