
import React, { useState, useEffect } from 'react';
import './Userreportleadserveyform.css';
import axios from 'axios';
import Select from 'react-select';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { Table } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function Userreportleadserveyform() {
    const [leadserveyData, setLeadserveyData] = useState([]);
    const [users, setUsers] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [stateFilter, setStateFilter] = useState(null);
    const [cityFilter, setCityFilter] = useState(null);
    const [areaFilter, setAreaFilter] = useState(null);
    const [usernameFilter, setUsernameFilter] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const instance = axios.create({
        baseURL: 'https://test-server-o94j.onrender.com/',
        headers: { 'X-Custom-Header': 'foobar' }
    });

    const fetchleadservey = () => {
        const userid = localStorage.getItem('userid');
        instance.get('leadservey/leadserveys')
            .then(response => response.data.filter(item => item.userid === userid))
            .then(data => setLeadserveyData(data))
            .catch(error => console.error('Error fetching leadservey:', error));
    };

    const fetchState = () => {
        instance.get('state/states')
            .then(response => response.data)
            .then(data => setState(data))
            .catch(error => console.error('Error fetching state:', error))
    }

    const fetchCity = () => {
        instance.get('city/cities')
            .then(response => response.data)
            .then(data => setCity(data))
            .catch(error => console.error('Error fetching cities', error))
    }

    const fetchArea = () => {
        instance.get('area/areas')
            .then(response => response.data)
            .then(data => setArea(data))
            .catch(error => console.error('Error fetching areas', error))
    }

    const fetchAreaByCity = (cityId) => {
        instance.get(`https://test-server-o94j.onrender.com/area/area/${cityId}`)
            .then(response => response.data)
            .then(data => setArea(data))
            .catch(error => console.error('Error fetching areas by city:', error))
    }

    const fetchUsers = () => {
        instance.get('user/users')
            .then(response => response.data)
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    };

    useEffect(() => {
        fetchleadservey();
        fetchUsers();
        fetchState();
        fetchCity();
        fetchArea();
    }, []);

    const handleStateFilterChange = selectedOption => {
        setStateFilter(selectedOption);
    };

    const handleCityFilterChange = selectedOption => {
        setCityFilter(selectedOption);
        if (selectedOption) {
            fetchAreaByCity(selectedOption.cityid);
        } else {
            fetchArea();
        }
    };

    const handleAreaFilterChange = selectedOption => {
        setAreaFilter(selectedOption);
    };

    const handleUsernameFilterChange = selectedOption => {
        setUsernameFilter(selectedOption);
    };

    const handleStartDateChange = date => {
        setStartDate(date);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
    };

    const filteredItems = leadserveyData.filter(item => {
        if (startDate && endDate) {
            const itemDateTime = new Date(item.createdAt).getTime();
            const startDateTime = new Date(startDate).getTime();
            const endDateTime = new Date(endDate).getTime();
            return itemDateTime >= startDateTime && itemDateTime <= endDateTime;
        }
        return true;
    }).filter(item =>
        (!stateFilter || stateFilter.value === item.State) &&
        (!cityFilter || cityFilter.value === item.City) &&
        (!areaFilter || areaFilter.value === item.Area) &&
        (!usernameFilter || usernameFilter.value === item.username)
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div className='userreportleadserveyfrom'>
            <Header />
            <h5 style={{ fontSize: '1rem', marginLeft: '1%' }}>LEAD-SERVEY REPORTS</h5>
            <div className='userreportleadserveyfrom-table'>
                <div className='userreportleadserveyfrom-forms' style={{ fontSize: '0.6rem' }}>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} className='mt-2 leadserveyfilterdate' md="2" controlId="startDate">
                                <Form.Label style={{ fontSize: '0.8rem' }}>From Date</Form.Label>
                                <Form.Control
                                    className='mt-2'
                                    style={{ fontSize: '0.6rem' }}
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => handleStartDateChange(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='mt-2 leadserveyfilterdate' md="2" controlId="endDate">
                                <Form.Label style={{ fontSize: '0.8rem' }}>To Date</Form.Label>
                                <Form.Control
                                    className='mt-2'
                                    style={{ fontSize: '0.6rem' }}
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => handleEndDateChange(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='mt-2 leadserveyfiltercity' md="4" controlId="cityFilter">
                                <Form.Label style={{ fontSize: '0.8rem' }}>Fliter city</Form.Label>
                                <Select
                                    className='mt-2 custom-select' // Add a custom class for styling
                                    styles={{ // Add custom styles to override default height
                                        control: (provided) => ({
                                            ...provided,
                                            height: '30px', // Adjust the height as needed
                                            minHeight: '30px', // Adjust the minHeight as needed
                                        }),
                                    }}
                                    placeholder='City'
                                    options={city.map(city => ({
                                        value: city.cityname,
                                        label: city.cityname,
                                        cityid: city.cityid
                                    }))}
                                    value={cityFilter}
                                    onChange={handleCityFilterChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='mt-2 leadserveyfilterarea' md="4" controlId="areaFilter">
                                <Form.Label style={{ fontSize: '0.8rem' }}>Fliter area</Form.Label>
                                <Select
                                    className='mt-2 custom-select' // Add a custom class for styling
                                    styles={{ // Add custom styles to override default height
                                        control: (provided) => ({
                                            ...provided,
                                            height: '30px', // Adjust the height as needed
                                            minHeight: '30px', // Adjust the minHeight as needed
                                        }),
                                    }}
                                    placeholder='Area'
                                    options={area.map(area => ({
                                        value: area.areaname,
                                        label: area.areaname
                                    }))}
                                    value={areaFilter}
                                    onChange={handleAreaFilterChange}
                                />
                            </Form.Group>

                        </Row>
                    </Form>
                </div>
                <Table style={{ fontSize: '0.6rem' }} striped bordered hover responsive>
                    <thead>
                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <th>Sr.No.</th>
                            <th>LS.ID</th>
                            <th>LS.Date</th>
                            {/* <th>Time</th> */}
                            <th>BussinessName</th>
                            <th>CustomerName</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Area</th>
                            <th>MobileNumber</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <td>{index + 1}</td>
                                <th>{item.leadserveyid}</th>
                                <td>{new Date(item.createdAt).toLocaleDateString('en-IN')}</td>
                                {/* <td>{new Date(item.createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</td> */}
                                <th>{item.CustName}</th>
                                <th>{item.FName} {item.LName}</th>
                                <th>{item.State}</th>
                                <th>{item.City}</th>
                                <th>{item.Area}</th>
                                <th>{item.MobNum}</th>
                                <th>
                                    <Link to={`/particular-leadservey-reports/${item.leadserveyid}`}>
                                        <button className='btn btn-warning view'>
                                            <i className="fa-solid fa-eye fa-beat fa-lg"></i>
                                        </button>
                                    </Link>
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="pagination-container">
                    {Array.from({ length: Math.ceil(filteredItems.length / itemsPerPage) }, (_, index) => (
                        <button
                            className="pagination-button mx-2"
                            key={index}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                <div className="total-records" style={{ textAlign: 'center', marginTop: '1rem', fontWeight: 'bold' }}>
                    Total No. of Records: {filteredItems.length}
                </div>
            </div>
            <div className='footers'>
                <Footer />
            </div>
        </div>
    )
}

export default Userreportleadserveyform;




