import React, { useState, useEffect } from 'react'
import './Area.css';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import companylogo from '../../assets/images/logo2.png';

function Area() {
    const [rowData, setRowData] = useState([]);
    const [newArea, setNewArea] = useState('');
    const [city, setCity] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [editArea, setEditArea] = useState(null);
    const [editedAreaName, setEditedAreaName] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const fetchAreas = () => {
        axios.get('https://test-server-o94j.onrender.com/area/areas')
            .then(response => response.data)
            .then(async data => {
                const dataWithSerialNumber = await Promise.all(data.map(async item => {
                    const cityResponse = await axios.get(`https://test-server-o94j.onrender.com/city/viewcity/${item.cityid}`)
                    const cityname = cityResponse.data.cityname;

                    return { ...item, srNo: item.srNo, cityname }
                }));
                setRowData(dataWithSerialNumber)
            })
            .catch(error => {
                if (error.response && error.response.status == 404) {
                    setRowData([])
                } else {
                    console.error('Error fetching data:', error);
                }
            })
    };

    const fetchcity = () => {
        axios.get('https://test-server-o94j.onrender.com/city/cities')
            .then(response => response.data)
            .then(data => setCity(data))
            .catch(error => console.error('Error fetching city:', error))
    }

    useEffect(() => {
        fetchAreas()
        fetchcity()
    }, [])

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredItems = rowData.filter(area => area.areaname.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pagenumber) => setCurrentPage(pagenumber)

    const handleAddArea = () => {
        if (newArea.trim() !== '' && selectedCity.trim() !== '') {
            const selectedCityObject = city.find(city => city.cityname === selectedCity)

            if (selectedCityObject) {
                const cityid = selectedCityObject.cityid

                axios.get('https://test-server-o94j.onrender.com/area/areas')
                    .then(response => response.data)
                    .then(data => {
                        const maxAreaId = data.reduce((max, area) => {
                            const areaId = parseInt(area.areaid);
                            return areaId > max ? areaId : max;
                        }, 0)

                        const generateAreaId = (maxAreaId + 1).toString();

                        return axios.post('https://test-server-o94j.onrender.com/area/add-area', {
                            areaid: generateAreaId,
                            areaname: newArea,
                            cityname: selectedCity,
                            cityid: cityid,
                        });
                    })
                    .then(() => {
                        setNewArea('');
                        setSelectedCity('');
                        fetchcity('')
                    })
                    .catch(error => console.error('Error adding city:', error));
            }
        }
    }

    const handleUpdateArea = (area) => {
        setEditArea(area);
        setEditedAreaName(area.areaname);
        setShowEditModal(true);
    }

    const confirmEditArea = () => {
        axios.post('https://test-server-o94j.onrender.com/area/update-area', {
            areaid: editArea.areaid,
            newAreaName: editedAreaName,
        })
            .then(() => {
                fetchAreas();
                setEditArea(null)
                setShowEditModal(false)
            })
            .catch(error => console.error('Error updating area:', error))
    };

    const handleDeleteArea = (area) => {
        setDeleteConfirmation(area)
        setShowDeleteModal(true)
    };

    const confirmDeleteArea = () => {
        axios.post('https://test-server-o94j.onrender.com/area/delete-area', {
            areaid: deleteConfirmation.areaid,
        })
            .then(() => {
                fetchAreas();
                setDeleteConfirmation(null)
                setShowDeleteModal(false)
            })
            .catch(error => console.error('Error deleting area:', error))
    };

    const cancelDeleteArea = () => {
        setDeleteConfirmation(null);
        setShowDeleteModal(false);
    }

    const handleResetAreaInput = () => {
        setNewArea('');
        setSelectedCity('')
    }

    const imageToBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            };
            img.onerror = (err) => {
                reject(err);
            };
        });
    };

    const downloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait', // Adjust orientation to fit more content horizontally
            unit: 'mm', // Set measurement unit to millimeters
            format: 'a4' // Set page format to A4
        });

        // Convert company logo to data URL
        const logo = new Image();
        logo.src = companylogo;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        logo.onload = async function () {
            canvas.width = logo.width;
            canvas.height = logo.height;
            ctx.drawImage(logo, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            doc.addImage(dataURL, 'PNG', 5, 2, 18, 12);

            // Add company name on the left side
            doc.setFontSize(12);
            const companyName = 'PRIME FRESH LIMITED (Vashi/DC)';
            const companyNameX = 24;
            const companyNameY = 6;
            doc.text(companyName, companyNameX, companyNameY);

            // Get the width of the company name
            const companyNameWidth = doc.getTextWidth(companyName);

            // Draw line below company name
            const lineX1 = companyNameX;
            const lineX2 = companyNameX + companyNameWidth;
            const lineY = companyNameY + 1; // Move the line slightly below the text
            doc.line(lineX1, lineY, lineX2, lineY);

            // Add "Lead Survey Reports" text under the line
            const leadReportText = 'Areas Reports';
            const leadReportTextX = companyNameX; // Adjust the position as needed
            const leadReportTextY = lineY + 5; // Move the text below the line
            doc.setFontSize(12);
            doc.text(leadReportText, leadReportTextX, leadReportTextY);

            doc.setFontSize(12)
            const currentDate = new Date().toLocaleDateString('en-IN');
            doc.text('Date: ' + currentDate, doc.internal.pageSize.width - 15, 7, null, null, 'right');

            // Generate table
            const tableColumns = [
                "Sr.No", "Area Id", "Area Name", "City Name"
            ];
            const tableRows = [];
            rowData.forEach((item, index) => {
                const rowData = [
                    index + 1,
                    item.areaid,
                    item.areaname,
                    item.cityname
                ];
                tableRows.push(rowData);
            });

            const totalRecords = tableRows.length;
            const recordsPerPage = 43;


            // Add table
            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: leadReportTextY + 3, // Start position of the table below "Lead Survey Reports" text
                margin: { left: 5 }, // Set margin-left
                didParseCell: function (data) {
                    const cell = data.cell;
                    if (tableColumns.includes(data.cell.raw)) {
                        // For table column headers (tableColumns)
                        cell.styles.fillColor = [204, 204, 204]; // Grey background color
                        cell.styles.textColor = [0, 0, 0]; // Black font color
                        cell.styles.fontSize = 9;
                        cell.styles.fontStyle = 'bold';
                        cell.styles.halign = 'center';

                    } else {
                        // For table rows (tableRows)
                        cell.styles.fillColor = [255, 255, 255]; // White background color
                        cell.styles.textColor = [0, 0, 0]; // Black font color
                        cell.styles.fontSize = 7;

                    }
                    cell.styles.lineWidth = 0.2;
                    cell.styles.lineColor = [0, 0, 0];
                },
                didDrawPage: function (data) {
                    const pageCount = doc.internal.getNumberOfPages();
                    const pageNumber = data.pageNumber;
                    const pageHeight = doc.internal.pageSize.height;

                    const startRecord = (pageNumber - 1) * recordsPerPage + 1;
                    const endRecord = Math.min(pageNumber * recordsPerPage, totalRecords);
                    const recordsText = `${startRecord} to ${endRecord}`;

                    const pageWidth = doc.internal.pageSize.width;
                    const marginRight = 15;
                    const marginLeft = 200;


                    doc.setFontSize(10);
                    doc.text(`Page ${pageNumber} of ${pageCount}`, pageWidth - marginLeft, pageHeight - 14, null, null, 'left');
                    doc.text(`No of Records: ${recordsText}`, pageWidth - marginRight, pageHeight - 14, null, null, 'right');
                }
            });

            // Add line above the footer
            doc.setLineWidth(0.5);
            doc.line(10, doc.internal.pageSize.height - 20, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 20);
            const preparedBy = 'admin';
            const currentDateFooter = new Date().toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });
            // Fetch IP address
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const ipAddress = response.data.ip;
                const footerText = `Prepared by ${preparedBy} on ${currentDate} ${currentDateFooter} | IP Address: ${ipAddress}`;
                doc.setFontSize(10);
                doc.text('Prime Fresh Limited (Vashi/DC)', 10, doc.internal.pageSize.height - 9);
                doc.setFontSize(10);
                doc.text(footerText, 10, doc.internal.pageSize.height - 4);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }

            // Save the PDF
            doc.save('areas_reports.pdf');
        };
    };

    const downloadExcel = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Areas Reports');

            // Adding company logo
            const logoBase64 = await imageToBase64(companylogo);
            const logoImage = workbook.addImage({
                base64: logoBase64,
                extension: 'png',
            });

            worksheet.addImage(logoImage, {
                tl: { col: 0, row: 0 },
                ext: { width: 150, height: 100 }
            });

            worksheet.mergeCells('A1:D1');
            worksheet.mergeCells('A2:C2');
            worksheet.mergeCells('D2');
            worksheet.mergeCells('A3:C3');

            const companyCell = worksheet.getCell('A2');
            companyCell.value = 'PRIME FRESH LIMITED (Vashi/DC)';
            companyCell.font = { size: 16, bold: true };
            companyCell.alignment = { vertical: 'middle', horizontal: 'center' };

            const currentDates = new Date().toLocaleDateString('en-IN');
            const companyCells = worksheet.getCell('D2');
            companyCells.value = `Date: ${currentDates}`;
            companyCells.font = { size: 16, bold: true };
            companyCells.alignment = { vertical: 'middle', horizontal: 'center' };

            const reportCell = worksheet.getCell('A3');
            reportCell.value = 'Areas Reports';
            reportCell.font = { size: 14, bold: true };
            reportCell.alignment = { vertical: 'middle', horizontal: 'center' };

            // Adding spacing rows
            worksheet.addRow([]);
            worksheet.addRow([]);

            // Defining columns
            const excelColumns = [
                { header: 'Sr.No', key: 'srno', width: 20 },
                { header: 'Area ID', key: 'areaid', width: 20 },
                { header: 'Area Name', key: 'areaname', width: 50 },
                { header: 'City Name', key: 'cityname', width: 50 },
            ];

            worksheet.columns = excelColumns;

            // Adding header row with styles
            const headerRow = worksheet.addRow(excelColumns.map(col => col.header));
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF000000' }
                };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });

            // Adding data rows
            filteredItems.forEach((item, index) => {
                const row = worksheet.addRow({
                    srno: index + 1,
                    areaid: item.areaid,
                    areaname: item.areaname,
                    cityname: item.cityname
                });
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });
            });

            // Adding footer
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString();
            const currentDateFooter = currentDate.toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });
            const preparedBy = 'admin';

            const response = await axios.get('https://api.ipify.org?format=json');
            const ipAddress = response.data.ip;

            const footerText = `Prepared by ${preparedBy} on ${formattedDate}, ${currentDateFooter}  | IP Address: ${ipAddress}`;

            // Add an empty row before footer
            worksheet.addRow([]);
            const footerRow = worksheet.addRow([footerText]);

            // Merge cells for the footer across all columns
            const lastRowNumber = footerRow.number;
            worksheet.mergeCells(`A${lastRowNumber}:D${lastRowNumber}`);

            // Center align the footer text
            footerRow.getCell(1).font = { italic: true };
            footerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

            // Adjust column widths
            worksheet.columns.forEach((column) => {
                if (column.header) {
                    column.width = Math.max(10, column.width, column.header.length + 5);
                }
            });

            // Generate and download the Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'areas_reports.xlsx';
            link.click();
        } catch (error) {
            console.error("Error generating Excel file:", error);
        }
    };

    const printStateDetails = () => {
        const printContent = document.getElementById('printstate').innerHTML;
        const originalContent = document.body.innerHTML;

        // Get the current date and week starting day
        const currentDate = new Date().toLocaleDateString('en-IN');

        const printableContentWithLogo = `
        <div style="font-family: Arial, sans-serif; margin-left: 20px;">
            <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                <img src="${companylogo}" alt="Company Logo" style="width: 100px; height: auto; margin-right: 20px;">
                    <div>
                        <h1 style="font-size: 18px; margin: 0;">Prime Fresh Limited (Vashi/DC)</h1>
                        <hr style="margin: 5px 0;">
                        <h2 style="font-size: 16px; margin: 0;">States Reports</h2>
                    </div>
                <div>
                    <h1 style="margin-left: 270px;">${currentDate}</h1>
                </div>
            </div>
            ${printContent}
            <div>
                <div class="print-footer">
                  <hr style="border: 0; border-top: 0.5px solid #000;">
                  <div style="padding: 5px 10px; text-align: left; font-size: 10px; margin-top: -10px;">
                  <p>Prime Fresh Limited (Vashi/DC)</p>
                  <p style="margin-top: -8px;">Prepared by admin on ${currentDate} ${currentTime},${weekStartingDay}</p>
               </div>
            </div>
        </div>
    `;


        // Combine printable content with the footer
        const printableContentWithFooter = printableContentWithLogo;

        // Replace the current body content with the printable content including the logo, company name, hr, and user details
        document.body.innerHTML = printableContentWithFooter;

        // Print the content
        window.print();

        // Restore the original content after printing
        document.body.innerHTML = originalContent;
        window.location.reload(); // Refresh the page to restore original content
    };

    // Function to get the starting day of the week
    function getWeekStartingDay() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = new Date().getDay();
        return days[currentDayIndex];
    }

    const currentTime = new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const currentDate = new Date().toLocaleDateString('en-IN');
    const weekStartingDay = getWeekStartingDay();

    return (
        <div>
            <h1 className='head'>AREA</h1>
            <div className='city'>
                <div className='city-box'>
                    <Form className='city-content' style={{ height: '50%', width: '30%', float: 'left', fontSize: '0.8rem', marginLeft: '0px' }}>
                        <Row>
                            <Form.Group>
                                <h5 style={{ fontSize: '1rem' }} className='mt-3'>AREA NAME</h5>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter area name'
                                    value={newArea}
                                    onChange={(e) => setNewArea(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <h6 className='mt-3'>SELECT CITY</h6>
                                <Form.Select className='mt-1'
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.target.value)}
                                >
                                    <option value='' disabled>Select a city</option>
                                    {city.map(city => (
                                        <option key={city.cityid} value={city.cityname}>
                                            {city.cityname}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <div className='city-header'>
                                <Button onClick={handleAddArea}>Add New</Button>
                                <Button className='mx-3' onClick={handleResetAreaInput}>Reset</Button>
                            </div>
                        </Row>
                    </Form>

                    <div className='city-table' style={{ height: '50%', width: '70%', float: 'right', fontSize: '0.8rem' }}>
                        <div className='category-pdfexcel'>
                            <Button onClick={downloadPDF}>
                                <i className="fa-solid fa-file-pdf fa-fade fa-xl"></i>
                            </Button>
                            <Button className='mx-2' onClick={downloadExcel}>
                                <i className="fa-solid fa-file-excel fa-fade fa-xl"></i>
                            </Button>
                            <Button className='mx-2' onClick={printStateDetails}>
                                <i className="fa-solid fa-print fa-fade fa-xl"></i>
                            </Button>
                        </div>
                        <div className='flex items-center justify-end rounded-5 mx-2'>
                            <input
                                type='text'
                                className='bg-#F8F9FC h-40 outline-none pl-13 w-350 rounded-5 placeholder-text-14 leading-20 font-normal'
                                placeholder='Search area name.....'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>SR.NO</th>
                                    <th>AREA</th>
                                    <th>CITY</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((area, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{indexOfFirstItem + index + 1}</td>
                                            <td>{area.areaname}</td>
                                            <td>{area.cityname}</td>
                                            <td>
                                                <a onClick={() => handleUpdateArea(area)}>
                                                    <i className="mx-3 fa-solid fa-pen fa-beat-fade fa-xl" style={{ color: 'green' }}></i>
                                                </a>
                                                <a onClick={() => handleDeleteArea(area)}>
                                                    <i className="mx-3 fa-solid fa-trash fa-beat-fade fa-xl" style={{ color: 'red' }}></i>
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className='pagination-container'>
                            {Array.from({ length: Math.ceil(rowData.length / itemsPerPage) }, (_, index) => (
                                <button
                                    className='pagination-button mx-2'
                                    key={index}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                {showEditModal && editArea && (
                    <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Area</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input
                                type="text"
                                value={editedAreaName}
                                onChange={(e) => setEditedAreaName(e.target.value)}
                                className="form-control"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={confirmEditArea}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

                {showDeleteModal && deleteConfirmation && (
                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete City</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to delete <strong>{deleteConfirmation.areaname}</strong>?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={cancelDeleteArea}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={confirmDeleteArea}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

            </div>
        </div>
    )
}

export default Area