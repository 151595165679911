import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Branches.css';
import { Button, Form, Row, Modal, Table } from 'react-bootstrap';

function DeleteConfirmationModal({ show, handleClose, handleDelete, branchName }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Branch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete <strong>{branchName}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function EditBranchModal({ show, handleClose, handleSave, branchName, setBranchName }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Branch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="text"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    className="form-control"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function Branches() {
    const [rowData, setRowData] = useState([]);
    const [newBranch, setNewBranch] = useState('');
    const [editBranch, setEditBranch] = useState(null);
    const [editedBranchName, setEditedBranchName] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [editModalShow, setEditModalShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const fetchBranches = () => {
        axios.get('https://test-server-o94j.onrender.com/branches/branches')
            .then(response => response.data)
            .then(data => {
                setRowData(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        fetchBranches();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredItems = rowData.filter(branch => 
        branch.branchname && branch.branchname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const confirmDeleteBranch = () => {
        axios.post('https://test-server-o94j.onrender.com/branches/delete-branch', {
            branchid: deleteConfirmation.branchid,
        })
            .then(() => {
                fetchBranches(); // Refresh the branch list
                setDeleteConfirmation(null);
            })
            .catch(error => console.error('Error deleting branch:', error));
    };

    const cancelDeleteBranch = () => {
        setDeleteConfirmation(null);
    };

    const handleDeleteBranch = (branch) => {
        setDeleteConfirmation(branch);
    };

    const handleAddBranch = () => {
        if (newBranch.trim() !== '') {
            axios.get('https://test-server-o94j.onrender.com/branches/branches')
                .then(response => response.data)
                .then(data => {
                    // Find the maximum branch ID
                    const maxBranchId = data.reduce((max, branch) => {
                        const branchId = parseInt(branch.branchid);
                        return branchId > max ? branchId : max;
                    }, 0);

                    // Calculate the next branch ID
                    const generatedBranchId = (maxBranchId + 1).toString();

                    return axios.post('https://test-server-o94j.onrender.com/branches/add-branch', {
                        branchid: generatedBranchId,
                        branchname: newBranch,
                    });
                })
                .then(() => {
                    setNewBranch('');
                    fetchBranches();
                })
                .catch(error => console.error('Error adding branch:', error));
        }
    };

    const handleUpdateBranch = (branch) => {
        setEditBranch(branch);
        setEditedBranchName(branch.branchname);
        setEditModalShow(true);
    };

    const confirmEditBranch = () => {
        axios.post('https://test-server-o94j.onrender.com/branches/update-branch', {
            branchid: editBranch.branchid,
            newBranchName: editedBranchName,
        })
        .then(() => {
            fetchBranches(); // Refresh the branch list
            setEditBranch(null);
            setEditModalShow(false);
        })
        .catch(error => console.error('Error updating branch:', error));
    };

    const handleResetBranchInput = () => {
        setNewBranch('');
    };

    return (
        <div>
            <h1 className='head'>BRANCHES</h1>
            <div className='branches'>
                <div className='branch-box'>
                    <Form className='branch-content' style={{ height: '50%', width: '30%', float: 'left', fontSize: '0.8rem', marginLeft: '0px' }}>
                        <Row>
                            <Form.Group>
                                <h5 style={{ fontSize: '1rem' }} className='mt-2'>BRANCH NAME</h5>
                                <Form.Control
                                    required
                                    style={{ fontSize: '0.8rem' }}
                                    type="text"
                                    placeholder="Enter Branch Name"
                                    value={newBranch}
                                    onChange={(e) => setNewBranch(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <div className='branch-header'>
                                <Button onClick={handleAddBranch}>Add BRANCH</Button>
                                <Button onClick={handleResetBranchInput} className='mx-2'>Reset</Button>
                            </div>
                        </Row>
                    </Form>
                    <div className='branch-table' style={{ height: '50%', width: '70%', float: 'right' }}>
                        <div className='flex items-center justify-end rounded-5 mx-2'>
                            <input
                                type='text'
                                className='bg-#0fbf15 h-40 outline-none pl-13 w-35 rounded-5 placeholder-text-14 leading-20 font-normal'
                                placeholder='Search branch name...'
                                value={searchTerm}
                                style={{ fontSize: '0.8rem' }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <Table style={{ fontSize: '0.8rem' }}>
                            <thead>
                                <tr>
                                    <th>SR. NO.</th>
                                    <th>BRANCH NAME</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((branch, index) => (
                                    <tr key={index}>
                                        <td>{indexOfFirstItem + index + 1}</td>
                                        <td>{branch.branchname}</td>
                                        <td>
                                            <a onClick={() => handleUpdateBranch(branch)}>
                                                <i className="mx-3 fa-solid fa-pen fa-beat-fade fa-xl" style={{ color: 'blue' }}></i>
                                            </a>
                                            <a onClick={() => handleDeleteBranch(branch)}>
                                                <i className="fa-solid fa-trash fa-xl" style={{ color: 'red' }}></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className='pagination-container'>
                            {Array.from({ length: Math.ceil(rowData.length / itemsPerPage) }, (_, index) => (
                                <button
                                    className='pagination-button mx-2'
                                    key={index}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {deleteConfirmation && (
                    <DeleteConfirmationModal
                        show={!!deleteConfirmation}
                        handleClose={cancelDeleteBranch}
                        handleDelete={confirmDeleteBranch}
                        branchName={deleteConfirmation.branchname}
                    />
                )}

                {editModalShow && editBranch && (
                    <EditBranchModal
                        show={editModalShow}
                        handleClose={() => setEditModalShow(false)}
                        handleSave={confirmEditBranch}
                        branchName={editedBranchName}
                        setBranchName={setEditedBranchName}
                    />
                )}
            </div>
        </div>
    );
}

export default Branches;

