import React, { useState, useEffect } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from 'axios';
import { Button } from 'react-bootstrap';

function Footer() {
    const [loggedUsername, setLoggedUsername] = useState('');
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const [showOffCanvasChat, setShowOffCanvasChat] = useState(false);
    const [showOffCanvasUser, setShowOffCanvasUser] = useState(false);
    const [userReports, setUserReports] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const reports = localStorage.getItem('userReports');
        if (reports) {
            setUserReports(JSON.parse(reports));
        }
        const storedUsername = localStorage.getItem('userid');
        setLoggedUsername(storedUsername);

        axios.get(`https://test-server-o94j.onrender.com/user/users/${storedUsername}`)
            .then(response => {
                setUserDetails(response.data);
            })
            .catch(error => {
                console.error('Error fetching user details:', error);
            });

    }, []);

    const handleClose = () => setShowOffCanvas(false);
    const handleShow = () => setShowOffCanvas(true);

    const handleCloseOffCanvas = () => setShowOffCanvasChat(false);
    const handleShowOffCanvas = () => setShowOffCanvasChat(true);

    const handleCloseOffCanvasUser = () => setShowOffCanvasUser(false);
    const handleShowOffCanvasUser = () => setShowOffCanvasUser(true);

    const handleLogout = () => {
        localStorage.clear(); // Clear all items in local storage
        navigate('/'); // Redirect to the home page
    };

    const handleMenuClick = (reportName) => {
        // Redirect to the appropriate route
        navigate(`/${reportName.toLowerCase().replace(/\s+/g, '-')}`);
    };



    return (
        <footer className='footer'>
            <div className="navbar-footer">
                <div className='list-item'>
                    <Link to={'/frontpage'}>
                        <li className="list-item">
                            <i className="fa-solid fa-house fa-lg"></i>
                            <span className="list-item-name">Home</span>
                        </li>
                    </Link>
                </div>
                <li className="list-item">
                    <i onClick={handleShow} className="fa-solid fa-user fa-lg"></i>
                    <span className="list-item-name">Profile</span>
                    <Offcanvas show={showOffCanvas} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className='profile'>My Profile</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {userDetails && userDetails.image && (
                                <div className='mx-5' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '70px', height: '70px', borderRadius: '10%', overflow: 'hidden', marginLeft: '-20%' }}>
                                        <img
                                            src={`https://test-server-o94j.onrender.com/${userDetails.image}`}
                                            alt={userDetails.username}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'fill', // Ensure the image covers the circle without stretching
                                            }}
                                        />
                                    </div>
                                    <div className='mx-3' style={{ marginRight: '10px', fontSize: '0.8rem' }}>
                                        {userDetails.username}
                                        <div>
                                            {userDetails.mobilenumber}
                                        </div>
                                        <div>
                                            {userDetails.email}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr style={{ margin: '10px 0' }} />
                            <div>
                                <Link to={'/userprofile'}>
                                    <div className='profile'>
                                        Profile
                                    </div>
                                </Link>
                                <Link to={'/userchat'}>
                                    <div className='mt-4'>
                                        Chats
                                    </div>
                                </Link>
                                <Link to={'/aboutus'}>
                                    <div className='mt-4'>
                                        About Us
                                    </div>
                                </Link>
                                <Link to={'/contactus'}>
                                    <div className='mt-4'>
                                        Contact Us
                                    </div>
                                </Link>
                                <Link to={'/changeuserpassword'}>
                                    <div className='mt-4'>
                                        Change User Password
                                    </div>
                                </Link>
                                <Link to={'/faq'}>
                                    <div className='mt-4'>
                                        FAQ's
                                    </div>
                                </Link>
                                <div className='mt-4'>
                                    <Button className='btn btn-danger' onClick={handleLogout}>
                                        Logout
                                    </Button>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </li>
                <li className="list-item">
                    <i className="fa-brands fa-rocketchat fa-lg"></i>
                    <span className="list-item-name">Chats</span>
                </li>
                <li className="list-item">
                    <i onClick={handleShowOffCanvas} className="fa-solid fa-chart-line fa-lg"></i>
                    <span className="list-item-name">Reports</span>
                    <Offcanvas show={showOffCanvasChat} onHide={handleCloseOffCanvas}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className='profile'>Reports</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <ul className='menus'>
                                {userReports.map((report, index) => (
                                    <div key={index} className={`mt-3 menu${index + 1}`}>
                                        <Button className="report-button" onClick={() => handleMenuClick(report)}>{report}</Button>
                                    </div>
                                ))}
                            </ul>
                        </Offcanvas.Body>
                    </Offcanvas>
                </li>
                <li className="list-item">
                    <i className="fa-solid fa-gear fa-lg"></i>
                    <span className="list-item-name">Settings</span>
                </li>
            </div>
        </footer>
    );
}

export default Footer;






