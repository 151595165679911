
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { useParams } from 'react-router-dom';
import './Userparticularleadserveyform.css'

function Userparticularleadserveyform() {
    const [leadserveyData, setLeadserveyData] = useState({});
    const { leadserveyid } = useParams();

    useEffect(() => {
        fetchLeadserveyDetails();
    }, [leadserveyid]);

    const fetchLeadserveyDetails = () => {
        axios.get(`https://test-server-o94j.onrender.com/leadservey/leadserveys/${leadserveyid}`)
            .then(response => {
                setLeadserveyData(response.data);
            })
            .catch(error => {
                console.error('Error fetching leadservey details:', error);
            });
    };

    return (
        <div>
            <Header />
            <div className='leadservey'>
                <div className='leadservey-heading'>
                    <h1>LEAD SERVEY DETAILS</h1>
                </div>
                <div style={{ fontSize: '0.8rem' }} className='leadservey-forms'>
                    <div className='form-details' style={{ lineHeight: '1' }}>
                        <p className='company-details'>
                            <strong>Company Name : M/s.</strong> {leadserveyData.CustName} ({leadserveyData.Since})
                        </p>
                        <p className='bussiness-type'>
                            <strong>Bussiness Type : </strong> {leadserveyData.BizType}
                        </p>
                        <p className='bussiness-category'>
                            <strong>Bussiness Category : </strong> {leadserveyData.BizCatge}
                        </p>
                        <p className='bussiness-experience'>
                            <strong>Bussiness Experience : </strong> {leadserveyData.BizExp}
                        </p>
                        <p className='fl'>
                            <strong>Customer Name : Mr/Ms</strong> {leadserveyData.FName} {leadserveyData.LName}
                        </p>
                        <p className='addr1'>
                            <strong>Address :</strong>
                            <span className="address-data">
                                {leadserveyData.Addr1}, {leadserveyData.Addr2}
                            </span>
                        </p>
                        <p className='acpsc'>
                            {leadserveyData.Area}, {leadserveyData.City} - {leadserveyData.PinCode},  {leadserveyData.State} ({leadserveyData.Country})
                        </p>
                        <p className='MobNum'>
                            <strong>Mob :</strong> {leadserveyData.MobNum},  {leadserveyData.AltMobNum}
                        </p>
                        <p className='EmailId'>
                            <strong>Email</strong> {leadserveyData.EmailId}
                        </p>
                    </div>
                    <hr />
                    <div className='form-details2' style={{ lineHeight: '1' }}>
                        <p className='current-address-since'>
                            <strong>Current Address Since Years : </strong> {leadserveyData.CurrAddSince}
                        </p>
                        <p className='bussiness-hour'>
                            <strong>Bussiness Hour : </strong> {leadserveyData.BizHourFrom} AM to {leadserveyData.BizHourTo} PM
                        </p>
                        <p className='approx-quantity-buy-in-kg'>
                            <strong>Approx Quantity Buy In Kg : </strong> {leadserveyData.ApproxQtyBuyinKg}
                        </p>
                        <p className='daily-purchase-value'>
                            <strong>Daily Purchase Value : </strong> {leadserveyData.DaPurVal}
                        </p>
                        <p className='key-skus'>
                            <strong>Key SKU's : </strong> {leadserveyData.KeySkus ? leadserveyData.KeySkus.join(',') : ''}
                        </p>

                    </div>
                </div>

            </div>
            <div className='footers'>
                <Footer />
            </div>
        </div>

    );
}

export default Userparticularleadserveyform;




