import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import './Product.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import companylogo from '../../assets/images/logo2.png';

function Product() {
    const [rowData, setRowData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [packing, setPacking] = useState([]);
    const [productName, setProductName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [selectedPacking, setSelectedPacking] = useState('');
    const [editProduct, setEditProduct] = useState(null);
    const [editedProductName, setEditedProductName] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategoryFilter, setSelectedCategoryFilter] = useState('');
    const [productImage, setProductImage] = useState('');

    const [selectedCategorySubcategories, setSelectedCategorySubcategories] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const fetchCategories = () => {
        axios.get('https://test-server-o94j.onrender.com/category/category')
            .then(response => response.data)
            .then(data => setCategories(data))
            .catch(error => console.error('Error fetching categories:', error));
    };

    const fetchSubcategories = () => {
        axios.get('https://test-server-o94j.onrender.com/subcategory/subcategory')
            .then(response => response.data)
            .then(data => setSubcategories(data))
            .catch(error => console.error('Error fetching subcategories.', error));
    };

    const fetchPacking = () => {
        axios.get('https://test-server-o94j.onrender.com/packing/packing')
            .then(response => response.data)
            .then(data => setPacking(data))
            .catch(error => console.error('Error fetching packing:', error));
    };


    const fetchProducts = () => {
        axios.get('https://test-server-o94j.onrender.com/products/products')
            .then(response => response.data)
            .then(async data => {
                const dataWithDetails = await Promise.all(data.map(async item => {
                    const categoryResponse = await axios.get(`https://test-server-o94j.onrender.com/category/viewcategory/${item.categoryid}`);
                    const subcategoryResponse = await axios.get(`https://test-server-o94j.onrender.com/subcategory/viewsubcategory/${item.subcategoryid}`);
                    const packingResponse = await axios.get(`https://test-server-o94j.onrender.com/packing/viewpacking/${item.packingid}`);

                    const categoryname = categoryResponse.data.categoryname;
                    const subcategoryname = subcategoryResponse.data.subcategoryname;
                    const packingname = packingResponse.data.packingname;

                    return { ...item, srNo: item.srNo, categoryname, subcategoryname, packingname };
                }));

                setRowData(dataWithDetails);
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    setRowData([]);
                } else {
                    console.error('Error fetching data:', error);
                }
            });
    };

    const fetchSubcategoriesByCategory = async (categoryId) => {
        try {
            const response = await axios.get(`https://test-server-o94j.onrender.com/subcategory/subcategory/${categoryId}`);
            setSelectedCategorySubcategories(response.data);
        } catch (error) {
            console.error('Error fetching subcategories by category:', error);
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchSubcategories();
        fetchPacking();
        fetchProducts();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredItems = rowData.filter(product => product.productname.toLowerCase().includes(searchTerm.toLowerCase()));
    const filteredItemsByCategory = selectedCategoryFilter ? filteredItems.filter(product => product.categoryname === selectedCategoryFilter) : filteredItems;
    const currentItems = filteredItemsByCategory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleAddProduct = async (e) => {
        e.preventDefault();

        if (productName.trim() !== '' && selectedCategory.trim() !== '' && selectedSubcategory.trim() !== '' && selectedPacking.trim() !== '' && productImage) {
            const selectedCategoryObject = categories.find(category => category.categoryname === selectedCategory);
            const selectedSubcategoryObject = selectedCategorySubcategories.find(subcategory => subcategory.subcategoryname === selectedSubcategory);
            const selectedPackingObject = packing.find(packingItem => packingItem.packingname === selectedPacking);

            if (selectedCategoryObject && selectedSubcategoryObject && selectedPackingObject) {
                const categoryid = selectedCategoryObject.categoryid;
                const subcategoryid = selectedSubcategoryObject.subcategoryid;
                const packingid = selectedPackingObject.packingid;

                try {
                    // Fetch existing products
                    const existingProducts = await axios.get('https://test-server-o94j.onrender.com/products/products');

                    // Generate new product ID and product code
                    const maxProductId = existingProducts.data.reduce((max, product) => {
                        const productId = parseInt(product.productid);
                        return productId > max ? productId : max;
                    }, 0);
                    const generatedProductId = (maxProductId + 1).toString();
                    const paddedIndex = `000${generatedProductId}`.slice(-3);
                    const productCode = `PFL${paddedIndex}`;

                    // Prepare form data
                    const formData = new FormData();
                    formData.append('productImage', productImage);
                    formData.append('productname', productName);
                    formData.append('categoryid', categoryid);
                    formData.append('subcategoryid', subcategoryid);
                    formData.append('packingid', packingid);
                    formData.append('productid', generatedProductId); // Add the generated product ID
                    formData.append('productcode', productCode)
                    // Send request to add product
                    await axios.post('https://test-server-o94j.onrender.com/products/add-products', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    // Reset form fields and fetch updated products
                    setProductName('');
                    setSelectedCategory('');
                    setSelectedSubcategory('');
                    setSelectedPacking('');
                    setProductImage('null');
                    fetchProducts();
                } catch (error) {
                    console.error('Error adding product:', error);
                }
            }
        }
    };


    const handleImageChange = (e) => {
        setProductImage(e.target.files[0]); // Set the selected image file
    };

    const handleUpdateProduct = (product) => {
        setEditProduct(product);
        setEditedProductName(product.productname);
        setShowEditModal(true);
    };

    const confirmEditProduct = () => {
        axios.post('https://test-server-o94j.onrender.com/products/update-products', {
            productid: editProduct.productid,
            newProductName: editedProductName,
        })
            .then(() => {
                fetchProducts(); // Refresh the subcategory list
                setEditProduct(null);
                setShowEditModal(false);
            })
            .catch(error => console.error('Error updating products:', error));
    };

    const handleDeleteProduct = (product) => {
        setDeleteConfirmation(product);
        setShowDeleteModal(true);
    };

    const confirmDeleteProduct = () => {
        axios.post('https://test-server-o94j.onrender.com/products/delete-products', {
            productid: deleteConfirmation.productid,
        })
            .then(() => {
                fetchProducts(); // Refresh the subcategory list
                setDeleteConfirmation(null);
                setShowDeleteModal(false);
            })
            .catch(error => console.error('Error deleting products:', error));
    };

    const cancelDeleteProduct = () => {
        setDeleteConfirmation(null);
        setShowDeleteModal(false);
    };


    const handleResetProductInput = () => {
        setProductName('');
        setSelectedCategory('');
        setSelectedSubcategory('');
        setSelectedPacking('')

    };

    const downloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait', // Adjust orientation to fit more content horizontally
            unit: 'mm', // Set measurement unit to millimeters
            format: 'a4' // Set page format to A4
        });

        // Convert company logo to data URL
        const logo = new Image();
        logo.src = companylogo;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        logo.onload = async function () {
            canvas.width = logo.width;
            canvas.height = logo.height;
            ctx.drawImage(logo, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            doc.addImage(dataURL, 'PNG', 5, 2, 18, 12);

            // Add company name on the left side
            doc.setFontSize(12);
            const companyName = 'PRIME FRESH LIMITED (Vashi/DC)';
            const companyNameX = 24;
            const companyNameY = 6;
            doc.text(companyName, companyNameX, companyNameY);

            // Get the width of the company name
            const companyNameWidth = doc.getTextWidth(companyName);

            // Draw line below company name
            const lineX1 = companyNameX;
            const lineX2 = companyNameX + companyNameWidth;
            const lineY = companyNameY + 1; // Move the line slightly below the text
            doc.line(lineX1, lineY, lineX2, lineY);

            // Add "Lead Survey Reports" text under the line
            const leadReportText = 'Product Reports';
            const leadReportTextX = companyNameX; // Adjust the position as needed
            const leadReportTextY = lineY + 5; // Move the text below the line
            doc.setFontSize(12);
            doc.text(leadReportText, leadReportTextX, leadReportTextY);

            doc.setFontSize(12)
            const currentDate = new Date().toLocaleDateString('en-IN');
            doc.text('Date: ' + currentDate, doc.internal.pageSize.width - 15, 7, null, null, 'right');

            // Generate table
            const tableColumns = ["Sr.No", "PRODUCT CODE", "PRODUCT", "CATEGORY", "SUBCATEGORY", "PACKING"];
            const tableRows = [];
            filteredItems.forEach((product, index) => {
                const productItems = [
                    index + 1,
                    product.productcode,
                    product.productname,
                    product.categoryname,
                    product.subcategoryname,
                    product.packingname
                ];
                tableRows.push(productItems);
            });

            // Add table
            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: leadReportTextY + 3, // Start position of the table below "Lead Survey Reports" text
                margin: { left: 5 }, // Set margin-left
                didParseCell: function (data) {
                    const cell = data.cell;
                    if (tableColumns.includes(data.cell.raw)) {
                        // For table column headers (tableColumns)
                        cell.styles.fillColor = [204, 204, 204]; // Grey background color
                        cell.styles.textColor = [0, 0, 0]; // Black font color
                        cell.styles.fontSize = 9;
                        cell.styles.fontStyle = 'bold';
                        cell.styles.halign = 'center';

                    } else {
                        // For table rows (tableRows)
                        cell.styles.fillColor = [255, 255, 255]; // White background color
                        cell.styles.textColor = [0, 0, 0]; // Black font color
                        cell.styles.fontSize = 7;
                    }
                    cell.styles.lineWidth = 0.2;
                    cell.styles.lineColor = [0, 0, 0];
                }
            });

            // Add line above the footer
            doc.setLineWidth(0.5);
            doc.line(10, doc.internal.pageSize.height - 20, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 20);
            const preparedBy = 'admin';
            const currentDateFooter = new Date().toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });
            // Fetch IP address
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const ipAddress = response.data.ip;
                const footerText = `Prepared by ${preparedBy} on ${currentDate} ${currentDateFooter} | IP Address: ${ipAddress}`;
                doc.setFontSize(10);
                doc.text('Prime Fresh Limited (Vashi/DC)', 10, doc.internal.pageSize.height - 15);
                doc.setFontSize(10);
                doc.text(footerText, 10, doc.internal.pageSize.height - 10);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }

            // Save the PDF
            doc.save('product_reports.pdf');
        };
    };


    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.book_new();
        const excelColumns = ["Sr.No", "PRODUCT CODE", "PRODUCT", "CATEGORY", "SUBCATEGORY", "PACKING"];
        XLSX.utils.sheet_add_aoa(worksheet, [excelColumns], { origin: 'A1' });
        const data = currentItems.map((product, index) => {
            const productData = [
                index + 1,
                product.productcode,
                product.productname,
                product.categoryname,
                product.subcategoryname,
                product.packingname
            ];
            return productData;
        });
        XLSX.utils.sheet_add_aoa(worksheet, data, { origin: 'A2' });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Product Details');
        XLSX.writeFile(workbook, 'product_details.xlsx');
    };

    const printProductDetails = () => {
        const printContent = document.getElementById('printproduct').innerHTML;
        const originalContent = document.body.innerHTML;

        // Get the current date and week starting day
        const currentDate = new Date().toLocaleDateString('en-IN');

        const printableContentWithLogo = `
        <div style="font-family: Arial, sans-serif; margin-left: 20px;">
            <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                <img src="${companylogo}" alt="Company Logo" style="width: 100px; height: auto; margin-right: 20px;">
                    <div>
                        <h1 style="font-size: 18px; margin: 0;">Prime Fresh Limited (Vashi/DC)</h1>
                        <hr style="margin: 5px 0;">
                        <h2 style="font-size: 16px; margin: 0;">Product Reports</h2>
                    </div>
                <div>
                    <h1 style="margin-left: 320px;">${currentDate}</h1>
                </div>
            </div>
            ${printContent}
        </div>
    `;


        // Combine printable content with the footer
        const printableContentWithFooter = printableContentWithLogo;

        // Replace the current body content with the printable content including the logo, company name, hr, and user details
        document.body.innerHTML = printableContentWithFooter;

        // Print the content
        window.print();

        // Restore the original content after printing
        document.body.innerHTML = originalContent;
        window.location.reload(); // Refresh the page to restore original content
    };

    // Function to get the starting day of the week
    function getWeekStartingDay() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = new Date().getDay();
        return days[currentDayIndex];
    }

    const currentTime = new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const currentDate = new Date().toLocaleDateString('en-IN');
    const weekStartingDay = getWeekStartingDay();

    return (
        <div>
            <h1 className='head'>PRODUCTS</h1>
            <div style={{ fontSize: '0.8rem' }} className='products'>
                <Form className='products-form' onSubmit={handleAddProduct}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                            <h5 style={{ fontSize: '0.8rem' }}>PRODUCT</h5>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Product Name"
                                value={productName}
                                style={{ fontSize: '0.8rem' }}
                                onChange={(e) => setProductName(e.target.value)}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                            <h5 style={{ fontSize: '0.8rem' }}>PACKING</h5>
                            <select
                                value={selectedPacking}
                                onChange={(e) => setSelectedPacking(e.target.value)}
                                className="form-select"
                                style={{ fontSize: '0.8rem' }}
                            >
                                <option value='' disabled>Select a packing</option>
                                {packing.map(packing => (
                                    <option key={packing.packingid} value={packing.packingname}>
                                        {packing.packingname}
                                    </option>
                                ))}
                            </select>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                            <h5 style={{ fontSize: '0.8rem' }}>CATEGORY</h5>
                            <select
                                value={selectedCategory}
                                onChange={(e) => {
                                    setSelectedCategory(e.target.value);
                                    const selectedCategoryId = categories.find(category => category.categoryname === e.target.value)?.categoryid;
                                    fetchSubcategoriesByCategory(selectedCategoryId); // Fetch subcategories based on the selected category
                                }}
                                className="form-select"
                                style={{ fontSize: '0.8rem' }}
                            >
                                <option value='' disabled>Select a category</option>
                                {categories.map(category => (
                                    <option key={category.categoryid} value={category.categoryname}>
                                        {category.categoryname}
                                    </option>
                                ))}
                            </select>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <h5 style={{ fontSize: '0.8rem' }}>SUBCATEGORY</h5>
                            <select
                                value={selectedSubcategory}
                                onChange={(e) => setSelectedSubcategory(e.target.value)}
                                className="form-select"
                                style={{ fontSize: '0.8rem' }}
                            >
                                <option value='' disabled>Select a subcategory</option>
                                {selectedCategorySubcategories.map(subcategory => (
                                    <option key={subcategory.subcategoryid} value={subcategory.subcategoryname}>
                                        {subcategory.subcategoryname}
                                    </option>
                                ))}
                            </select>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="productImage">
                            <Form.Label>Product Image</Form.Label>
                            <Form.Control type="file" onChange={handleImageChange} />
                        </Form.Group>

                    </Row>
                    <div className='product-button'>
                        <Button className='add-product-button' type="submit">ADD PRODUCT</Button>
                        <Button onClick={handleResetProductInput} className='mx-2 add-product-button' type='submit'>Reset</Button>
                    </div>
                </Form>

                <div className='product-pdfexcel-button'>
                    <Button onClick={downloadPDF}>
                        <i className="fa-solid fa-file-pdf fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={downloadExcel}>
                        <i className="fa-solid fa-file-excel fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={printProductDetails}>
                        <i className="fa-solid fa-print fa-fade fa-xl"></i>
                    </Button>
                </div>

                <div className='products-table' >
                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <input
                            type='text'
                            className='bg-#F8F9FC h-40 outline-none pl-13 w-350 rounded-5 placeholder-text-14 leading-20 font-normal'
                            placeholder='Search product name.....'
                            value={searchTerm}
                            style={{ fontSize: '0.8rem' }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <select
                            value={selectedCategoryFilter}
                            onChange={(e) => setSelectedCategoryFilter(e.target.value)}
                            className="form-select ml-3 selectedcategory"
                            style={{ fontSize: '0.8rem', width: '25%' }}
                        >
                            <option value=''>Filter by Category</option>
                            {categories.map(category => (
                                <option key={category.categoryid} value={category.categoryname}>
                                    {category.categoryname}
                                </option>
                            ))}
                        </select>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>PRODUCTCODE</th>
                                <th>PRODUCTNAME</th>
                                <th>PACKING</th>
                                <th>CATEGORY</th>
                                <th>SUBCATEGORY</th>
                                <th>ACTIONS</th>
                                <th>PRODUCTIMAGE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((product, index) => (
                                <tr key={index}>
                                    <td>{indexOfFirstItem + index + 1}</td>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>{product.packingname}</td>
                                    <td>{product.categoryname}</td>
                                    <td>{product.subcategoryname}</td>
                                    <td>
                                        <a onClick={() => handleUpdateProduct(product)}>
                                            <i className="mx-3 fa-solid fa-pen fa-beat-fade fa-xl" style={{ color: 'blue' }}></i>
                                        </a>
                                        <a onClick={() => handleDeleteProduct(product)}>
                                            <i className="mx-3 fa-solid fa-trash fa-beat-fade fa-xl" style={{ color: 'red' }}></i>
                                        </a>
                                    </td>
                                    <td>
                                        <img src={`https://test-server-o94j.onrender.com/${product.productImage}`} alt="Product" style={{ width: '50px', height: '50px' }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='pagination-container'>
                        {Array.from({ length: Math.ceil(filteredItemsByCategory.length / itemsPerPage) }).map((_, index) => (
                            <button key={index} onClick={() => paginate(index + 1)} className='pagination-button'>
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Edit Product Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        type="text"
                        value={editedProductName}
                        onChange={(e) => setEditedProductName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={confirmEditProduct}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Product Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this product?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelDeleteProduct}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteProduct}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="printproduct" style={{ display: 'none', fontSize: '0.8rem', backgroundColor: 'white' }}>
                <Table>
                    <thead>
                        <tr style={{ fontSize: '0.8rem' }}>
                            <th>Sr.No</th>
                            <th>PRODUCTID</th>
                            <th>PRODUCTNAME</th>
                            <th>CATEGORYNAME</th>
                            <th>SUBCATEGORYNAME</th>
                            <th>PACKINGNAME</th>
                            <th>PRODUCTIMAGE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems.map((product, index) => (
                            <tr key={index} style={{ fontSize: '0.6rem' }}>
                                <td>{index + 1}</td>
                                <td>{product.productcode}</td>
                                <td>{product.productname}</td>
                                <td>{product.categoryname}</td>
                                <td>{product.subcategoryname}</td>
                                <td>{product.packingname}</td>
                                <td>
                                    <img src={`https://test-server-o94j.onrender.com/${product.productImage}`} alt="Product" style={{ width: '50px', height: '50px' }} />
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="8" style={{ fontSize: '0.5rem' }}>
                                Prepared by admin on {currentDate} {currentTime},{weekStartingDay}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Product;

