import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';
import logo from '../../assets/images/logo2.png';

function Sidebar({ children }) {
    const [sidebarClosed, setSidebarClosed] = useState(true);
    const [userDetails, setUserDetails] = useState(null);

    const toggleSidebar = () => {
        setSidebarClosed(!sidebarClosed);
        const icon = document.querySelector('.fa-bars');
        if (sidebarClosed) {
            icon.classList.add('centered-icon');
        } else {
            icon.classList.remove('centered-icon');
        }
    };

    useEffect(() => {
        const arrowClickHandler = (e) => {
            const arrowParent = e.target.parentElement.parentElement;
            arrowParent.classList.toggle("showMenu");
        };

        const arrowElements = document.querySelectorAll(".arrow");
        arrowElements.forEach((arrow) => {
            arrow.addEventListener("click", arrowClickHandler);
        });

        return () => {
            arrowElements.forEach((arrow) => {
                arrow.removeEventListener("click", arrowClickHandler);
            });
        };

    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userid');
        axios.get(`https://test-server-o94j.onrender.com/user/users/${storedUserId}`)
            .then(response => {
                setUserDetails(response.data);
            })
            .catch(error => {
                console.error('Error fetching user details:', error);
            });
    }, []);

    return (
        <div>
            <div className={`sidebar ${sidebarClosed ? '' : 'close'}`}>
                <div className='logo-details'>
                    <img src={logo} />
                </div>
                <ul className='nav-links mt-4'>
                    <li>
                        <Link to='/dashboard'>
                            <i className="fa-solid fa-gauge"></i>
                            <span className='link_name'>DASHBOARD</span>
                        </Link>
                        <ul className='sub-menu blank'>
                            <li><span className='link_name'>DASHBOARD</span></li>
                        </ul>
                    </li>
                    <li>
                        <div className='iocn-link'>
                            <Link to=''>
                                <i className="fa-brands fa-product-hunt"></i>
                                <span className='link_name'>PRODUCTS</span>
                            </Link>
                            <i className="fa-solid fa-chevron-down arrow"></i>
                        </div>
                        <ul className='sub-menu'>
                            <li><span className='link_name'>PRODUCTS</span></li>
                            <li><Link to='/category'>CATEGORY</Link></li>
                            <li><Link to='/subcategory'>SUB-CATEGORY</Link></li>
                            <li><Link to='/packing'>PACKING</Link></li>
                            <li><Link to='/product'>PRODUCT</Link></li>
                        </ul>
                    </li>
                    <li>
                        <div className='iocn-link'>
                            <Link to=''>
                                <i className="fa-solid fa-location-dot"></i>
                                <span className='link_name'>LOCATIONS</span>
                            </Link>
                            <i className="fa-solid fa-chevron-down arrow"></i>
                        </div>
                        <ul className='sub-menu'>
                            <li><span className='link_name'>LOCATIONS</span></li>
                            <li><Link to='/state'>STATE</Link></li>
                            <li><Link to='/city'>CITY</Link></li>
                            <li><Link to='/area'>AREA</Link></li>
                        </ul>
                    </li>
                    <li>
                        <div className='iocn-link'>
                            <Link to=''>
                                <i class="fa-brands fa-wpforms"></i>
                                <span className='link_name'>REPORTS</span>
                            </Link>
                            <i className="fa-solid fa-chevron-down arrow"></i>
                        </div>
                        <ul className='sub-menu'>
                            <li><span className='link_name'>REPORTS</span></li>
                            <li><Link to='/reports'>LEAD SURVEY</Link></li>
                        </ul>
                    </li>
                    <li>
                        <div className='iocn-link'>
                            <Link to=''>
                                <i className="fa-solid fa-m"></i>
                                <span className='link_name'>MODULES</span>
                            </Link>
                            <i className="fa-solid fa-chevron-down arrow"></i>
                        </div>
                        <ul className='sub-menu'>
                            <li><span className='link_name'>MODULES</span></li>
                            <li><Link to='/assigning'>LEAD-ASSIGNING</Link></li>
                        </ul>
                    </li>
                    <li>
                        <div className='iocn-link'>
                            <Link to=''>
                                <i className="fa-solid fa-gear"></i>
                                <span className='link_name'>SETTINGS</span>
                            </Link>
                            <i className="fa-solid fa-chevron-down arrow"></i>
                        </div>
                        <ul className='sub-menu'>
                            <li><span className='link_name'>SETTINGS</span></li>
                            <li><Link to='/userdetails'>USERS</Link></li>
                            <li><Link to='/register'>USER-REGISTRATION</Link></li>
                            <li><Link to='/branches'>BRANCHES</Link></li>
                        </ul>
                    </li>
                    <li>
                        <div className='profile-details'>
                            {userDetails && userDetails.image && (
                                <div className='profile-content'>
                                    <img
                                        src={`https://test-server-o94j.onrender.com/${userDetails.image}`}
                                        alt='profile'
                                        className='profile-image'
                                    />
                                </div>
                            )}
                            <div className='name-job'>
                                <div className='profile_name'>{userDetails && userDetails.username}</div>
                                <div className='job'>{userDetails && userDetails.roles}</div>
                            </div>
                            <Link to={'/'}>
                                <i className="fa-solid fa-right-from-bracket"></i>
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
            <section className='home-section'>
                <div className='home-content'>
                    <i className={`fa-solid fa-bars ${sidebarClosed ? 'centered-icon' : ''}`} onClick={toggleSidebar}></i>
                </div>
                <div className='col-lg-12'>
                    <main>{children}</main>
                </div>
            </section>
        </div>
    );
}

export default Sidebar;

