import React, { useState, useEffect } from 'react';
import './Leadprocessingreport.css';
import axios from 'axios';
import Select from 'react-select';
import { Modal, Button, Form } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import companylogo from '../../../../assets/images/logo2.png';

function Leadprocessingreport() {
    const [leadprocessingData, setLeadprocessingData] = useState([]);
    const [users, setUsers] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [stateFilter, setStateFilter] = useState([]);
    const [cityFilter, setCityFilter] = useState([]);
    const [areaFilter, setAreaFilter] = useState([]);
    const [usernameFilter, setUsernameFilter] = useState([]);
    const [executiveFilter, setExecutiveFilter] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedLeadprocessingData, setSelectedLeadprocessingData] = useState(null);

    const fetchLeadprocessing = () => {
        axios.get('https://test-server-o94j.onrender.com/leadprocessing/leadprocessing')
            .then(response => response.data)
            .then(data => setLeadprocessingData(data))
            .catch(error => console.error('Error fetching leadprocessing', error))
    };

    console.log(leadprocessingData);

    const fetchUsers = () => {
        axios.get('https://test-server-o94j.onrender.com/user/users')
            .then(response => response.data)
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    };

    const fetchState = () => {
        axios.get('https://test-server-o94j.onrender.com/state/states')
            .then(response => response.data)
            .then(data => setState(data))
            .catch(error => console.error('Error fetching state:', error))
    }

    const fetchCity = () => {
        axios.get('https://test-server-o94j.onrender.com/city/cities')
            .then(response => response.data)
            .then(data => setCity(data))
            .catch(error => console.error('Error fetching cities', error))
    }

    const fetchArea = () => {
        axios.get('https://test-server-o94j.onrender.com/area/areas')
            .then(response => response.data)
            .then(data => setArea(data))
            .catch(error => console.error('Error fetching areas', error))
    }

    useEffect(() => {
        fetchLeadprocessing();
        fetchState();
        fetchCity();
        fetchArea();
        fetchUsers();
    }, []);

    const handleStateFilterChange = selectedOptions => {
        setStateFilter(selectedOptions);
    };

    const handleCityFilterChange = selectedOptions => {
        setCityFilter(selectedOptions);
    };

    const handleAreaFilterChange = selectedOptions => {
        setAreaFilter(selectedOptions);
    };

    const handleUsernameFilterChange = selectedOptions => {
        setUsernameFilter(selectedOptions);
    };

    const handleExecutiveChange = selectedOptions => {
        setExecutiveFilter(selectedOptions)
    };

    const handleStartDateChange = date => {
        setStartDate(date);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
    };

    const handleDeleteConfirmation = (id) => {
        setDeleteItemId(id);
    };

    const handleDelete = () => {
        axios.delete(`https://test-server-o94j.onrender.com/leadprocessing/leadprocessing/${deleteItemId}`)
            .then(response => {
                fetchLeadprocessing();
                setDeleteItemId(null);
            })
            .catch(error => console.error('Error deleting item:', error));
    };

    const handleDeleteAllLeadprocessing = () => {
        axios.delete('https://test-server-o94j.onrender.com/leadprocessing/delete-all-leadprocessing')
            .then(() => {
                fetchLeadprocessing();
                setShowDeleteModal(false);
            })
            .catch(error => console.error('Error deleting all leadprocessing:', error))
    }

    const filteredItems = leadprocessingData.filter(item => {
        if (startDate && endDate) {
            const itemDateTime = new Date(item.createdAt).getTime();
            const startDateTime = new Date(startDate).getTime();
            const endDateTime = new Date(endDate).getTime();
            return itemDateTime >= startDateTime && itemDateTime <= endDateTime;
        }
        return true;
    }).filter(item =>
        (stateFilter.length === 0 || stateFilter.some(state => state.value === item.leadserveyDetails.State)) &&
        (cityFilter.length === 0 || cityFilter.some(city => city.value === item.leadserveyDetails.City)) &&
        (areaFilter.length === 0 || areaFilter.some(area => area.value === item.leadserveyDetails.Area)) &&
        (usernameFilter.length === 0 || usernameFilter.some(username => username.value === item.leadserveyDetails.username)) &&
        (executiveFilter.length === 0 || executiveFilter.some(username => username.value === item.username))
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleShowPreview = (leadprocessingData) => {
        setSelectedLeadprocessingData(leadprocessingData);
        setShowPreview(true);
    };

    const handleClosePreview = () => setShowPreview(false);

    const downloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        })

        const logo = new Image();
        logo.src = companylogo;
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d');
        logo.onload = async function () {
            canvas.width = logo.width;
            canvas.height = logo.height;
            ctx.drawImage(logo, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            doc.addImage(dataURL, 'PNG', 5, 2, 18, 12);

            doc.setFontSize(15);
            const companyName = 'PRIME FRESH LIMITED (Vashi/DC)';
            const companyNameX = 24;
            const companyNameY = 6;
            doc.text(companyName, companyNameX, companyNameY);

            const companyNameWidth = doc.getTextWidth(companyName);

            const lineX1 = companyNameX;
            const lineX2 = companyNameX + companyNameWidth;
            const lineY = companyNameY + 1; 
            doc.line(lineX1, lineY, lineX2, lineY);

            const leadReportText = 'Lead Processing Reports';
            const leadReportTextX = companyNameX; 
            const leadReportTextY = lineY + 5; 
            doc.setFontSize(12);
            doc.text(leadReportText, leadReportTextX, leadReportTextY);

            doc.setFontSize(12)
            const currentDate = new Date().toLocaleDateString('en-IN');
            doc.text('Date: ' + currentDate, doc.internal.pageSize.width - 15, 7, null, null, 'right');

            const tableColumns = [
                "LsId", "LsDate", "BussinessName / CustomerName", "Area", "City", "State", "Mobile", "Email", "Status",
            ];
            const tableRows = [];
            filteredItems.forEach((item, index) => {
                const lsId = item.leadserveyDetails.BizCatge === 'General Trade' ? `GT/${item.leadserveyDetails.leadserveyid}` : `MT/${item.leadserveyDetails.leadserveyid}`;
                const lsDate = new Date(item.leadserveyDetails.createdAt).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: '2-digit' })
                const rowData = [
                    lsId,
                    lsDate,
                    `${item.leadserveyDetails.CustName} / ${item.leadserveyDetails.FName + item.leadserveyDetails.LName}`, // Combine first and last name for BizName / CustName
                    item.leadserveyDetails.Area,
                    item.leadserveyDetails.City,
                    item.leadserveyDetails.State,
                    { content: item.leadserveyDetails.MobNum, fontStyle: 'courier', fontSize: '1rem' }, // Set font style of Mobile column to 'courier new'
                    item.leadserveyDetails.EmailId,
                    `${item.interested ? "INTERESTED" : ""}  ${item.notInterested ? "NOT-INTERESTED" : ""}`
                ];
                tableRows.push(rowData);
            });

            const totalRecords = tableRows.length;
            const recordsPerPage = 46;

            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: leadReportTextY + 3,
                margin: { left: 5 },
                didParseCell: function (data) {
                    const cell = data.cell;
                    if (tableColumns.includes(data.cell.raw)) {
                        cell.styles.fillColor = [204, 204, 204]; 
                        cell.styles.textColor = [0, 0, 0]; 
                        cell.styles.fontSize = 9;
                        cell.styles.fontStyle = 'bold';
                        cell.styles.halign = 'center';
                    } else {
                        cell.styles.fillColor = [255, 255, 255]; 
                        cell.styles.textColor = [0, 0, 0]; 
                        cell.styles.fontSize = 5;
                        if (data.column.index === 1 || data.column.index === 6) {
                            cell.styles.font = 'courier';

                        }
                    }
    
                    cell.styles.lineWidth = 0.2;
                    cell.styles.lineColor = [0, 0, 0];
                },
                didDrawPage: function (data) {
                    const pageCount = doc.internal.getNumberOfPages();
                    const pageNumber = data.pageNumber;
                    const pageHeight = doc.internal.pageSize.height;

                    const startRecord = (pageNumber - 1) * recordsPerPage + 1;
                    const endRecord = Math.min(pageNumber * recordsPerPage, totalRecords);
                    const recordsText = `${startRecord} to ${endRecord}`;

                    const pageWidth = doc.internal.pageSize.width;
                    const marginRight = 15;
                    const marginLeft = 200;


                    doc.setFontSize(10);
                    doc.text(`Page ${pageNumber} of ${pageCount}`, pageWidth - marginLeft, pageHeight - 15, null, null, 'left');
                    doc.text(`No of Records: ${recordsText}`, pageWidth - marginRight, pageHeight - 15, null, null, 'right');
                }
            });

            doc.setLineWidth(0.5);
            doc.line(10, doc.internal.pageSize.height - 20, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 20);
            const preparedBy = 'admin';
            const currentDateFooter = new Date().toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });

            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const ipAddress = response.data.ip;
                const footerText = `Prepared by ${preparedBy} on ${currentDate} ${currentDateFooter} | IP Address: ${ipAddress}`;
                doc.setFontSize(10);
                doc.text('Prime Fresh Limited (Vashi/DC)', 10, doc.internal.pageSize.height - 10);
                doc.setFontSize(10);
                doc.text(footerText, 10, doc.internal.pageSize.height - 5);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }

            doc.save('lead_processing_reports.pdf');
        }
    }

    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.book_new();

        const excelColumns = [
            "Sr.No", "LSID", "Date", "Time", "SubmittedBy", "CustName",
            "Since", "Yrs", "Biztype", "BizCatge", "FName", "LName", "Addr1", "Addr2",
            "State", "City", "Area", "Pin Code", "Country", "MobNum", "AltMobNum",
            "EmailId", "BizExp", "CurrAddSince", "BizHourFrom",
            "BizHourTo", "ApproxQtyBuyinKg", "DaPurVal",
            "KeySkus"
        ];

        XLSX.utils.sheet_add_aoa(worksheet, [excelColumns], { origin: 'A1' });

        const excelData = currentItems.map((item, index) => {
            return [
                index + 1,
                item.leadserveyDetails.leadserveyid,
                new Date(item.createdAt).toLocaleDateString('en-IN'),
                new Date(item.createdAt).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
                item.username,
                item.leadserveyDetails.CustName,
                item.leadserveyDetails.Since,
                item.leadserveyDetails.Yrs,
                item.leadserveyDetails.BizType,
                item.leadserveyDetails.BizCatge,
                item.leadserveyDetails.FName,
                item.leadserveyDetails.LName,
                item.leadserveyDetails.Addr1,
                item.leadserveyDetails.Addr2,
                item.leadserveyDetails.State,
                item.leadserveyDetails.City,
                item.leadserveyDetails.Area,
                item.leadserveyDetails.PinCode,
                item.leadserveyDetails.Country,
                item.leadserveyDetails.MobNum,
                item.leadserveyDetails.AltMobNum,
                item.leadserveyDetails.EmailId,
                item.leadserveyDetails.BizExp,
                item.leadserveyDetails.CurrAddSince,
                item.leadserveyDetails.BizHourFrom,
                item.leadserveyDetails.BizHourTo,
                item.leadserveyDetails.ApproxQtyBuyinKg,
                item.leadserveyDetails.DaPurVal,
                item.leadserveyDetails.KeySkus.join(','),
            ];
        });
        XLSX.utils.sheet_add_aoa(worksheet, excelData, { origin: 'A2' });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Lead Survey Form');

        worksheet['!header'] = [
            '&CLead Processing Reports' 
        ];

        XLSX.writeFile(workbook, 'lead_processing_reports.xlsx');
    };

    const printUserDetails = () => {
        const printContent = document.getElementById('printleadprocessing').innerHTML;
        const originalContent = document.body.innerHTML;

        const currentDate = new Date().toLocaleDateString('en-IN');

        const printableContentWithLogo = `
        <div style="font-family: Arial, sans-serif; margin-left: 20px;">
            <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                <img src="${companylogo}" alt="Company Logo" style="width: 100px; height: auto; margin-right: 20px;">
                    <div>
                        <h1 style="font-size: 18px; margin: 0;">Prime Fresh Limited (Vashi/DC)</h1>
                        <hr style="margin: 5px 0;">
                        <h2 style="font-size: 16px; margin: 0;">Lead Processing Reports</h2>
                    </div>
                <div>
                    <h1 style="margin-left: 270px;">${currentDate}</h1>
                </div>
            </div>
            ${printContent}
        </div>
    `;

        const printableContentWithFooter = printableContentWithLogo;

        document.body.innerHTML = printableContentWithFooter;

        window.print();

        document.body.innerHTML = originalContent;
        window.location.reload(); 
    };

    function getWeekStartingDay() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = new Date().getDay();
        return days[currentDayIndex];
    }

    const currentTime = new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const currentDate = new Date().toLocaleDateString('en-IN');
    const weekStartingDay = getWeekStartingDay();



    return (
        <div>
            <h1 className='head' style={{ fontSize: '1.5rem' }}>LEAD-PROCESSING REPORTS</h1>
            <div className='leadserveyreport-table'>
                <div className='leadservey-pdfexcel-button'>
                    <Button onClick={downloadPDF}>
                        <i className="fa-solid fa-file-pdf fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={downloadExcel}>
                        <i className="fa-solid fa-file-excel fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={printUserDetails}>
                        <i className="fa-solid fa-print fa-fade fa-xl"></i>
                    </Button>
                    <Button variant='danger' onClick={() => setShowDeleteModal(true)}>
                        <i className="fa-solid fa-trash fa-fade fa-xl" style={{ color: "#050505" }}></i>
                    </Button>
                </div>
                <div className='leadprocessingserveyreport-forms' style={{ fontSize: '0.8rem' }}>
                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter States</label>
                        <Select
                            className='mt-2 leadprocessingstatefilter'
                            isMulti
                            placeholder='State'
                            options={state.map(state => ({
                                value: state.statename,
                                label: state.statename
                            }))}
                            value={stateFilter}
                            onChange={handleStateFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Cities</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='City'
                            options={city.map(city => ({
                                value: city.cityname,
                                label: city.cityname
                            }))}
                            value={cityFilter}
                            onChange={handleCityFilterChange}
                        />
                    </div>


                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Areas</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Area'
                            options={area.map(area => ({
                                value: area.areaname,
                                label: area.areaname
                            }))}
                            value={areaFilter}
                            onChange={handleAreaFilterChange}
                        />
                    </div>


                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Submitted By</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Username'
                            options={users.map(user => ({
                                value: user.username,
                                label: user.username
                            }))}
                            value={usernameFilter}
                            onChange={handleUsernameFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Assigned To</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Username'
                            options={users.map(user => ({
                                value: user.username,
                                label: user.username
                            }))}
                            value={executiveFilter}
                            onChange={handleExecutiveChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Assign From:</label>
                        <Form.Group>
                            <Form.Control
                                className='mt-2'
                                type="date"
                                value={startDate}
                                onChange={(e) => handleStartDateChange(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Assign To:</label>
                        <Form.Group>
                            <Form.Control
                                className='mt-2'
                                type="date"
                                value={endDate}
                                onChange={(e) => handleEndDateChange(e.target.value)}
                            />
                        </Form.Group>
                    </div>

                </div>
                <Table className='mt-2' style={{ fontSize: '0.8rem' }} striped bordered hover responsive>
                    <thead>
                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <th>SR.NO</th>
                            <th>LSDATE</th>
                            <th>LSID</th>
                            <th>SUBMITTEDBY</th>
                            <th>ASSIGNEDDATE</th>
                            <th>ASSIGNEDTO</th>
                            <th>BIZNAME</th>
                            <th>CUSTNAME</th>
                            <th>STATE</th>
                            <th>CITY</th>
                            <th>AREA</th>
                            <th>INTERESTED</th>
                            <th>NOTINTERESTED</th>
                            <th>PREVIEW</th>
                            <th>DELETE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{new Date(item.leadserveyDetails.createdAt).toLocaleDateString('en-IN')}</td>
                                <td>{item.leadserveyDetails.leadserveyid}</td>
                                <td>{item.leadserveyDetails.username}</td>
                                <td>{new Date(item.createdAt).toLocaleDateString('en-IN')}</td>
                                <td>{item.username}</td>
                                <td>{item.leadserveyDetails.CustName}</td>
                                <td>{item.leadserveyDetails.FName + item.leadserveyDetails.LName}</td>
                                <td>{item.leadserveyDetails.State}</td>
                                <td>{item.leadserveyDetails.City}</td>
                                <td>{item.leadserveyDetails.Area}</td>
                                <td>{item.interested ? "INTERESTED" : ""}</td>
                                <td>{item.notInterested ? "NOT-INTERESTED" : ""}</td>
                                <td>
                                    <button onClick={() => handleShowPreview(item)} className='btn btn-warning view'>
                                        <i className="fa-solid fa-eye fa-beat fa-xl"></i>
                                    </button>
                                </td>
                                <td>
                                    <button onClick={() => handleDeleteConfirmation(item._id)} className='btn btn-danger delete'>
                                        <i className="fa-solid fa-trash fa-fade fa-xl" style={{ color: "#050505" }}></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="pagination-container">
                    {Array.from({ length: Math.ceil(filteredItems.length / itemsPerPage) }, (_, index) => (
                        <button
                            className="pagination-button mx-2"
                            key={index}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                <div className="total-records" style={{ textAlign: 'center', marginTop: '1rem', fontWeight: 'bold' }}>
                    Total No. of Records: {filteredItems.length}
                </div>
            </div>
            {/* Pop-up/modal for delete confirmation */}
            <Modal show={!!deleteItemId} onHide={() => setDeleteItemId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this item?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteItemId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to delete all leadprocessing</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant='danger' onClick={handleDeleteAllLeadprocessing}>Delete All</Button>
                </Modal.Footer>

            </Modal>

            <Modal show={showPreview} onHide={handleClosePreview} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Lead Survey Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedLeadprocessingData && <LeadprocessingDataPreview leadprocessingData={selectedLeadprocessingData} />}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={handleClosePreview}>
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="printleadprocessing" style={{ display: 'none', fontSize: '0.8rem' }}>
                <Table>
                    <thead>
                        <tr style={{ fontSize: '0.8rem' }}>
                            <th>Sr.No</th>
                            <th>LSID</th>
                            <th>LSDATE</th>
                            <th>SUBMITTEDBY</th>
                            <th>ASSIGNEDTO</th>
                            <th>BIZNAME</th>
                            <th>CUSTNAME</th>
                            <th>MOBNUM</th>
                            <th>STATE</th>
                            <th>CITY</th>
                            <th>AREA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems.map((item, index) => (
                            <tr key={item.userid} style={{ fontSize: '0.5rem' }}>
                                <td>{index + 1}</td>
                                <td>{item.leadserveyDetails.leadserveyid}</td>
                                <td>{new Date(item.createdAt).toLocaleDateString('en-IN')}</td>
                                <td>{item.leadserveyDetails.username}</td>
                                <td>{item.username}</td>
                                <td>{item.leadserveyDetails.CustName}</td>
                                <td>{item.leadserveyDetails.FName} {item.LName}</td>
                                <td>{item.leadserveyDetails.MobNum}</td>
                                <td>{item.leadserveyDetails.State}</td>
                                <td style={{ width: '70px' }}>{item.leadserveyDetails.City}</td>
                                <td>{item.leadserveyDetails.Area}</td>
                            </tr>
                        ))}
                        {/* Footer Row */}
                        <tr>
                            <td colSpan="8" style={{ fontSize: '0.5rem' }}>
                                Prepared by admin on {currentDate} {currentTime},{weekStartingDay}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

function LeadprocessingDataPreview({ leadprocessingData }) {
    return (
        <div style={{ fontSize: '0.8rem', display: 'flex', flexDirection: 'column' }}>
            <div className='form-details' style={{ lineHeight: '1' }}>
                <p className='company-details'>
                    <strong>Company Name : M/s.</strong> {leadprocessingData.leadserveyDetails.CustName} ({leadprocessingData.leadserveyDetails.Since})
                </p>
                <p className='bussiness-type'>
                    <strong>Bussiness Type : </strong> {leadprocessingData.leadserveyDetails.BizType}
                </p>
                <p className='bussiness-category'>
                    <strong>Bussiness Category : </strong> {leadprocessingData.leadserveyDetails.BizCatge}
                </p>
                <p className='bussiness-experience'>
                    <strong>Bussiness Experience : </strong> {leadprocessingData.leadserveyDetails.BizExp}
                </p>
                <p className='fl'>
                    <strong>Customer Name : Mr/Ms</strong> {leadprocessingData.leadserveyDetails.FName} {leadprocessingData.leadserveyDetails.LName}
                </p>
                <p className='addr1'>
                    <strong>Address :</strong>
                    <span className="address-data">
                        {leadprocessingData.leadserveyDetails.Addr1}, {leadprocessingData.leadserveyDetails.Addr2}
                    </span>
                </p>
                <p className='acpsc'>
                    {leadprocessingData.leadserveyDetails.Area}, {leadprocessingData.leadserveyDetails.City} - {leadprocessingData.leadserveyDetails.PinCode},  {leadprocessingData.leadserveyDetails.State} ({leadprocessingData.leadserveyDetails.Country})
                </p>
                <p className='MobNum'>
                    <strong>Mob :</strong> {leadprocessingData.leadserveyDetails.MobNum},  {leadprocessingData.leadserveyDetails.AltMobNum}
                </p>
                <p className='EmailId'>
                    <strong>Email</strong> {leadprocessingData.leadserveyDetails.EmailId}
                </p>
            </div>
            <hr />
            <div className='form-details2' style={{ lineHeight: '1' }}>
                <p className='current-address-since'>
                    <strong>Current Address Since Years : </strong> {leadprocessingData.leadserveyDetails.CurrAddSince}
                </p>
                <p className='bussiness-hour'>
                    <strong>Bussiness Hour : </strong> {leadprocessingData.leadserveyDetails.BizHourFrom} AM to {leadprocessingData.leadserveyDetails.BizHourTo} PM
                </p>
                <p className='approx-quantity-buy-in-kg'>
                    <strong>Approx Quantity Buy In Kg : </strong> {leadprocessingData.leadserveyDetails.ApproxQtyBuyinKg}
                </p>
                <p className='daily-purchase-value'>
                    <strong>Daily Purchase Value : </strong> {leadprocessingData.leadserveyDetails.DaPurVal}
                </p>
                <p className='key-skus'>
                    <strong>Key SKU's : </strong> {leadprocessingData.leadserveyDetails.KeySkus ? leadprocessingData.leadserveyDetails.KeySkus.join(',') : ''}
                </p>

            </div>
        </div>
    );
}

export default Leadprocessingreport;

