import React from 'react'
import Home from '../Home'
import Banner from '../banner/Banner'
import Homefooter from '../homefooter/Homefooter'
import Homeservice from '../homeservice/Homeservice'
import Testimonials from '../testimonials/Testimonials'

function Homepage() {
  return (
    <div>
      <Home />
      <Banner />
      <Homeservice />
      <Testimonials/>
      <Homefooter />
    </div>
  )
}

export default Homepage