// import React from 'react'
// import './Home.css';
// import Container from 'react-bootstrap/Container';
// import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav';
// import Button from 'react-bootstrap/Button';
// import logo from '../../assets/images/logo2.png';
// import { Link } from 'react-router-dom';

// function Home() {
//     return (
//         <div data-aos='zoom-out-down'>
//             <Navbar expand="lg" className="bg-body-tertiary">
//                 <Container>
//                     <Link to='https://primefresh.app/' className="navbar-brand">
//                         <img src={logo} alt='' />
//                     </Link>
//                     <Navbar.Toggle aria-controls="basic-navbar-nav" />
//                     <Navbar.Collapse id="basic-navbar-nav">
//                         <Nav className="mx-5">
//                             <Button className='mx-4 login-button' as={Link} to="/login">
//                                 LOGIN
//                             </Button>
//                         </Nav>
//                     </Navbar.Collapse>
//                 </Container>
//             </Navbar>
//         </div>
//     )
// }

// export default Home

import React from 'react'
import './Home.css';
import logo from '../../assets/images/logo2.png';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div>
            <nav className="nav">
                <div className="nav__logo">
                    <img src={logo} alt='logo' />
                </div>
                <Link to={'/login'}>
                    <button className="btn login-button">LOGIN</button>
                </Link>
            </nav>
        </div>
    )
}

export default Home