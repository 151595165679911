
import React, { useState, useEffect } from 'react';
import './Leadservey.css';
import axios from 'axios';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import Select from 'react-select';

function Leadservey() {
    const [formData, setFormData] = useState({
        CustName: '', Since: '', Yrs: '', BizType: '', BizCatge: '', FName: '', LName: '', Addr1: '', Addr2: '', State: '', City: '', Area: '', PinCode: '', Country: 'India', MobNum: '', AltMobNum: '', EmailId: '', BizExp: '', CurrAddSince: '', BizHourFrom: '', BizHourTo: '', ApproxQtyBuyinKg: '', DaPurVal: '', KeySkus: [],
    });
    const [leadserveyData, setleadserveyData] = useState([]);
    const [username, setUsername] = useState('');
    const [userid, setUserid] = useState('');
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [product, setProduct] = useState([])
    const [selectedCityState, setSelectedCityState] = useState([]);
    const [showPreview, setShowPreview] = useState(false);

    const fetchState = () => {
        axios.get('https://test-server-o94j.onrender.com/state/states')
            .then(response => response.data)
            .then(data => setState(data))
            .catch(error => console.error('Error fetching state:', error))
    };

    const fetchcity = () => {
        axios.get('https://test-server-o94j.onrender.com/city/cities')
            .then(response => response.data)
            .then(data => setCity(data))
            .catch(error => console.error('Errro fetching cities', error))
    };

    const fetcharea = () => {
        axios.get('https://test-server-o94j.onrender.com/area/areas')
            .then(response => response.data)
            .then(data => setArea(data))
            .catch(error => console.error('Error fetching areas', error))
    };

    const fetchproduct = () => {
        axios.get('https://test-server-o94j.onrender.com/products/products')
            .then(response => response.data)
            .then(data => setProduct(data))
            .catch(error => console.error('Error fetching product', error))
    }

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        setUsername(storedUsername);
        const storedUserid = localStorage.getItem('userid');
        setUserid(storedUserid);
        fetchState();
        fetchcity();
        fetcharea();
        fetchproduct();
        fetchleadservey();
        const currentYear = new Date().getFullYear();
        setFormData(prevState => ({
            ...prevState,
            Yrs: currentYear - parseInt(formData.Since),
        }));
    }, [formData.Since]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        // if (name === 'PinCode' && value.length === 6) {
        //     fetchPincodeDetails(value);
        // }
    };

    const handleSelectChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData({
            ...formData,
            KeySkus: selectedValues
        });
    };

    // const fetchPincodeDetails = (pincode) => {
    //     axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
    //         .then(response => {
    //             if (response.data && response.data[0].Status === 'Success') {
    //                 const details = response.data[0].PostOffice[0];
    //                 setFormData(prevState => ({
    //                     ...prevState,
    //                     State: details.State,
    //                     City: details.District,
    //                     Area: details.Name,
    //                 }));
    //             } else {
    //                 alert('Invalid Pincode');
    //             }
    //         })
    //         .catch(error => console.error('Error fetching pin code details:', error));
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const maxId = leadserveyData.reduce((max, leadservey) => Math.max(max, parseInt(leadservey.leadserveyid)), 0);
            const newId = maxId + 1;
            const response = await axios.post('https://test-server-o94j.onrender.com/leadservey/add-leadservey', { ...formData, leadserveyid: newId, username, userid });

            if (response.status >= 200 && response.status < 300) {
                console.log('Leadservey added successfully');
                setFormData({
                    CustName: '', Since: '', Yrs: '', BizType: '', BizCatge: '', FName: '', LName: '', Addr1: '', Addr2: '', State: '', City: '', Area: '', PinCode: '', Country: 'India', MobNum: '', AltMobNum: '', EmailId: '', BizExp: '', CurrAddSince: '', BizHourFrom: '', BizHourTo: '', ApproxQtyBuyinKg: '', DaPurVal: '', KeySkus: [],
                });
                fetchleadservey();
                alert(`LeadServey Form Submitted Successfully. Leadservey ID: ${newId}`);
            } else {
                console.error('Error adding Leadservey', response.statusText);
                alert(`Error adding Leadservey: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error adding Leadservey:', error.message || error);
            alert(`Error adding Leadservey: ${error.message || error}`);
        }
    };

    const fetchleadservey = () => {
        axios.get('https://test-server-o94j.onrender.com/leadservey/leadserveys')
            .then(response => response.data)
            .then(data => setleadserveyData(data))
            .catch(error => console.error('Error fetching leadservey:', error))
    };

    const handleShowPreview = () => setShowPreview(true);
    const handleClosePreview = () => setShowPreview(false);

    return (
        <div>
            <Header />
            <div className='leadservey'>
                <div className='leadservey-heading'>
                    <h1>Lead Survey Form</h1>
                </div>
                <div style={{ fontSize: '0.8rem' }} className='leadservey-form'>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-2">
                            <Form.Group as={Col} className='customername' md="4" controlId="CustName">
                                <Form.Label>Business Name <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="CustName"
                                    value={formData.CustName}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.CustName || formData.CustName.trim() === '') ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='establishment' md="4" controlId="Since">
                                <Form.Label>Establishment <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    name="Since"
                                    value={formData.Since}
                                    onChange={handleChange}
                                    pattern="[0-9]{4}"
                                    style={{
                                        border: (!formData.Since || formData.Since.length !== 4) ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='years' as={Col} md="4" controlId="Yrs">
                                <Form.Label>Years</Form.Label>
                                <Form.Control
                                    disabled
                                    type="Number"
                                    name="Yrs"
                                    value={formData.Yrs}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='bussinesstype' as={Col} md="4" controlId="BizType">
                                <Form.Label>Bussiness Type <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Select
                                    className='selectbussinesstype'
                                    required
                                    type='text'
                                    name='BizType'
                                    value={formData.BizType}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.BizType || formData.BizType.trim() === '') ? '1px solid red' : ''
                                    }}
                                >
                                    <option value='' disabled>Bussiness Type</option>
                                    <option value="Proprietor">Proprietor</option>
                                    <option value="Partnership">Partnership</option>
                                    <option value="LLP(Limited Liability Partnership)">LLP(Limited Liability Partnership)</option>
                                    <option value="PVT LTD(Private Limited)">PVT LTD(Private Limited)</option>
                                    <option value="LTD(Limited Company)">LTD(Limited Company)</option>
                                    <option value="HUF(Hindu Undivided Family)">HUF(Hindu Undivided Family)</option>
                                    <option value="Ownership">Ownership</option>

                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='bussinesscategory' as={Col} md="4" controlId="BizCatge">
                                <Form.Label>Bussiness Category <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Select
                                    className='selectbussinesscategory'
                                    required
                                    type='text'
                                    name='BizCatge'
                                    value={formData.BizCatge}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.BizCatge || formData.BizCatge.trim() === '') ? '1px solid red' : ''
                                    }}
                                >
                                    <option value='' disabled>Bussiness Category</option>
                                    <option value="General Trade">General Trade</option>
                                    <option value="Modern Trade">Modern Trade</option>

                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='mt-2 firstname' as={Col} md="4" controlId="FName">
                                <Form.Label>First Name <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="FName"
                                    value={formData.FName}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.FName || /\d/.test(formData.FName)) ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='mt-2 lastname' as={Col} md="4" controlId="LName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="LName"
                                    value={formData.LName}
                                    onChange={handleChange}
                                    style={{
                                        border: /\d/.test(formData.LName) ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='address1' as={Col} md="4" controlId="Addr1">
                                <Form.Label>Address 1 <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="Addr1"
                                    value={formData.Addr1}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.Addr1 || formData.Addr1.trim() === '') ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='address2' as={Col} md="4" controlId="Addr2">
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Addr2"
                                    value={formData.Addr2}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='area' as={Col} md="4" controlId="Area">
                                <Form.Label>Area <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Select
                                    className='selectarea'
                                    required
                                    name="Area"
                                    value={formData.Area}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.Area || formData.Area.trim() === '') ? '1px solid red' : ''
                                    }}
                                >
                                    <option value='' disabled>Area</option>
                                    {area.map(area => (
                                        <option key={area.areaid} value={area.areaname}>
                                            {area.areaname}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='citys' as={Col} md="4" controlId="City">
                                <Form.Label>City <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Select
                                    className='selectcity'
                                    required
                                    name="City"
                                    value={formData.City}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.City || formData.City.trim() === '') ? '1px solid red' : ''
                                    }}
                                >
                                    <option value='' disabled>City</option>
                                    {city.map(city => (
                                        <option key={city.cityid} value={city.cityname}>
                                            {city.cityname}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='states' as={Col} md="4" controlId="State">
                                <Form.Label>State <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Select
                                    className='selectstate'
                                    required
                                    name="State"
                                    value={formData.State}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.State || formData.State.trim() === '') ? '1px solid red' : ''
                                    }}
                                >
                                    <option value='' disabled>State</option>
                                    {state.map(state => (
                                        <option key={state.stateid} value={state.statename}>
                                            {state.statename}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='mt-2 country' as={Col} md="4" controlId="Country">
                                <Form.Label>Country <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    disabled
                                    required
                                    type="text"
                                    name="Country"
                                    value={formData.Country}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='mt-2 pincode' as={Col} md="4" controlId="PinCode">
                                <Form.Label>PinCode <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="number"
                                    name="PinCode"
                                    value={formData.PinCode}
                                    onChange={handleChange}
                                    pattern="[0-9]{6}"
                                    style={{
                                        border: (!formData.PinCode || formData.PinCode.length !== 6) ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='mt-2 mobilenumber' as={Col} md="4" controlId="MobNum">
                                <Form.Label>Mob Number <a style={{ color: 'red' }}>*</a></Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <span style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '10px', color: '#6c757d' }}>+91</span>
                                    <Form.Control
                                        required
                                        type="number"
                                        name="MobNum"
                                        value={formData.MobNum}
                                        onChange={handleChange}
                                        pattern="[0-9]{10}"
                                        style={{
                                            paddingLeft: '40px',
                                            border: (!formData.MobNum || formData.MobNum.length !== 10) ? '1px solid red' : ''
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className='mt-2 alternatemobilenumber' as={Col} md="4" controlId="AltMobNum">
                                <Form.Label>Mob Number 2</Form.Label>
                                <div style={{ position: 'relative' }}>
                                    <span style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '10px', color: '#6c757d' }}>+91</span>
                                    <Form.Control
                                        type="number"
                                        name="AltMobNum"
                                        value={formData.AltMobNum}
                                        onChange={handleChange}
                                        style={{
                                            paddingLeft: '40px',
                                            border: formData.AltMobNum.length !== 10 && formData.AltMobNum.length !== 0 ? '1px solid red' : ''
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className='mt-2 emailid' as={Col} md="4" controlId="EmailId">
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="EmailId"
                                    value={formData.EmailId}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='mt-2 bussinessexperience' as={Col} md="4" controlId="BizExp">
                                <Form.Label>Bussiness Experience <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="BizExp"
                                    value={formData.BizExp}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.BizExp || formData.BizExp.trim() === '') ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='mt-2 currentaddresssince' as={Col} md="4" controlId="CurrAddSince">
                                <Form.Label>Current Addr Since Year <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="CurrAddSince"
                                    value={formData.CurrAddSince}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.CurrAddSince || formData.CurrAddSince.trim() === '') ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='mt-2 businesshourfrom' as={Col} md="4" controlId="BizHourFrom">
                                <Form.Label>Business Hour Form <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="time" // Use type="time" to input hours  
                                    name="BizHourFrom"
                                    value={formData.BizHourFrom}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.BizHourFrom || formData.BizHourFrom.trim() === '') ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='mt-2 businesshourto' as={Col} md="4" controlId="BizHourTo">
                                <Form.Label>Business Hour To <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Form.Control
                                    required
                                    type="time" // Use type="time" to input hours
                                    name="BizHourTo"
                                    value={formData.BizHourTo}
                                    onChange={handleChange}
                                    style={{
                                        border: (!formData.BizHourTo || formData.BizHourTo.trim() === '') ? '1px solid red' : ''
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='mt-2 approxqty' md="4" controlId="ApproxQtyBuyinKg">
                                <Form.Label>Approx Qty Buy In Kg</Form.Label>
                                <Form.Control
                                    type="Number"
                                    name="ApproxQtyBuyinKg"
                                    value={formData.ApproxQtyBuyinKg}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='mt-2 dailtpurchasevalue' md="4" controlId="DaPurVal">
                                <Form.Label>Daily Purchase Value</Form.Label>
                                <Form.Control
                                    type="Number"
                                    name="DaPurVal"
                                    value={formData.DaPurVal}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className='mt-2 keyskus' as={Col} md="12" controlId="KeySkus">
                                <Form.Label>Key SKUs <a style={{ color: 'red' }}>*</a></Form.Label>
                                <Select
                                    isMulti
                                    options={product.map(prod => ({ value: prod.productname, label: prod.productname }))}
                                    onChange={handleSelectChange}
                                    value={formData.KeySkus.map(sku => ({ label: sku, value: sku }))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: formData.KeySkus.length === 0 ? 'red' : provided.borderColor,
                                        }),
                                        menu: (provided) => ({ ...provided, zIndex: 1051 })
                                    }}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                    <div className='preview-button'>
                        <Button onClick={handleShowPreview}>PREVIEW</Button>
                    </div>
                </div>
            </div>
            <div className='footers'>
                <Footer />
            </div>
            <Modal show={showPreview} onHide={handleClosePreview} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Lead Survey Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LeadserveyPreview formData={formData} />
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={handleClosePreview}>
                        Back
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

function LeadserveyPreview({ formData }) {
    return (
        <div style={{ fontSize: '0.8rem', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Business Name:</strong>
                <span style={{ width: '150px' }} >{formData.CustName}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Establishment:</strong>
                <span style={{ width: '150px' }}>{formData.Since}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Years:</strong>
                <span style={{ width: '150px' }}>{formData.Yrs}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Business Type:</strong>
                <span style={{ width: '150px' }}>{formData.BizType}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Business Category:</strong>
                <span style={{ width: '150px' }}>{formData.BizCatge}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>First Name:</strong>
                <span style={{ width: '150px' }}>{formData.FName}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Last Name:</strong>
                <span style={{ width: '150px' }}>{formData.LName}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Address 1:</strong>
                <span style={{ width: '150px' }}>{formData.Addr1}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Address 2:</strong>
                <span style={{ width: '150px' }}>{formData.Addr2}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>State:</strong>
                <span style={{ width: '150px' }}>{formData.State}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>City:</strong>
                <span style={{ width: '150px' }}>{formData.City}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Area:</strong>
                <span style={{ width: '150px' }}>{formData.Area}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Country:</strong>
                <span style={{ width: '150px' }}>{formData.Country}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>PinCode:</strong>
                <span style={{ width: '150px' }}>{formData.PinCode}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Mob Number:</strong>
                <span style={{ width: '150px' }}>{formData.MobNum}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Mob Number 2:</strong>
                <span style={{ width: '150px' }}>{formData.AltMobNum}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Email Id:</strong>
                <span style={{ width: '150px' }}>{formData.EmailId}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Business Experience:</strong>
                <span style={{ width: '150px' }}>{formData.BizExp}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Current Address Since :</strong>
                <span style={{ width: '150px' }}>{formData.CurrAddSince}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Business Hour From:</strong>
                <span style={{ width: '150px' }}>{formData.BizHourFrom} AM</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Business Hour To:</strong>
                <span style={{ width: '150px' }}>{formData.BizHourTo} PM</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Approx Qty Buy In Kg:</strong>
                <span style={{ width: '150px' }}>{formData.ApproxQtyBuyinKg}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Daily Purchase Value:</strong>
                <span style={{ width: '150px' }}>{formData.DaPurVal}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <strong style={{ width: '150px' }}>Key SKU's(Name of Sku's):</strong>
                <span style={{ width: '150px' }}>{formData.KeySkus.join(', ')}</span>
            </div>
        </div>

    );
}

export default Leadservey;





