import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './Userprofile.css';
import Header from '../../header/Header'
import Footer from '../../footer/Footer'

function Userprofile() {
    const [loggedUsername, setLoggedUsername] = useState('');
    const [userReports, setUserReports] = useState([]);
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        const reports = localStorage.getItem('userReports');
        if (reports) {
            setUserReports(JSON.parse(reports));
        }
        const storedUsername = localStorage.getItem('userid');
        setLoggedUsername(storedUsername);

        axios.get(`https://test-server-o94j.onrender.com/user/users/${storedUsername}`)
            .then(response => {
                setUserDetails(response.data);
            })
            .catch(error => {
                console.error('Error fetching user details:', error);
            });

    }, []);

    return (
        <div>
            <div className="container">
                <Header />
                <div className="user-profile-container">
                    <h3 className='userprofile-heading'>My Profile</h3>
                    {userDetails && userDetails.image && (
                        <div className='mt-3 profile-image-container'>
                            <img
                                src={`https://test-server-o94j.onrender.com/${userDetails.image}`}
                                alt={userDetails.username}
                                className="profile-image"
                            />
                        </div>
                    )}

                    <div className="mt-3 user-details">
                        <p><strong>USER ID:</strong> {userDetails?.userid}</p>
                        <p><strong>USER NAME:</strong> {userDetails?.username}</p>
                        <p><strong>EMAIL ID:</strong> {userDetails?.email}</p>
                        <p><strong>DEPARTMENT:</strong> {userDetails?.department}</p>
                        <p><strong>FORMS:</strong></p>
                        <ul>
                            {userDetails?.forms.map((form, index) => (
                                <li key={index}>{form}</li>
                            ))}
                        </ul>
                        <p><strong>REPORTS:</strong></p>
                        <ul>
                            {userDetails?.reports.map((report, index) => (
                                <li key={index}>{report}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='footers'>
                <Footer />
            </div>
        </div>
    )
}

export default Userprofile