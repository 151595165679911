import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Particularuser.css';

function Particularuser() {
    const [particularuserData, setParticularUserData] = useState({});
    const { userid } = useParams();

    const fetchParticularUser = () => {
        axios.get(`https://test-server-o94j.onrender.com/user/users/${userid}`)
            .then(response => {
                setParticularUserData(response.data);
            })
            .catch(error => {
                console.error('Error fetching user details:', error);
            });
    };

    useEffect(() => {
        fetchParticularUser();
    }, [userid]);

    // Function to convert UTC time to Indian Standard Time (IST)
    const convertToIST = (utcDate) => {
        if (!utcDate) return ''; // Return empty string if date is not available
        const date = new Date(utcDate); // Convert UTC string to date object
        const options = { timeZone: 'Asia/Kolkata' }; // Set timezone to IST
        return date.toLocaleString('en-US', options); // Convert to IST string format
    };

    return (
        <div className="user-details-container">
            <h2>{particularuserData?.username}</h2>
            <div className="user-image-container">
                {particularuserData && particularuserData.image && (
                    <img
                        src={`https://test-server-o94j.onrender.com/${particularuserData.image}`}
                        alt={particularuserData.username}
                        className="user-image"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'fill', // Ensure the image covers the circle without stretching
                        }}
                    />
                )}
            </div>
            <p><strong>USER ID:</strong> {particularuserData?.userid}</p>
            <p><strong>USER NAME:</strong> {particularuserData?.username}</p>
            <p><strong>EMAIL ID:</strong> {particularuserData?.email}</p>
            <p><strong>MOBILE NUMBER:</strong>{particularuserData?.mobilenumber}</p>
            <p><strong>DEPARTMENT:</strong> {particularuserData?.department}</p>
            {particularuserData.forms && (
                <div className="forms-list">
                    <p><strong>FORMS:</strong></p>
                    <ul>
                        {particularuserData.forms.map((form, index) => (
                            <li key={index}>{form}</li>
                        ))}
                    </ul>
                </div>
            )}
            {particularuserData.reports && (
                <div className="reports-list">
                    <p><strong>REPORTS:</strong></p>
                    <ul>
                        {particularuserData.reports.map((report, index) => (
                            <li key={index}>{report}</li>
                        ))}
                    </ul>
                </div>
            )}
            <p><strong>Last Login:</strong> {convertToIST(particularuserData?.lastLogin)}</p>
        </div>
    );
}

export default Particularuser;

