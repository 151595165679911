import React from 'react'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'
import './Userchat.css';

function Userchat() {
    return (
        <div>
            <Header />
            <div className='user-userchat-container'>
                <h3 className='user-userchat-heading'>User Chats</h3>
            </div>

            <div className='footers'>
                <Footer />
            </div>
        </div>
    )
}

export default Userchat