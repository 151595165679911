import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './Frontpage.css';
import Header from '../header/Header';
import Carousel from 'react-bootstrap/Carousel';
import logo1 from '../../assets/images/userbanner1.png';
import logo2 from '../../assets/images/userbanner2.jpg';
import logo3 from '../../assets/images/userbanner3.png';
import logo4 from '../../assets/images/capsicum.jpg'
import logo5 from '../../assets/images/tomatoes.jpg';
import logo6 from '../../assets/images/grapes.jpg';
import logo7 from '../../assets/images/pomegranate.jpg';
import Footer from '../footer/Footer';

function Frontpage() {
    const [forms, setForms] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve userForms from local storage
        const userForms = JSON.parse(localStorage.getItem('userForms'));
        if (userForms) {
            setForms(userForms);
        }
    }, []);

    const handleFormClick = (formName) => {
        // Redirect to corresponding form route
        navigate(`/${formName.toLowerCase().replace(/\s+/g, '-')}`);
    };

    return (
        <div>
            <div>
                <Header />
                <div className='product-about'>
                    <Carousel data-bs-theme="dark">
                        <Carousel.Item className='frontpage-banner'>
                            <div className="centered-content">
                                <img
                                    className="centered-image"
                                    src={logo1}
                                    style={{ width: '100%', position: 'relative' }}
                                />
                                <div className='banner'>
                                    <h1 style={{ color: 'green' }}>APPLE</h1>
                                    <p>Explore Our Amazing Fruits & Vegetables With <b className='typewriter'></b></p>
                                </div>

                            </div>
                        </Carousel.Item>
                        <Carousel.Item className='frontpage-banner'>
                            <div className="centered-content">
                                <img
                                    className="centered-image"
                                    src={logo2}
                                    alt="First slide"
                                    style={{ width: '100%', position: 'relative', transform: 'scaleX(-1)' }}
                                />

                                <div className='banner'>
                                    <h1 style={{ color: 'orange' }}>ORANGE</h1>
                                    <p>Explore Our Amazing Fruits & Vegetables With <b className='typewriter'></b></p>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className='frontpage-banner'>
                            <div className="centered-content">
                                <img
                                    className="centered-image"
                                    src={logo3}
                                    alt="First slide"
                                    style={{ width: '100%', position: 'relative' }}
                                />
                                <div className='banner'>
                                    <h1 style={{ color: 'brown' }}>ONION</h1>
                                    <p>Explore Our Amazing Fruits & Vegetables With <b className='typewriter'></b></p>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className='frontpage-banner'>
                            <div className="centered-content">
                                <img
                                    className="centered-image"
                                    src={logo4}
                                    alt="First slide"
                                    style={{ width: '100%', position: 'relative' }}
                                />
                                <div className='banner'>
                                    <h1 style={{ color: 'red' }}>CAPSICUM</h1>
                                    <p>Explore Our Amazing Fruits & Vegetables With <b className='typewriter'></b></p>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className='frontpage-banner'>
                            <div className="centered-content">
                                <img
                                    className="centered-image"
                                    src={logo5}
                                    alt="First slide"
                                    style={{ width: '100%', position: 'relative' }}
                                />
                                <div className='banner'>
                                    <h1 style={{ color: 'red' }}>TOMATOE</h1>
                                    <p>Explore Our Amazing Fruits & Vegetables With <b className='typewriter'></b></p>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className='frontpage-banner'>
                            <div className="centered-content">
                                <img
                                    className="centered-image"
                                    src={logo6}
                                    alt="First slide"
                                    style={{ width: '100%', position: 'relative', transform: 'scaleX(-1)' }}
                                />
                                <div className='banner'>
                                    <h1 style={{ color: 'green' }}>GRAPES</h1>
                                    <p>Explore Our Amazing Fruits & Vegetables With <b className='typewriter'></b></p>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className='frontpage-banner'>
                            <div className="centered-content">
                                <img
                                    className="centered-image"
                                    src={logo7}
                                    alt="First slide"
                                    style={{ width: '100%', position: 'relative', transform: 'scaleX(-1)' }}
                                />
                                <div className='banner'>
                                    <h1 style={{ color: 'red' }}>POMEGRANATE</h1>
                                    <p>Explore Our Amazing Fruits & Vegetables With <b className='typewriter'></b></p>
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className='container'>
                    <div className='icons-cards'>
                        {forms.map((form, index) => (
                            <div className='cards' key={index} onClick={() => handleFormClick(form)}>
                                <div className='icons'>
                                    <i className="fa-brands fa-wpforms"></i>
                                </div>
                                <div className='cards-heading'>
                                    <h1>{form}</h1>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='footers'>
                <Footer />
            </div>

        </div>
    )
}

export default Frontpage