import React from 'react'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'
import './Aboutus.css';

function Aboutus() {
    return (
        <div>
            <Header />
            <div className='user-aboutus-container'>
                <h3 className='user-aboutus-heading'>About Us</h3>
            </div>

            <div className='footers'>
                <Footer />
            </div>
        </div>
    )
}

export default Aboutus