import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './Bargraph.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const fetchLeadprocessing = async () => {
    try {
        const response = await axios.get('https://test-server-o94j.onrender.com/leadprocessing/leadprocessing');
        return response.data;
    } catch (error) {
        console.error('Error fetching lead processing data:', error);
        return [];
    }
};

function Bargraph() {
    const [leadsData, setLeadsData] = useState([]);

    useEffect(() => {
        const fetchAndSetData = async () => {
            const data = await fetchLeadprocessing();
            setLeadsData(data);
        };

        fetchAndSetData();
    }, []);

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const totalLeadSurveys = labels.map(month => {
        const monthIndex = labels.indexOf(month);
        return leadsData.reduce((count, lead) => {
            const leadMonth = new Date(lead.createdAt).getMonth();
            return leadMonth === monthIndex ? count + 1 : count;
        }, 0);
    });

    const interestedSurveys = labels.map(month => {
        const monthIndex = labels.indexOf(month);
        return leadsData.reduce((count, lead) => {
            const leadMonth = new Date(lead.createdAt).getMonth();
            return lead.interested && leadMonth === monthIndex ? count + 1 : count;
        }, 0);
    });

    const notInterestedSurveys = labels.map(month => {
        const monthIndex = labels.indexOf(month);
        return leadsData.reduce((count, lead) => {
            const leadMonth = new Date(lead.createdAt).getMonth();
            return lead.notInterested && leadMonth === monthIndex ? count + 1 : count;
        }, 0);
    });

    const data = {
        labels,
        datasets: [
            {
                label: 'Total Lead',
                data: totalLeadSurveys,
                borderColor: 'rgb(21, 5, 250)',
                backgroundColor: 'rgba(21, 5, 250)', 
            },
            {
                label: 'Interested Lead',
                data: interestedSurveys,
                borderColor: 'rgb(5, 250, 29)',
                backgroundColor: 'rgba(5, 250, 29)', 
            },
            {
                label: 'Not-Interested Lead',
                data: notInterestedSurveys,
                borderColor: 'rgb(250, 5, 5)',
                backgroundColor: 'rgba(250, 5, 5)', 
            },
        ],

       
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Lead Survey Assigning Reports (Monthly)',
                font: {
                    size: 16,
                }
            },
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 10,
                    },
                },
            },
        },
    };

    return (
        <div className='chart-container'>
            <div className="chart">
                <Line options={options} data={data} width={600} height={400} />
            </div>
        </div>
    )
}

export default Bargraph;

