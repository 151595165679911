import React from 'react';
import './Report.css';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function Report() {
    return (
        <div>
            <h1 className='head'>LEAD REPORTS</h1>
            <div className=" mb-5 report-container">
                <div className="row">
                    {/* <div className="col-md-3 mb-3 reports">
                        <Card className='custom-card square-card'>
                            <Card.Body>
                                <Card.Title><i className="fa-brands fa-wpforms fa-2xl"></i></Card.Title>
                                <Link to={"/gtform-report"}>
                                    <Button className="mt-3 report-Button">GT FORM</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3 mb-3">
                        <Card className='custom-card square-card'>
                            <Card.Body>
                                <Card.Title><i className="fa-brands fa-wpforms fa-2xl"></i></Card.Title>
                                <Link to={''}>
                                    <Button className="mt-3 btn btn-success btn-success">AC FORM</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3 mb-3">
                        <Card className='custom-card square-card'>
                            <Card.Body>
                                <Card.Title><i className="fa-brands fa-wpforms fa-2xl"></i></Card.Title>
                                <Link to={"/gtbuyer-form-report"}>
                                    <Button className="mt-3 btn btn-success btn-success">GT BUYER</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div> */}
                    <div className="col-md-5 mb-4">
                        <Card className='custom-card square-card'>
                            <Card.Body>
                                <Card.Title><i className="fa-brands fa-wpforms fa-2xl"></i></Card.Title>
                                <Link to={"/leadservey-form-report"}>
                                    <Button className="mt-3 leadserveyreport btn btn-success btn-success">LEAD SURVEY</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-5 mb-4">
                        <Card className='custom-card square-card'>
                            <Card.Body>
                                <Card.Title><i className="fa-brands fa-wpforms fa-2xl"></i></Card.Title>
                                <Link to={"/leadprocessing-report"}>
                                    <Button className="mt-3 leadprocessingreport btn btn-success btn-success">LEAD PROCESSING</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-5 mb-4">
                        <Card className='custom-card square-card'>
                            <Card.Body>
                                <Card.Title><i className="fa-brands fa-wpforms fa-2xl"></i></Card.Title>
                                <Link to={"/leadprogressing-report"}>
                                    <Button className="mt-3 leadprocessingreport btn btn-success btn-success">LEAD PROSPECT</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Report;



