
import React from 'react';
import './Homeservice.css';
import icon1 from '../../../assets/images/farmer.png';
import icon2 from '../../../assets/images/export.png';
import icon3 from '../../../assets/images/supply-chain-management.png';
import icon4 from '../../../assets/images/supplier.png';
import icon5 from '../../../assets/images/manufacture.png';
import icon6 from '../../../assets/images/warehouse.png';
import icon7 from '../../../assets/images/box.png';
import icon8 from '../../../assets/images/conveyor-belt.png';
import client1 from '../../../assets/images/Blinkit.png';
import client2 from '../../../assets/images/Zepto.png';
import client3 from '../../../assets/images/Amazonfresh.jpg';
import client4 from '../../../assets/images/Itclimited.png';
import client5 from '../../../assets/images/Countrydelight.jpg';
import client6 from '../../../assets/images/Bigbasket.png';
import client7 from '../../../assets/images/Swiggyinstamart.png';
import client8 from '../../../assets/images/Jiomart.png';
import client9 from '../../../assets/images/starbazaar.jpeg';
import client10 from '../../../assets/images/Reliancefresh.png';
import client11 from '../../../assets/images/Adanifresh.jpeg';
import client12 from '../../../assets/images/Motherdiary.jpeg';

function ServiceBox({ icon, title, description }) {
    return (
        <div className="col-6 col-md-4 mb-4" data-aos='fade-down'>
            <div className="service-card h-100" data-aos='fade-down'>
                <div className="service-card-body text-center" data-aos='fade-down'>
                    <div className="icon mb-3" data-aos='fade-down'>
                        <img src={icon} alt={title} className="img-fluid" />
                    </div>
                    <h4 className="service-card-title" data-aos='fade-down'>{title}</h4>
                    <p className="service-card-text" data-aos='fade-down'>{description}</p>
                </div>
            </div>
        </div>
    );
}

const servicesData = [
    { icon: icon1, title: 'Farming', description: 'Network Of Over 110,000 Farmers Across India' },
    { icon: icon2, title: 'Exports', description: 'Network In 7+ Countries' },
    { icon: icon3, title: '3PL Supply Chain', description: '' },
    { icon: icon4, title: 'Supplier', description: '' },
    { icon: icon5, title: 'Producer', description: '' },
    { icon: icon6, title: 'Warehouse', description: 'More Than 17+ Years Of Warehouse Management' },
    { icon: icon7, title: 'Manpower', description: 'Operate 24*7*365 Days With Manpower Of 800+' },
    { icon: icon8, title: 'Food Processing', description: '' },
];

function Homeservice() {
    return (
        <div >
            <div className='our-services' data-aos='fade-down'>
                <div className="container-fluid mb-5" data-aos='fade-down'>
                    <div className="text-center mt-5 our-services-title" data-aos='fade-down'>
                        <h1>OUR SERVICES</h1>
                    </div>
                    <div className="row mt-5" data-aos='fade-down'>
                        {servicesData.map((service, index) => (
                            <ServiceBox
                                key={index}
                                icon={service.icon}
                                title={service.title}
                                description={service.description}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className='our-client' data-aos='fade-down'>
                <div className='container-fluid mb-5' data-aos='fade-down'>
                    <div className="text-center mt-5 our-services-title" data-aos='fade-down'>
                        <h1>OUR PARTNERS</h1>
                    </div>
                    <div className='client-logo d-flex justify-content-center flex-wrap' data-aos='fade-down'>
                        <img src={client1} alt='client1' data-aos='fade-down' />
                        <img src={client2} alt='client2' data-aos='fade-down' />
                        <img src={client3} alt='client3' data-aos='fade-down' />
                        <img src={client4} alt='client4' data-aos='fade-down' />
                        <img src={client5} alt='client5' data-aos='fade-down' />
                        <img src={client6} alt='client6' data-aos='fade-down' />
                        <img src={client7} alt='client7' data-aos='fade-down' />
                        <img src={client8} alt='client8' data-aos='fade-down' />
                        <img src={client9} alt='client9' data-aos='fade-down' />
                        <img src={client10} alt='client10' data-aos='fade-down' />
                        <img src={client11} alt='client11' data-aos='fade-down' />
                        <img src={client12} alt='client12' data-aos='fade-down' />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Homeservice;

