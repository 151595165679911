import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import axios from 'axios';
import user from '../../assets/images/people.png';
import form from '../../assets/images/forms.png';
import Bargraph from './bargraph/Bargraph';
import Piechart from './piechart/Piechart';
import { Link } from 'react-router-dom';
import Linegraph from './linegraph/Linegraph';
import { Button, Table } from 'react-bootstrap';

function Dashboard() {
  const [userCount, setUserCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [leadSurveyCount, setLeadSurveyCount] = useState(0);
  const [leadprocessingcount, setLeadProcessingCount] = useState(0)
  const [leadinterestedcount, setLeadInterestedCount] = useState(0)
  const [leadnotinterestedcount, setLeadNotInterestedCount] = useState(0)
  // const [products, setProducts] = useState([]);


  const fetchUsers = () => {
    axios.get('https://test-server-o94j.onrender.com/user/users')
      .then(response => {
        setUserCount(response.data.length);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }

  const fetchAdminUsers = () => {
    axios.get('https://test-server-o94j.onrender.com/user/users')
      .then(response => {
        // Filter users by department 'admin department'
        const adminUsers = response.data.filter(user => user.department === 'Admin Department');
        setAdminCount(adminUsers.length);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }
  

  const fetchOtherUsers = () => {
    axios.get('https://test-server-o94j.onrender.com/user/users')
      .then(response => {
        // Filter users by role 'admin'
        const otherUsers = response.data.filter(user => user.department === 'User Department');
        setUsersCount(otherUsers.length);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }

  const fetchleadservey = () => {
    axios.get('https://test-server-o94j.onrender.com/leadservey/leadserveys')
      .then(response => {
        setLeadSurveyCount(response.data.length)
      })
      .catch(error => {
        console.error('Error fetching leadserveys data:', error);
      })
  }

  const fetchleadprocessing = () => {
    axios.get('https://test-server-o94j.onrender.com/leadprocessing/leadprocessing')
      .then(response => {
        setLeadProcessingCount(response.data.length)
      })
      .catch(error => {
        console.error('Error fetching leadprocessing data:', error);
      })
  }

  const fetchinterestedlead = () => {
    axios.get('https://test-server-o94j.onrender.com/leadprocessing/leadprocessing')
      .then(response => {
        const interestedLeads = response.data.filter(lead => lead.interested === true);
        setLeadInterestedCount(interestedLeads.length);
      })
      .catch(error => {
        console.error('Error fetching leadprocessing data:', error);
      });
  };

  const fetchnotinterestedlead = () => {
    axios.get('https://test-server-o94j.onrender.com/leadprocessing/leadprocessing')
      .then(response => {
        const notinterestedLeads = response.data.filter(lead => lead.notInterested === true);
        setLeadNotInterestedCount(notinterestedLeads.length);
      })
      .catch(error => {
        console.error('Error fetching leadprocessing data:', error);
      });
  };

  // const fetchProducts = () => {
  //   axios.get('https://test-server-o94j.onrender.com/products/products')
  //     .then(response => response.data)
  //     .then(async data => {
  //       const dataWithDetails = await Promise.all(data.map(async item => {
  //         const categoryResponse = await axios.get(`https://test-server-o94j.onrender.com/category/viewcategory/${item.categoryid}`);
  //         const subcategoryResponse = await axios.get(`https://test-server-o94j.onrender.com/subcategory/viewsubcategory/${item.subcategoryid}`);
  //         const packingResponse = await axios.get(`https://test-server-o94j.onrender.com/packing/viewpacking/${item.packingid}`);

  //         const categoryname = categoryResponse.data.categoryname;
  //         const subcategoryname = subcategoryResponse.data.subcategoryname;
  //         const packingname = packingResponse.data.packingname;

  //         return { ...item, srNo: item.srNo, categoryname, subcategoryname, packingname };
  //       }));

  //       setProducts(dataWithDetails.slice(0, 5));
  //     })
  //     .catch(error => {
  //       if (error.response && error.response.status === 404) {
  //         setProducts([]);
  //       } else {
  //         console.error('Error fetching data:', error);
  //       }
  //     });
  // };

  useEffect(() => {
    fetchUsers();
    fetchAdminUsers();
    fetchOtherUsers();
    fetchleadservey();
    fetchleadprocessing();
    fetchinterestedlead();
    fetchnotinterestedlead();
    // fetchProducts();
  }, []);

  return (
    <div className="dashboardpage">
      <h1 className="head">DASHBOARD</h1>
      <div class="mt-4 px-4">
        <div class="row">
          <div class="col-md-3 dashboard-card">
            <Link to={"/leadservey-form-report"}>
              <div class="item gap-2 d-flex">
                <div class="circle bg-light-purple">
                  <img src={form} alt="" className="ml-2" />
                </div>
                <div className="sub-headingcards">
                  <span className="label purple-text">LEAD ASSIGNING</span>
                  <span className="value">No of Lead: {leadSurveyCount}</span>
                  <span className="value">No of Lead Assigned: {leadprocessingcount}</span>
                  <span className="value">No of Lead Assigning Pending : {leadSurveyCount - leadprocessingcount}</span>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-md-3 dashboard-card">
            <Link to={"/leadprocessing-report"}>
              <div class="item gap-2 d-flex">
                <div className="circle bg-purple">
                  <img src={form} alt="" className="ml-2" />
                </div>
                <div className="sub-headingcards">
                  <span className="label purple-text">LEAD PROCESSING</span>
                  <span className="value">No of Lead Processing: {leadprocessingcount}</span>
                  <span className="value">No of Lead Interested: {leadinterestedcount}</span>
                  {/* <span className="value">No of Lead Not-Interested: {leadnotinterestedcount}</span> */}
                  <span className='value'>Lead Processing Pending: {leadprocessingcount - (leadinterestedcount + leadnotinterestedcount)}</span>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-md-3 dashboard-card">
            <div class="item gap-2 d-flex">
              <div className="circle bg-purple">
                <img src={user} alt="" className="ml-2" />
              </div>
              <div className="sub-headingcards">
                <span className="label purple-text">USERS</span>
                <span className="value">No of Users: {userCount}</span>
                <span className="value">No of Admin: {adminCount}</span>
                <span className="value">No of Users: {usersCount}</span>
              </div>
            </div>
          </div>
          {/* <div class="col-md-3">
            <div class="item gap-2 d-flex">
              <div className="circle bg-pink">
                <img src="https://cdn-icons-png.flaticon.com/128/11482/11482372.png" alt="" className="ml-2" />
              </div>
              <div>
                <span className="label purple-text">TOTAL SALES</span>
                <span className="value">$89K</span>
                <div className="change green-text">
                  <i className="fa-solid fa-arrow-up" />11%
                  <a href="#" className="black-text">this month</a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="leadsurveygraph">
        <Linegraph />
        <Bargraph />
        {/* <Piechart /> */}
      </div>
      {/* <div className='dashboard-product'>
        <div className='text-center dashboard-product-head'>
          <h1>PRODUCT DETAILS</h1>
        </div>
        <div className="table-responsive mt-2">
          <table className="table">
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>PRODUCTCODE</th>
                <th>PRODUCTNAME</th>
                <th>PACKING</th>
                <th>CATEGORY</th>
                <th>SUBCATEGORY</th>
                <th>PRODUCTIMAGE</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{product.productcode}</td>
                  <td>{product.productname}</td>
                  <td>{product.packingname}</td>
                  <td>{product.categoryname}</td>
                  <td>{product.subcategoryname}</td>
                  <td>
                    <img src={`https://test-server-o94j.onrender.com/${product.productImage}`} alt="Product" style={{ width: '50px', height: '50px' }} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <Button>
            <Link to={'/product'}>View All</Link>
          </Button>
        </div>
      </div> */}
    </div>

  );
}

export default Dashboard;







