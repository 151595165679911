import React, { useState, useEffect } from 'react'
import './City.css';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Row } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import companylogo from '../../assets/images/logo2.png';

function DeleteConfirmationModal({ show, handleClose, handleDelete, cityName }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete State</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are You sure want to delete <strong>{cityName}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function CustomModal({ show, handleClose, title, message }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function City() {
    const [rowData, setRowData] = useState([]);
    const [newCity, setNewCity] = useState('');
    const [state, setState] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [editCity, setEditCity] = useState(null);
    const [editedCityName, setEditedCityName] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', message: '' });
    const [searchTerm, setSearchTerm] = useState('');


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const fetchCities = () => {
        axios.get('https://test-server-o94j.onrender.com/city/cities')
            .then(response => response.data)
            .then(async data => {
                const dataWithSerialNumber = await Promise.all(data.map(async item => {
                    const stateResponse = await axios.get(`https://test-server-o94j.onrender.com/state/viewstate/${item.stateid}`);
                    const statename = stateResponse.data.statename;

                    return { ...item, srNo: item.srNo, statename }
                }));
                setRowData(dataWithSerialNumber)
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    setRowData([])
                } else {
                    console.error('Error fetching data:', error);
                }
            })
    };

    const fetchstate = () => {
        axios.get('https://test-server-o94j.onrender.com/state/states')
            .then(response => response.data)
            .then(data => setState(data))
            .catch(error => console.error('Error fetching state:', error))
    };

    useEffect(() => {
        fetchCities();
        fetchstate()
    }, [])

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredItems = rowData.filter(city => city.cityname.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pagenumber) => setCurrentPage(pagenumber)

    const checkAssociatedareas = async (cityId) => {
        try {
            const response = await axios.get(`https://test-server-o94j.onrender.com/city/check-associated-areas/${cityId}`)
            return response.data.hasAreas
        } catch (error) {
            console.error('Error checking associated area:', error);
            return false;
        }
    }

    const handleDeleteCity = async (city) => {
        const hasAreas = await checkAssociatedareas(city.cityid)

        if (hasAreas) {
            setModalContent({
                title: 'Error',
                message: 'Cannot delete city with associated area. Delete city first'
            })
            setShowModal(true)
        } else {
            setDeleteConfirmation(city)
        }
    }

    const confirmDeleteCity = () => {
        axios.post('https://test-server-o94j.onrender.com/city/delete-city', {
            cityid: deleteConfirmation.cityid
        })
            .then(() => {
                fetchCities()
                setDeleteConfirmation(null)
            })
            .catch(error => console.error('Error deleting state:', error))
    }

    const cancelDeleteCity = () => {
        setDeleteConfirmation(null)
    }

    const handleAddCity = () => {
        if (newCity.trim() !== '' && selectedState.trim() !== '') {
            const selectedStateObject = state.find(state => state.statename === selectedState)

            if (selectedStateObject) {
                const stateid = selectedStateObject.stateid

                axios.get('https://test-server-o94j.onrender.com/city/cities')
                    .then(response => response.data)
                    .then(data => {
                        const maxCityId = data.reduce((max, city) => {
                            const cityId = parseInt(city.cityid);
                            return cityId > max ? cityId : max;
                        }, 0)

                        const generatedCityId = (maxCityId + 1).toString();

                        return axios.post('https://test-server-o94j.onrender.com/city/add-city', {
                            cityid: generatedCityId,
                            cityname: newCity,
                            statename: selectedState,
                            stateid: stateid,
                        });
                    })
                    .then(() => {
                        setNewCity('');
                        setSelectedState('');
                        fetchCities()
                    })
                    .catch(error => console.error('Error adding city:', error));
            }
        }
    }



    const handleUpdateCity = (city) => {
        setEditCity(city);
        setEditedCityName(city.cityname);
        setShowEditModal(true);
    }

    const confirmEditCity = () => {
        axios.post('https://test-server-o94j.onrender.com/city/update-city', {
            cityid: editCity.cityid,
            newCityName: editedCityName,
        })
            .then(() => {
                fetchCities();
                setEditCity(null)
                setShowEditModal(false)
            })
            .catch(error => console.error('Error updating city:', error))
    };


    const handleResetCityInput = () => {
        setNewCity('');
        setSelectedState('')
    }


    const imageToBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            };
            img.onerror = (err) => {
                reject(err);
            };
        });
    };

    const downloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait', // Adjust orientation to fit more content horizontally
            unit: 'mm', // Set measurement unit to millimeters
            format: 'a4' // Set page format to A4
        });

        // Convert company logo to data URL
        const logo = new Image();
        logo.src = companylogo;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        logo.onload = async function () {
            canvas.width = logo.width;
            canvas.height = logo.height;
            ctx.drawImage(logo, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            doc.addImage(dataURL, 'PNG', 5, 2, 18, 12);

            // Add company name on the left side
            doc.setFontSize(12);
            const companyName = 'PRIME FRESH LIMITED (Vashi/DC)';
            const companyNameX = 24;
            const companyNameY = 6;
            doc.text(companyName, companyNameX, companyNameY);

            // Get the width of the company name
            const companyNameWidth = doc.getTextWidth(companyName);

            // Draw line below company name
            const lineX1 = companyNameX;
            const lineX2 = companyNameX + companyNameWidth;
            const lineY = companyNameY + 1; // Move the line slightly below the text
            doc.line(lineX1, lineY, lineX2, lineY);

            // Add "Lead Survey Reports" text under the line
            const leadReportText = 'Cities Reports';
            const leadReportTextX = companyNameX; // Adjust the position as needed
            const leadReportTextY = lineY + 5; // Move the text below the line
            doc.setFontSize(12);
            doc.text(leadReportText, leadReportTextX, leadReportTextY);

            doc.setFontSize(12)
            const currentDate = new Date().toLocaleDateString('en-IN');
            doc.text('Date: ' + currentDate, doc.internal.pageSize.width - 15, 7, null, null, 'right');

            // Generate table
            const tableColumns = [
                "Sr.No", "City Id", "City Name", "State Name"
            ];
            const tableRows = [];
            rowData.forEach((item, index) => {
                const rowData = [
                    index + 1,
                    item.cityid,
                    item.cityname,
                    item.statename
                ];
                tableRows.push(rowData);
            });

            const totalRecords = tableRows.length;
            const recordsPerPage = 43;


            // Add table
            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: leadReportTextY + 3, // Start position of the table below "Lead Survey Reports" text
                margin: { left: 5 }, // Set margin-left
                didParseCell: function (data) {
                    const cell = data.cell;
                    if (tableColumns.includes(data.cell.raw)) {
                        // For table column headers (tableColumns)
                        cell.styles.fillColor = [204, 204, 204]; // Grey background color
                        cell.styles.textColor = [0, 0, 0]; // Black font color
                        cell.styles.fontSize = 9;
                        cell.styles.fontStyle = 'bold';
                        cell.styles.halign = 'center';

                    } else {
                        // For table rows (tableRows)
                        cell.styles.fillColor = [255, 255, 255]; // White background color
                        cell.styles.textColor = [0, 0, 0]; // Black font color
                        cell.styles.fontSize = 7;

                    }
                    cell.styles.lineWidth = 0.2;
                    cell.styles.lineColor = [0, 0, 0];
                },
                didDrawPage: function (data) {
                    const pageCount = doc.internal.getNumberOfPages();
                    const pageNumber = data.pageNumber;
                    const pageHeight = doc.internal.pageSize.height;

                    const startRecord = (pageNumber - 1) * recordsPerPage + 1;
                    const endRecord = Math.min(pageNumber * recordsPerPage, totalRecords);
                    const recordsText = `${startRecord} to ${endRecord}`;

                    const pageWidth = doc.internal.pageSize.width;
                    const marginRight = 15;
                    const marginLeft = 200;


                    doc.setFontSize(10);
                    doc.text(`Page ${pageNumber} of ${pageCount}`, pageWidth - marginLeft, pageHeight - 14, null, null, 'left');
                    doc.text(`No of Records: ${recordsText}`, pageWidth - marginRight, pageHeight - 14, null, null, 'right');
                }
            });

            // Add line above the footer
            doc.setLineWidth(0.5);
            doc.line(10, doc.internal.pageSize.height - 20, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 20);
            const preparedBy = 'admin';
            const currentDateFooter = new Date().toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });
            // Fetch IP address
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const ipAddress = response.data.ip;
                const footerText = `Prepared by ${preparedBy} on ${currentDate} ${currentDateFooter} | IP Address: ${ipAddress}`;
                doc.setFontSize(10);
                doc.text('Prime Fresh Limited (Vashi/DC)', 10, doc.internal.pageSize.height - 9);
                doc.setFontSize(10);
                doc.text(footerText, 10, doc.internal.pageSize.height - 4);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }

            // Save the PDF
            doc.save('states_reports.pdf');
        };
    };

    const downloadExcel = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('States Reports');
    
            // Adding company logo
            const logoBase64 = await imageToBase64(companylogo);
            const logoImage = workbook.addImage({
                base64: logoBase64,
                extension: 'png',
            });
    
            worksheet.addImage(logoImage, {
                tl: { col: 0, row: 0 },
                ext: { width: 150, height: 100 }
            });
    
            worksheet.mergeCells('A1:D1');
            worksheet.mergeCells('A2:C2');
            worksheet.mergeCells('D2');
            worksheet.mergeCells('A3:C3');
    
            const companyCell = worksheet.getCell('A2');
            companyCell.value = 'PRIME FRESH LIMITED (Vashi/DC)';
            companyCell.font = { size: 16, bold: true };
            companyCell.alignment = { vertical: 'middle', horizontal: 'center' };
    
            const currentDates = new Date().toLocaleDateString('en-IN');
            const companyCells = worksheet.getCell('D2');
            companyCells.value = `Date: ${currentDates}`;
            companyCells.font = { size: 16, bold: true };
            companyCells.alignment = { vertical: 'middle', horizontal: 'center' };
    
            const reportCell = worksheet.getCell('A3');
            reportCell.value = 'Cities Reports';
            reportCell.font = { size: 14, bold: true };
            reportCell.alignment = { vertical: 'middle', horizontal: 'center' };
    
            // Adding spacing rows
            worksheet.addRow([]);
            worksheet.addRow([]);
    
            // Defining columns
            const excelColumns = [
                { header: 'Sr.No', key: 'srno', width: 20 },
                { header: 'City ID', key: 'cityid', width: 20 },
                { header: 'City Name', key: 'cityname', width: 50 },
                { header: 'State Name', key: 'statename', width: 50 },
            ];
    
            worksheet.columns = excelColumns;
    
            // Adding header row with styles
            const headerRow = worksheet.addRow(excelColumns.map(col => col.header));
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF000000' }
                };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
    
            // Adding data rows
            filteredItems.forEach((item, index) => {
                const row = worksheet.addRow({
                    srno: index + 1,
                    cityid: item.cityid,
                    cityname: item.cityname,
                    statename: item.statename
                });
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });
            });
    
            // Adding footer
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString();
            const currentDateFooter = currentDate.toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });
            const preparedBy = 'admin';
    
            const response = await axios.get('https://api.ipify.org?format=json');
            const ipAddress = response.data.ip;
    
            const footerText = `Prepared by ${preparedBy} on ${formattedDate}, ${currentDateFooter}  | IP Address: ${ipAddress}`;
    
            // Add an empty row before footer
            worksheet.addRow([]);
            const footerRow = worksheet.addRow([footerText]);
    
            // Merge cells for the footer across all columns
            const lastRowNumber = footerRow.number;
            worksheet.mergeCells(`A${lastRowNumber}:D${lastRowNumber}`);
    
            // Center align the footer text
            footerRow.getCell(1).font = { italic: true };
            footerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    
            // Adjust column widths
            worksheet.columns.forEach((column) => {
                if (column.header) {
                    column.width = Math.max(10, column.width, column.header.length + 5);
                }
            });
    
            // Generate and download the Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'states_reports.xlsx';
            link.click();
        } catch (error) {
            console.error("Error generating Excel file:", error);
        }
    };
    
    const printStateDetails = () => {
        const printContent = document.getElementById('printcity').innerHTML;
        const originalContent = document.body.innerHTML;

        // Get the current date and week starting day
        const currentDate = new Date().toLocaleDateString('en-IN');

        const printableContentWithLogo = `
        <div style="font-family: Arial, sans-serif; margin-left: 20px;">
            <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                <img src="${companylogo}" alt="Company Logo" style="width: 100px; height: auto; margin-right: 20px;">
                    <div>
                        <h1 style="font-size: 18px; margin: 0;">Prime Fresh Limited (Vashi/DC)</h1>
                        <hr style="margin: 5px 0;">
                        <h2 style="font-size: 16px; margin: 0;">States Reports</h2>
                    </div>
                <div>
                    <h1 style="margin-left: 270px;">${currentDate}</h1>
                </div>
            </div>
            ${printContent}
            <div>
                <div class="print-footer">
                  <hr style="border: 0; border-top: 0.5px solid #000;">
                  <div style="padding: 5px 10px; text-align: left; font-size: 10px; margin-top: -10px;">
                  <p>Prime Fresh Limited (Vashi/DC)</p>
                  <p style="margin-top: -8px;">Prepared by admin on ${currentDate} ${currentTime},${weekStartingDay}</p>
               </div>
            </div>
        </div>
    `;


        // Combine printable content with the footer
        const printableContentWithFooter = printableContentWithLogo;

        // Replace the current body content with the printable content including the logo, company name, hr, and user details
        document.body.innerHTML = printableContentWithFooter;

        // Print the content
        window.print();

        // Restore the original content after printing
        document.body.innerHTML = originalContent;
        window.location.reload(); // Refresh the page to restore original content
    };

    // Function to get the starting day of the week
    function getWeekStartingDay() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = new Date().getDay();
        return days[currentDayIndex];
    }

    const currentTime = new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const currentDate = new Date().toLocaleDateString('en-IN');
    const weekStartingDay = getWeekStartingDay();

    return (
        <div>
            <h1 className='head'>CITY</h1>
            <div className='city'>
                <div className='city-box'>
                    <Form className='city-content' style={{ height: '50%', width: '30%', float: 'left', fontSize: '0.8rem', marginLeft: '0px' }}>
                        <Row>
                            <Form.Group>
                                <h5 style={{ fontSize: '1rem' }} className='mt-3'>CITY NAME</h5>
                                <Form.Control
                                    type='text'
                                    placeholder='Enter city name'
                                    value={newCity}
                                    onChange={(e) => setNewCity(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group>
                                <h6 className='mt-3'>SELECT STATE</h6>
                                <Form.Select className='mt-1'
                                    value={selectedState}
                                    onChange={(e) => setSelectedState(e.target.value)}
                                >
                                    <option value='' disabled>Select a state</option>
                                    {state.map(state => (
                                        <option key={state.stateid} value={state.statename}>
                                            {state.statename}
                                        </option>
                                    ))}

                                </Form.Select>
                            </Form.Group>
                            <div className='city-header'>
                                <Button onClick={handleAddCity}>Add New</Button>
                                <Button className='mx-3' onClick={handleResetCityInput}>Reset</Button>
                            </div>
                        </Row>
                    </Form>
                    <div className='city-table' style={{ height: '50%', width: '70%', float: 'right', fontSize: '0.8rem' }}>
                        <div className='category-pdfexcel'>
                            <Button onClick={downloadPDF}>
                                <i className="fa-solid fa-file-pdf fa-fade fa-xl"></i>
                            </Button>
                            <Button className='mx-2' onClick={downloadExcel}>
                                <i className="fa-solid fa-file-excel fa-fade fa-xl"></i>
                            </Button>
                            <Button className='mx-2' onClick={printStateDetails}>
                                <i className="fa-solid fa-print fa-fade fa-xl"></i>
                            </Button>
                        </div>
                        <div className='flex items-center justify-end rounded-5 mx-2'>
                            <input
                                type='text'
                                className='bg-#F8F9FC h-40 outline-none pl-13 w-350 rounded-5 placeholder-text-14 leading-20 font-normal'
                                placeholder='Search city name.....'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>SR.NO</th>
                                    <th>CITY</th>
                                    <th>STATE</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((city, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{indexOfFirstItem + index + 1}</td>
                                            <td>{city.cityname}</td>
                                            <td>{city.statename}</td>
                                            <td>
                                                <a onClick={() => handleUpdateCity(city)}>
                                                    <i className="mx-3 fa-solid fa-pen fa-beat-fade fa-xl" style={{ color: 'green' }}></i>
                                                </a>
                                                <a onClick={() => handleDeleteCity(city)}>
                                                    <i className="mx-3 fa-solid fa-trash fa-beat-fade fa-xl" style={{ color: 'red' }}></i>
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className='pagination-container'>
                            {Array.from({ length: Math.ceil(rowData.length / itemsPerPage) }, (_, index) => (
                                <button
                                    className='pagination-button mx-2'
                                    key={index}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                {showEditModal && editCity && (
                    <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit City</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input
                                type="text"
                                value={editedCityName}
                                onChange={(e) => setEditedCityName(e.target.value)}
                                className="form-control"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={confirmEditCity}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}


                {deleteConfirmation && (
                    <DeleteConfirmationModal
                        show={!!deleteConfirmation}
                        handleClose={cancelDeleteCity}
                        handleDelete={confirmDeleteCity}
                        cityName={deleteConfirmation.cityname} />
                )}

                <CustomModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    title={modalContent.title}
                    message={modalContent.message} />

            </div>
            <div id="printcity" style={{ display: 'none' }}>
                <Table>
                    <thead>
                        <tr style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.6rem' }}>
                            <th>Sr.No</th>
                            <th>City ID</th>
                            <th>City Name</th>
                            <th>State Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems.map((item, index) => (
                            <tr key={index} style={{ fontSize: '0.5rem' }}>
                                <td>{index + 1}</td>
                                <td>{item.cityid}</td>
                                <td>{item.cityname}</td>
                                <td>{item.statename}</td>
                            </tr>
                        ))}
                        {/* Footer Row */}
                        {/* <tr>
                            <td colSpan="8" style={{ fontSize: '0.5rem' }}>
                                Prepared by admin on {currentDate} {currentTime},{weekStartingDay}
                            </td>
                        </tr> */}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default City