import React from 'react';
import { MutatingDots } from 'react-loader-spinner';
import './Loader.css'; // Import CSS file for custom styling

function Loader() {
    return (
        <div className="loader-container">
            <MutatingDots
                visible={true}
                height={150}
                width={100}
                color="#4fa94d"
                secondaryColor="#4fa94d"
                radius={12.5}
                ariaLabel="mutating-dots-loading"
            />
        </div>
    );
}

export default Loader;
