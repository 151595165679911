
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Category.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import companylogo from '../../assets/images/logo2.png';



function DeleteConfirmationModal({ show, handleClose, handleDelete, categoryName }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete <strong>{categoryName}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function EditCategoryModal({ show, handleClose, handleSave, categoryName, setCategoryName }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="text"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    className="form-control"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

// New Modal Component
function CustomModal({ show, handleClose, title, message }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}



function Category() {
    const [rowData, setRowData] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [editCategory, setEditCategory] = useState(null);
    const [editedCategoryName, setEditedCategoryName] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [editModalShow, setEditModalShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', message: '' });
    const [searchTerm, setSearchTerm] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const fetchCategories = () => {
        axios.get('https://test-server-o94j.onrender.com/category/category')
            .then(response => response.data)
            .then(data => {
                setRowData(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredItems = rowData.filter(category => category.categoryname.toLowerCase().includes(searchTerm.toLowerCase()));
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    const checkAssociatedSubcategories = async (categoryId) => {
        try {
            const response = await axios.get(`https://test-server-o94j.onrender.com/category/check-associated-subcategories/${categoryId}`);
            return response.data.hasSubcategories;
        } catch (error) {
            console.error('Error checking associated subcategories:', error);
            return false;
        }
    };

    const handleDeleteCategory = async (category) => {
        const hasSubcategories = await checkAssociatedSubcategories(category.categoryid);

        if (hasSubcategories) {
            setModalContent({
                title: 'Error',
                message: 'Cannot delete category with associated subcategories. Delete subcategories first.',
            });
            setShowModal(true);
        } else {
            setDeleteConfirmation(category);
        }
    };

    const confirmDeleteCategory = () => {
        axios.post('https://test-server-o94j.onrender.com/category/delete-category', {
            categoryid: deleteConfirmation.categoryid,
        })
            .then(() => {
                fetchCategories(); // Refresh the category list
                setDeleteConfirmation(null);
            })
            .catch(error => console.error('Error deleting category:', error));
    };

    const cancelDeleteCategory = () => {
        setDeleteConfirmation(null);
    };

    const handleAddCategory = () => {
        if (newCategory.trim() !== '') {
            axios.get('https://test-server-o94j.onrender.com/category/category')
                .then(response => response.data)
                .then(data => {
                    // Find the maximum category code
                    const maxCategoryId = data.reduce((max, category) => {
                        const categoryId = parseInt(category.categoryid);
                        return categoryId > max ? categoryId : max;
                    }, 0);

                    // Calculate the next category code
                    const generatedCategoryId = (maxCategoryId + 1).toString();

                    return axios.post('https://test-server-o94j.onrender.com/category/add-category', {
                        categoryid: generatedCategoryId,
                        categoryname: newCategory,
                    });
                })
                .then(() => {
                    setNewCategory('');
                    fetchCategories();
                })
                .catch(error => console.error('Error adding category:', error));
        }
    };

    const handleUpdateCategory = (category) => {
        setEditCategory(category);
        setEditedCategoryName(category.categoryname);
        setEditModalShow(true);
    };

    const confirmEditCategory = () => {
        axios.post('https://test-server-o94j.onrender.com/category/update-category', {
            categoryid: editCategory.categoryid,
            newCategoryName: editedCategoryName,
        })
            .then(() => {
                fetchCategories(); // Refresh the category list
                setEditCategory(null);
                setEditModalShow(false);
            })
            .catch(error => console.error('Error updating category:', error));
    };

    const handleResetCategoryInput = () => {
        setNewCategory('');
    };

    const downloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait', // Adjust orientation to fit more content horizontally
            unit: 'mm', // Set measurement unit to millimeters
            format: 'a4' // Set page format to A4
        });

        // Convert company logo to data URL
        const logo = new Image();
        logo.src = companylogo;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        logo.onload = async function () {
            canvas.width = logo.width;
            canvas.height = logo.height;
            ctx.drawImage(logo, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            doc.addImage(dataURL, 'PNG', 5, 2, 18, 12);

            // Add company name on the left side
            doc.setFontSize(12);
            const companyName = 'PRIME FRESH LIMITED (Vashi/DC)';
            const companyNameX = 24;
            const companyNameY = 6;
            doc.text(companyName, companyNameX, companyNameY);

            // Get the width of the company name
            const companyNameWidth = doc.getTextWidth(companyName);

            // Draw line below company name
            const lineX1 = companyNameX;
            const lineX2 = companyNameX + companyNameWidth;
            const lineY = companyNameY + 1; // Move the line slightly below the text
            doc.line(lineX1, lineY, lineX2, lineY);

            // Add "Lead Survey Reports" text under the line
            const leadReportText = 'Category Reports';
            const leadReportTextX = companyNameX; // Adjust the position as needed
            const leadReportTextY = lineY + 5; // Move the text below the line
            doc.setFontSize(12);
            doc.text(leadReportText, leadReportTextX, leadReportTextY);

            doc.setFontSize(12)
            const currentDate = new Date().toLocaleDateString('en-IN');
            doc.text('Date: ' + currentDate, doc.internal.pageSize.width - 15, 7, null, null, 'right');

            // Generate table
            const tableColumns = [
                "Sr.No", "Category Id", "Category Name",
            ];
            const tableRows = [];
            rowData.forEach((item, index) => {
                const rowData = [
                    index + 1,
                    item.categoryid,
                    item.categoryname
                ];
                tableRows.push(rowData);
            });

            // Add table
            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: leadReportTextY + 3, // Start position of the table below "Lead Survey Reports" text
                margin: { left: 5 }, // Set margin-left
                didParseCell: function (data) {
                    const cell = data.cell;
                    if (tableColumns.includes(data.cell.raw)) {
                        // For table column headers (tableColumns)
                        cell.styles.fillColor = [204, 204, 204]; // Grey background color
                        cell.styles.textColor = [0, 0, 0]; // Black font color
                        cell.styles.fontSize = 9;
                        cell.styles.fontStyle = 'bold';
                        cell.styles.halign = 'center';

                    } else {
                        // For table rows (tableRows)
                        cell.styles.fillColor = [255, 255, 255]; // White background color
                        cell.styles.textColor = [0, 0, 0]; // Black font color
                        cell.styles.fontSize = 7;

                    }
                    cell.styles.lineWidth = 0.2;
                    cell.styles.lineColor = [0, 0, 0];
                }
            });

            // Add line above the footer
            doc.setLineWidth(0.5);
            doc.line(10, doc.internal.pageSize.height - 20, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 20);
            const preparedBy = 'admin';
            const currentDateFooter = new Date().toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });
            // Fetch IP address
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const ipAddress = response.data.ip;
                const footerText = `Prepared by ${preparedBy} on ${currentDate} ${currentDateFooter} | IP Address: ${ipAddress}`;
                doc.setFontSize(10);
                doc.text('Prime Fresh Limited (Vashi/DC)', 10, doc.internal.pageSize.height - 15);
                doc.setFontSize(10);
                doc.text(footerText, 10, doc.internal.pageSize.height - 10);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }

            // Save the PDF
            doc.save('category_reports.pdf');
        };
    };

    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.book_new();

        // Define columns for Excel
        const excelColumns = [
            "Sr.No", "Category Id", "Category Name"
        ];

        // Add headers row
        XLSX.utils.sheet_add_aoa(worksheet, [excelColumns], { origin: 'A1' });

        // Add data rows
        const excelData = rowData.map((item, index) => {
            return [
                index + 1,
                item.categoryid,
                item.categoryname
            ];
        });
        XLSX.utils.sheet_add_aoa(worksheet, excelData, { origin: 'A2' });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Category Form');
        XLSX.writeFile(workbook, 'category_reports.xlsx');
    };

    const printCategoryDetails = () => {
        const printContent = document.getElementById('printcategory').innerHTML;
        const originalContent = document.body.innerHTML;

        // Get the current date and week starting day
        const currentDate = new Date().toLocaleDateString('en-IN');

        const printableContentWithLogo = `
        <div style="font-family: Arial, sans-serif; margin-left: 20px;">
            <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                <img src="${companylogo}" alt="Company Logo" style="width: 100px; height: auto; margin-right: 20px;">
                    <div>
                        <h1 style="font-size: 18px; margin: 0;">Prime Fresh Limited (Vashi/DC)</h1>
                        <hr style="margin: 5px 0;">
                        <h2 style="font-size: 16px; margin: 0;">Category Reports</h2>
                    </div>
                <div>
                    <h1 style="margin-left: 270px;">${currentDate}</h1>
                </div>
            </div>
            ${printContent}
        </div>
    `;
        // Combine printable content with the footer
        const printableContentWithFooter = printableContentWithLogo;
        // Replace the current body content with the printable content including the logo, company name, hr, and user details
        document.body.innerHTML = printableContentWithFooter;
        // Print the content
        window.print();
        // Restore the original content after printing
        document.body.innerHTML = originalContent;
        window.location.reload(); // Refresh the page to restore original content
    };

    // Function to get the starting day of the week
    function getWeekStartingDay() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = new Date().getDay();
        return days[currentDayIndex];
    }

    const currentTime = new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const currentDate = new Date().toLocaleDateString('en-IN');
    const weekStartingDay = getWeekStartingDay();

    return (
        <div>
            <h1 className='head'>CATEGORY</h1>
            <div className='category'>
                <div className='category-box'>
                    <Form className='category-content' style={{ height: '50%', width: '30%', float: 'left', fontSize: '0.8rem', marginLeft: '0px' }}>
                        <Row>
                            <Form.Group>
                                <h5 style={{ fontSize: '1rem' }} className='mt-2 '>CATEGORY NAME</h5>
                                <Form.Control
                                    required
                                    style={{ fontSize: '0.8rem' }}
                                    type="text"
                                    placeholder="Enter Category Name"
                                    value={newCategory}
                                    onChange={(e) => setNewCategory(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <div className='category-header' >
                                <Button onClick={handleAddCategory}>Add New</Button>
                                <Button onClick={handleResetCategoryInput} className='mx-2'>Reset</Button>
                            </div>
                        </Row>
                    </Form>
                    <div className='category-table' style={{ height: '50%', width: '70%', float: 'right' }}>
                        <div className='category-pdfexcel'>
                            <Button onClick={downloadPDF}>
                                <i className="fa-solid fa-file-pdf fa-fade fa-xl"></i>
                            </Button>
                            <Button className='mx-2' onClick={downloadExcel}>
                                <i className="fa-solid fa-file-excel fa-fade fa-xl"></i>
                            </Button>
                            <Button className='mx-2' onClick={printCategoryDetails}>
                                <i className="fa-solid fa-print fa-fade fa-xl"></i>
                            </Button>
                        </div>
                        <div className='flex items-center justify-end rounded-5 mx-2'>
                            <input
                                type='text'
                                className='bg-#0fbf15 h-40 outline-none pl-13 w-35 rounded-5 placeholder-text-14 leading-20 font-normal'
                                placeholder='Search category name.....'
                                value={searchTerm}
                                style={{ fontSize: '0.8rem' }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <table style={{ fontSize: '0.8rem' }}>
                            <thead>
                                <tr>
                                    <th>SR. NO.</th>
                                    <th>CATEGORY NAME</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((category, index) => (
                                    <tr key={index}>
                                        <td>{indexOfFirstItem + index + 1}</td>
                                        <td>{category.categoryname}</td>
                                        <td>
                                            <a onClick={() => handleUpdateCategory(category)}>
                                                <i className="mx-3 fa-solid fa-pen fa-beat-fade fa-xl" style={{ color: 'blue' }}></i>
                                            </a>
                                            <a onClick={() => handleDeleteCategory(category)}>
                                                <i className="mx-3 fa-solid fa-trash fa-beat-fade fa-xl" style={{ color: 'red' }}></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='pagination-container'>
                            {Array.from({ length: Math.ceil(rowData.length / itemsPerPage) }, (_, index) => (
                                <button
                                    className='pagination-button mx-2'
                                    key={index}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {deleteConfirmation && (
                    <DeleteConfirmationModal
                        show={!!deleteConfirmation}
                        handleClose={cancelDeleteCategory}
                        handleDelete={confirmDeleteCategory}
                        categoryName={deleteConfirmation.categoryname}
                    />
                )}

                {editModalShow && editCategory && (
                    <EditCategoryModal
                        show={editModalShow}
                        handleClose={() => setEditModalShow(false)}
                        handleSave={confirmEditCategory}
                        categoryName={editedCategoryName}
                        setCategoryName={setEditedCategoryName}
                    />
                )}

                <CustomModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    title={modalContent.title}
                    message={modalContent.message}
                />
            </div>
            <div id="printcategory" style={{ display: 'none', fontSize: '0.8rem' }}>
                <Table>
                    <thead>
                        <tr style={{ fontSize: '0.8rem' }}>
                            <th>Sr.No</th>
                            <th>CATEGORY ID</th>
                            <th>CATEGORY NAME</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems.map((category, index) => (
                            <tr key={index} style={{ fontSize: '0.5rem' }}>
                                <td>{index + 1}</td>
                                <td>{category.categoryid}</td>
                                <td>{category.categoryname}</td>
                            </tr>
                        ))}
                        {/* Footer Row */}
                        <tr>
                            <td colSpan="8" style={{ fontSize: '0.5rem' }}>
                                Prepared by admin on {currentDate} {currentTime},{weekStartingDay}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Category;
