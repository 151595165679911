
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Userdetails.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import companylogo from '../../assets/images/logo2.png';

function Userdetails() {
    const [users, setUsers] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [updatedUsername, setUpdatedUsername] = useState('');
    const [updatedDepartment, setUpdatedDepartment] = useState('');
    const [updatedEmail, setUpdatedEmail] = useState('');
    const [updatedMobilenumber, setUpdatedMobilenumber] = useState('')
    const [updatedForms, setUpdatedForms] = useState([]);
    const [updatedReports, setUpdatedReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const instance = axios.create({
        baseURL: 'https://test-server-o94j.onrender.com/user/',
        headers: { 'X-Custom-Header': 'foobar' }
    });

    const fetchUsers = () => {
        instance.get('users')
            .then(response => setUsers(response.data))
            .catch(error => console.error('Error fetching users details:', error));
    };

    const handleDeleteIconClick = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    }

    const handleUpdateIconClick = (item) => {
        setSelectedItem(item);
        setUpdatedUsername(item.username);
        setUpdatedDepartment(item.department);
        setUpdatedForms(item.forms || []);
        setUpdatedReports(item.reports || []);
        setUpdatedEmail(item.email);
        setUpdatedMobilenumber(item.mobilenumber);
        setShowUpdateModal(true);
    }

    const handleDeleteConfirmation = () => {
        instance.post('delete-user', {
            userid: selectedItem.userid,
        })
            .then(() => {
                fetchUsers();
                setSelectedItem(null);
                setShowDeleteModal(false);
            })
            .catch(error => console.error('Error deleting user:', error));
    }

    const handleUpdateSubmit = () => {
        instance.post('http://localhost:8000/user/update-user', {
            userid: selectedItem.userid,
            newUserName: updatedUsername,
            newDepartment: updatedDepartment,
            newForms: updatedForms,
            newReports: updatedReports,
            newEmail: updatedEmail,
            newMobilenumber: updatedMobilenumber
        })
            .then(() => {
                fetchUsers();
                setShowUpdateModal(false);
            })
            .catch(error => console.error('Error updating user:', error));
    }

    const imageToBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            };
            img.onerror = (err) => {
                reject(err);
            };
        });
    };

    const downloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });

        const logoBase64 = await imageToBase64(companylogo);

        doc.addImage(logoBase64, 'PNG', 5, 2, 18, 12);

        doc.setFontSize(12);
        const companyName = 'PRIME FRESH LIMITED (Vashi/DC)';
        const companyNameX = 24;
        const companyNameY = 6;
        doc.text(companyName, companyNameX, companyNameY);

        const companyNameWidth = doc.getTextWidth(companyName);
        const lineX1 = companyNameX;
        const lineX2 = companyNameX + companyNameWidth;
        const lineY = companyNameY + 1;
        doc.line(lineX1, lineY, lineX2, lineY);

        const leadReportText = 'User Reports';
        const leadReportTextX = companyNameX;
        const leadReportTextY = lineY + 5;
        doc.setFontSize(12);
        doc.text(leadReportText, leadReportTextX, leadReportTextY);

        doc.setFontSize(12);
        const currentDate = new Date().toLocaleDateString('en-IN');
        doc.text('Date: ' + currentDate, doc.internal.pageSize.width - 15, 7, null, null, 'right');

        const tableColumns = [
            "UserID", "UserName", "Email", "Department", "Forms", "Reports"
        ];
        const tableRows = [];
        users.forEach((user) => {
            const userData = [
                user.userid,
                user.username,
                user.email,
                user.department,
                user.forms ? user.forms.join(", ") : "",
                user.reports ? user.reports.join(", ") : ""
            ];
            tableRows.push(userData);
        });

        // Fetch the IP address before creating the PDF
        let ipAddress = '';
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            ipAddress = response.data.ip;
        } catch (error) {
            console.error('Error fetching IP address:', error);
        }

        let recordStart = 1;
        doc.autoTable({
            head: [tableColumns],
            body: tableRows,
            startY: leadReportTextY + 3,
            margin: { left: 5 },
            didParseCell: function (data) {
                const cell = data.cell;
                if (tableColumns.includes(data.cell.raw)) {
                    cell.styles.fillColor = [204, 204, 204];
                    cell.styles.textColor = [0, 0, 0];
                    cell.styles.fontSize = 9;
                    cell.styles.fontStyle = 'bold';
                    cell.styles.halign = 'center';
                } else {
                    cell.styles.fillColor = [255, 255, 255];
                    cell.styles.textColor = [0, 0, 0];
                    cell.styles.fontSize = 7;
                }
                cell.styles.lineWidth = 0.2;
                cell.styles.lineColor = [0, 0, 0];
            },
            didDrawPage: function (data) {
                const pageSize = doc.internal.pageSize;
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                const pageRecordsCount = data.table.body.length;
                const recordEnd = recordStart + pageRecordsCount - 1;

                // Display the record range at the bottom of each page
                doc.setFontSize(10);
                doc.text(`No. of Records ${recordStart} to ${recordEnd}`, pageSize.width / 2, pageHeight - 15, { align: 'center' });

                recordStart = recordEnd + 1;

                // Add footer
                doc.setLineWidth(0.5);
                doc.line(10, pageHeight - 20, pageSize.width - 10, pageHeight - 20);
                const preparedBy = 'admin';
                const currentDateFooter = new Date().toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });

                const footerText = `Prepared by ${preparedBy} on ${currentDate} ${currentDateFooter} | IP Address: ${ipAddress}`;
                doc.setFontSize(10);
                doc.text('Prime Fresh Limited (Vashi/DC)', 10, pageHeight - 10);
                doc.setFontSize(10);
                doc.text(footerText, 10, pageHeight - 5);
            }
        });

        doc.save('user_reports.pdf');
    };



    const downloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Users');

        const logoBase64 = await imageToBase64(companylogo);
        const logoImage = workbook.addImage({
            base64: logoBase64,
            extension: 'png',
        });

        worksheet.addImage(logoImage, {
            tl: { col: 0, row: 0 },
            ext: { width: 150, height: 100 }
        });

        worksheet.mergeCells('A1:H1');
        worksheet.getCell('A1').value = 'Reports';
        worksheet.getCell('A1').font = { size: 16, bold: true };
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.mergeCells('A2:H2');
        worksheet.getCell('A2').value = 'PRIME FRESH LIMITED (Vashi/DC)';
        worksheet.getCell('A2').font = { size: 14, bold: true };
        worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.mergeCells('A3:H3');
        worksheet.getCell('A3').value = 'User Details Reports';
        worksheet.getCell('A3').font = { size: 14, bold: true };
        worksheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.addRow([]);
        worksheet.addRow([]);

        const excelColumns = [
            { header: 'Sr.No', key: 'srno', width: 10 },
            { header: 'User ID', key: 'userid', width: 20 },
            { header: 'Username', key: 'username', width: 20 },
            { header: 'Email ID', key: 'email', width: 30 },
            { header: 'Department', key: 'department', width: 20 },
            { header: 'Mobile Number', key: 'mobilenumber', width: 20 },
            { header: 'Forms', key: 'forms', width: 30 },
            { header: 'Reports', key: 'reports', width: 30 },
        ];

        worksheet.columns = excelColumns;

        // Adding header row with style
        const headerRow = worksheet.addRow(excelColumns.map(col => col.header));
        headerRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' }
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        users.forEach((user, index) => {
            const row = worksheet.addRow({
                srno: index + 1,
                userid: user.userid,
                username: user.username,
                email: user.email,
                department: user.department,
                mobilenumber: user.mobilenumber,
                forms: user.forms ? user.forms.join(", ") : "",
                reports: user.reports ? user.reports.join(", ") : "",
            });
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        const currentDate = new Date().toLocaleDateString();
        const footerText = `Prepared by admin on ${currentDate}`;
        const footerRow = worksheet.addRow([footerText]);
        footerRow.getCell(1).font = { italic: true };
        worksheet.mergeCells(`A${footerRow.number}:H${footerRow.number}`);

        worksheet.columns.forEach((column, index) => {
            if (index > 0) {
                column.width = column.header.length < 20 ? 20 : column.header.length + 5;
            }
        });

        worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'user_details.xlsx';
        link.click();
    };

    const printUserDetails = () => {
        const printContent = document.getElementById('printableArea').innerHTML;
        const originalContent = document.body.innerHTML;

        const currentDate = new Date().toLocaleDateString('en-IN');

        const printableContentWithLogo = `
        <div style="font-family: Arial, sans-serif; margin-left: 20px;">
            <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                <img src="${companylogo}" alt="Company Logo" style="width: 100px; height: auto; margin-right: 20px;">
                    <div>
                        <h1 style="font-size: 18px; margin: 0;">Prime Fresh Limited (Vashi/DC)</h1>
                        <hr style="margin: 5px 0;">
                        <h2 style="font-size: 16px; margin: 0;">User Details Reports</h2>
                    </div>
                <div>
                    <h1 style="margin-left: 270px;">${currentDate}</h1>
                </div>
            </div>
            ${printContent}
        </div>
    `;


        const printableContentWithFooter = printableContentWithLogo;

        document.body.innerHTML = printableContentWithFooter;

        window.print();

        document.body.innerHTML = originalContent;
        window.location.reload();
    };

    function getWeekStartingDay() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = new Date().getDay();
        return days[currentDayIndex];
    }

    const currentTime = new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const currentDate = new Date().toLocaleDateString('en-IN');
    const weekStartingDay = getWeekStartingDay();

    useEffect(() => {
        fetchUsers();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);


    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div className='leadserveyreport'>
            <h1 className='head'>USER DETAILS</h1>
            <div className="leadserveyreport-table">
                <div className='leadservey-pdfexcel-button'>
                    <Button onClick={downloadPDF}>
                        <i className="fa-solid fa-file-pdf fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={downloadExcel}>
                        <i className="fa-solid fa-file-excel fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={printUserDetails}>
                        <i className="fa-solid fa-print fa-fade fa-xl"></i>
                    </Button>
                </div>

                <Table className='mt-2' striped bordered hover>
                    <thead>
                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <th>Sr.No</th>
                            <th>User ID</th>
                            <th>Username</th>
                            <th>Email ID</th>
                            <th>Department</th>
                            <th>MobileNumber</th>
                            <th>Forms</th>
                            <th>Reports</th>
                            <th>Preview</th>
                            <th>Delete</th>
                            <th>Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((user, index) => (
                            <tr key={user.userid} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <td>{index + 1}</td>
                                <td>{user.userid}</td>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.department}</td>
                                <td>{user.mobilenumber}</td>
                                <td>
                                    {user.forms && (
                                        <ul>
                                            {user.forms.map((form, index) => (
                                                <div key={index}>{form}</div>
                                            ))}
                                        </ul>
                                    )}
                                </td>
                                <td>
                                    {user.reports && (
                                        <ul>
                                            {user.reports.map((report, index) => (
                                                <div key={index}>{report}</div>
                                            ))}
                                        </ul>
                                    )}
                                </td>
                                <td>
                                    <Link to={`/user/${user.userid}`}>
                                        <button className='btn btn-warning view'>
                                            <i className="fa-solid fa-eye fa-beat fa-xl"></i>
                                        </button>
                                    </Link>
                                </td>
                                <td>
                                    <a onClick={() => handleDeleteIconClick(user)}>
                                        {user.department !== 'Admin Department' && (
                                            <button className='btn btn-light delete'>
                                                <i className="fa-solid fa-trash fa-beat fa-xl" style={{ color: "red" }}></i>
                                            </button>
                                        )}
                                    </a>
                                </td>

                                <td>
                                    <a onClick={() => handleUpdateIconClick(user)}>
                                        <button className='btn btn-light'>
                                            <i className="fa-solid fa-pen fa-beat fa-xl" style={{ color: "blue" }}></i>
                                        </button>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="pagination-container">
                    {Array.from({ length: Math.ceil(users.length / itemsPerPage) }, (_, index) => (
                        <button
                            className="pagination-button mx-2"
                            key={index}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                <div className="total-records" style={{ textAlign: 'center', marginTop: '1rem', color: 'black', fontWeight: 'bold' }}>
                    Total No. of Records: {users.length}
                </div>
            </div>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirmation}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formUsername">
                            <Form.Label>New Username</Form.Label>
                            <Form.Control type="text" value={updatedUsername} onChange={(e) => setUpdatedUsername(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formRoles">
                            <Form.Label>New Department</Form.Label>
                            <Form.Control as="select" value={updatedDepartment} onChange={(e) => setUpdatedDepartment(e.target.value)}>
                                <option value="User Department">User Department</option>
                                <option value="Admin Department">Admin Department</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>New Email</Form.Label>
                            <Form.Control type="text" value={updatedEmail} onChange={(e) => setUpdatedEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formMobilenumber">
                            <Form.Label>New Email</Form.Label>
                            <Form.Control type="text" value={updatedMobilenumber} onChange={(e) => setUpdatedMobilenumber(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="formForms">
                            <Form.Label>New Forms</Form.Label>
                            <Form.Control type="text" value={updatedForms.join(',')} onChange={(e) => setUpdatedForms(e.target.value.split(','))} />
                        </Form.Group>
                        <Form.Group controlId="formReports">
                            <Form.Label>New Reports</Form.Label>
                            <Form.Control type="text" value={updatedReports.join(',')} onChange={(e) => setUpdatedReports(e.target.value.split(','))} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpdateSubmit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="printableArea" style={{ display: 'none', fontSize: '0.8rem' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr style={{ fontSize: '0.8rem' }}>
                            <th>Sr.No</th>
                            <th>UserID</th>
                            <th>Username</th>
                            <th>EmailID</th>
                            <th>Department</th>
                            <th>MobileNumber</th>
                            <th>Forms</th>
                            <th>Reports</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={user.userid} style={{ fontSize: '0.5rem' }}>
                                <td>{index + 1}</td>
                                <td>{user.userid}</td>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.department}</td>
                                <td>{user.mobilenumber}</td>
                                <td>{user.forms ? user.forms.join(', ') : ''}</td>
                                <td>{user.reports ? user.reports.join(', ') : ''}</td>
                            </tr>
                        ))}
                        {/* Footer Row */}
                        <tr>
                            <td colSpan="8" style={{ fontSize: '0.5rem' }}>
                                Prepared by admin on {currentDate} {currentTime},{weekStartingDay}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Userdetails;