import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/images/logo2.png';
import axios from 'axios';
import './Header.css';

function Header() {
    const [loggedUsername, setLoggedUsername] = useState('');
    const [lastLogin, setLastLogin] = useState('');
    const [userImage, setUserImage] = useState('');
    const [department, setDepartment] = useState('');
    const [branches, setBranches] = useState([]);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedLastLogin = localStorage.getItem('lastLogin');
        const storedDepartment = localStorage.getItem('department');

        setLoggedUsername(storedUsername);
        setDepartment(storedDepartment);

        const lastLoginDate = new Date(storedLastLogin);
        const formattedLastLogin = `${lastLoginDate.toLocaleDateString()} | ${lastLoginDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
        setLastLogin(formattedLastLogin);

        const storedUserId = localStorage.getItem('userid');
        axios.get(`https://test-server-o94j.onrender.com/user/users/${storedUserId}`)
            .then(response => {
                setUserImage(response.data.image);
                setBranches(response.data.branches || []);
            })
            .catch(error => {
                console.error('Error fetching user details:', error);
            });
    }, []);

    return (
        <div>
            <Navbar className="bg-body-tertiary fixed-top">
                <Container>
                    <Navbar.Brand href="/frontpage">
                        <img
                            alt=""
                            src={logo}
                            width="100"
                            height="70"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Navbar.Text className='last-login'>
                        {userImage && (
                            <img
                                src={`https://test-server-o94j.onrender.com/${userImage}`}
                                className="user-image-navbar"
                                style={{
                                    width: '45px', // Adjust the width as needed
                                    height: '45px', // Adjust the height as needed
                                    borderRadius: '10%', // Ensures the image is displayed in a circle
                                    objectFit: 'cover', // Ensures the image covers the circle without stretching
                                    marginRight: '10px' // Adds some spacing between the image and the text
                                }}
                            />
                        )}
                        <div style={{ marginTop: '-5px'}}>
                            <div style={{ marginTop: '-45px' }} className='mx-5'>
                                {loggedUsername}
                            </div>
                            <div style={{ marginTop: '-5px' }} className='mx-5'>{department}</div>
                            <div style={{ marginTop: '-5px' }} className='mx-5'>
                                 {branches.join(', ')}
                            </div>
                        </div>
                        <div className='mt-2'>Last Login: {lastLogin}</div>
                    </Navbar.Text>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;







