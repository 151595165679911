import React, { useState, useEffect } from 'react';
import './Assigning.css';
import axios from 'axios';
import Select from 'react-select';
import { Button, Table, Form, Modal } from 'react-bootstrap';


function Assigning() {
    const [leadserveyData, setLeadserveyData] = useState([]);
    const [users, setUsers] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [stateFilter, setStateFilter] = useState([]);
    const [cityFilter, setCityFilter] = useState([]);
    const [areaFilter, setAreaFilter] = useState([]);
    const [usernameFilter, setUsernameFilter] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedLeadSurveyData, setSelectedLeadSurveyData] = useState(null);

    const [selectedLeadSurveyIds, setSelectedLeadSurveyIds] = useState([]);

    const handleCheckboxChange = (e, leadSurveyId) => {
        if (e.target.checked) {
            setSelectedLeadSurveyIds(prevIds => [...prevIds, leadSurveyId]);
        } else {
            setSelectedLeadSurveyIds(prevIds =>
                prevIds.filter(id => id !== leadSurveyId)
            );
        }
    };

    const handleAssignButtonClick = () => {
        if (selectedLeadSurveyIds.length === 0 || !assignedUsers) {
            alert('Please select a user and at least one lead survey to assign.');
            return;
        }

        axios
            .post('http://localhost:8000/leadprocessing/assign', {
                username: assignedUsers,
                leadSurveyIds: selectedLeadSurveyIds,
                mobileNumber: assignedUsers.mobilenumber
            })
            .then(response => {
                alert('Lead surveys assigned successfully:', response.data);
                // Remove selected lead surveys from leadserveyData
                setLeadserveyData(leadserveyData =>
                    leadserveyData.filter(item => !selectedLeadSurveyIds.includes(item._id.$oid))
                );

                // Clear selected lead survey IDs or perform any other necessary actions
                setSelectedLeadSurveyIds([]);

                // Update assigned field to true for selected lead surveys in the frontend
                setLeadserveyData(leadserveyData =>
                    leadserveyData.map(item => {
                        if (selectedLeadSurveyIds.includes(item._id.$oid)) {
                            return { ...item, assigned: true };
                        }
                        return item;
                    })
                );
            })
            .catch(error => {
                console.error('Error assigning lead surveys:', error);
                // Handle error...
            });
    };

    const fetchleadservey = () => {
        axios.get('https://test-server-o94j.onrender.com/leadservey/leadserveys')
            .then(response => response.data)
            .then(data => {
                // Filter data where assigned is false
                const filteredData = data.filter(item => !item.assigned);
                setLeadserveyData(filteredData);
            })
            .catch(error => console.error('Error fetching leadservey:', error))
    }


    const fetchusers = () => {
        axios.get('https://test-server-o94j.onrender.com/user/users')
            .then(response => response.data)
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    }

    const fetchState = () => {
        axios.get('https://test-server-o94j.onrender.com/state/states')
            .then(response => response.data)
            .then(data => setState(data))
            .catch(error => console.error('Error fetching state:', error))
    }

    const fetchcity = () => {
        axios.get('https://test-server-o94j.onrender.com/city/cities')
            .then(response => response.data)
            .then(data => setCity(data))
            .catch(error => console.error('Errro fetching cities', error))
    }

    const fetcharea = () => {
        axios.get('https://test-server-o94j.onrender.com/area/areas')
            .then(response => response.data)
            .then(data => setArea(data))
            .catch(error => console.error('Error fetching areas', error))
    }

    useEffect(() => {
        fetchleadservey();
        fetchusers();
        fetchState();
        fetchcity();
        fetcharea();
    }, [])

    const handleStateFilterChange = (selectedOptions) => {
        setStateFilter(selectedOptions);
    };

    const handleCityFilterChange = (selectedOptions) => {
        setCityFilter(selectedOptions);
    };

    const handleAreaFilterChange = (selectedOptions) => {
        setAreaFilter(selectedOptions);
    };

    const handleUsernameFilterChange = (selectedOptions) => {
        setUsernameFilter(selectedOptions);
    }

    const handleUsernameChange = (selectedOptions) => {
        setAssignedUsers(selectedOptions);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const filteredItems = leadserveyData.filter(item => {
        if (selectedDate) {
            const itemDate = new Date(item.createdAt).toISOString().slice(0, 10); // Extracting the date portion
            return itemDate === selectedDate;
        }
        return true;
    }).filter(item =>
        (stateFilter.length === 0 || stateFilter.some(state => state.value === item.State)) &&
        (cityFilter.length === 0 || cityFilter.some(city => city.value === item.City)) &&
        (areaFilter.length === 0 || areaFilter.some(area => area.value === item.Area)) &&
        (usernameFilter.length === 0 || usernameFilter.some(username => username.value === item.username))
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleShowPreview = (leadSurveyData) => {
        setSelectedLeadSurveyData(leadSurveyData);
        setShowPreview(true);
    };

    const handleClosePreview = () => setShowPreview(false);

    return (
        <div className='assigning'>
            <div className='assigning-heading'>
                <h1 className='head'>LEAD ASSIGNING</h1>
            </div>
            <div className='assigning-table' style={{ fontSize: '0.8rem' }}>
                <div className='assigning-forms' style={{ fontSize: '0.8rem' }}>
                    <div className='assigning state flex items-center justify-end rounded-5 mx-2 assignstate'>
                        <label>Filter by States</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Select States'
                            options={state.map(state => ({
                                value: state.statename,
                                label: state.statename
                            }))}
                            value={stateFilter}
                            onChange={handleStateFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter by Cities</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Select Cities'
                            options={city.map(city => ({
                                value: city.cityname,
                                label: city.cityname
                            }))}
                            value={cityFilter}
                            onChange={handleCityFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter by Areas</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Select Areas'
                            options={area.map(area => ({
                                value: area.areaname,
                                label: area.areaname
                            }))}
                            value={areaFilter}
                            onChange={handleAreaFilterChange}
                        />
                    </div>


                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter by Username</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Select Username'
                            options={users.map(user => ({
                                value: user.username,
                                label: user.username
                            }))}
                            value={usernameFilter}
                            onChange={handleUsernameFilterChange}
                        />
                    </div>


                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Assigned To</label>
                        <select
                            value={assignedUsers}
                            onChange={(e) => setAssignedUsers(e.target.value)}
                            className="mt-2 form-select"
                            style={{ fontSize: '0.8rem' }}
                        >
                            <option value='' disabled>Select a username</option>
                            {users.map(user => (
                                <option key={user.userid} value={user.username}>
                                    {user.username}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Date:</label>
                        <Form.Group>
                            <input
                                className='mt-3'
                                type="date"
                                value={selectedDate}
                                onChange={(e) => handleDateChange(e.target.value)} />
                        </Form.Group>
                    </div>
                </div>
                <Table className="custom-table" style={{ fontSize: '0.8rem' }} striped bordered hover responsive>
                    <thead>
                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <th>Sr.No</th>
                            <th>LSDate</th>
                            <th>LSID</th>
                            <th>BizName</th>
                            <th>CustName</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Area</th>
                            <th>Mobile</th>
                            <th>Assign</th>
                            <th>Preview</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{new Date(item.createdAt).toLocaleDateString('en-IN')}</td>

                                <td>{item.leadserveyid}</td>
                                <td>{item.CustName}</td>
                                <td>{item.FName}</td>
                                <td>{item.State}</td>
                                <td>{item.City}</td>
                                <td>{item.Area}</td>
                                <td>{item.MobNum}</td>
                                <td>
                                    <input
                                        className='checkbox'
                                        type="checkbox"
                                        onChange={(e) => handleCheckboxChange(e, item)}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => handleShowPreview(item)} className='btn btn-warning view'>
                                        <i className="fa-solid fa-eye fa-beat fa-lg"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
                <div className="pagination-container">
                    {Array.from({ length: Math.ceil(filteredItems.length / itemsPerPage) }, (_, index) => (
                        <button
                            className="pagination-button mx-2"
                            key={index}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
            <div className='assigning-button'>
                <Button onClick={handleAssignButtonClick}>Assign Lead Surveys</Button>
            </div>

            <Modal show={showPreview} onHide={handleClosePreview} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Lead Survey Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedLeadSurveyData && <LeadserveyPreview leadserveyData={selectedLeadSurveyData} />}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={handleClosePreview}>
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

function LeadserveyPreview({ leadserveyData }) {
    return (
        <div style={{ fontSize: '0.8rem', display: 'flex', flexDirection: 'column' }}>
            <div className='form-details' style={{ lineHeight: '1' }}>
                <p className='company-details'>
                    <strong>Company Name : M/s.</strong> {leadserveyData.CustName} ({leadserveyData.Since})
                </p>
                <p className='bussiness-type'>
                    <strong>Bussiness Type : </strong> {leadserveyData.BizType}
                </p>
                <p className='bussiness-category'>
                    <strong>Bussiness Category : </strong> {leadserveyData.BizCatge}
                </p>
                <p className='bussiness-experience'>
                    <strong>Bussiness Experience : </strong> {leadserveyData.BizExp}
                </p>
                <p className='fl'>
                    <strong>Customer Name : Mr/Ms</strong> {leadserveyData.FName} {leadserveyData.LName}
                </p>
                <p className='addr1'>
                    <strong>Address :</strong>
                    <span className="address-data">
                        {leadserveyData.Addr1}, {leadserveyData.Addr2}
                    </span>
                </p>
                <p className='acpsc'>
                    {leadserveyData.Area}, {leadserveyData.City} - {leadserveyData.PinCode},  {leadserveyData.State} ({leadserveyData.Country})
                </p>
                <p className='MobNum'>
                    <strong>Mob :</strong> {leadserveyData.MobNum},  {leadserveyData.AltMobNum}
                </p>
                <p className='EmailId'>
                    <strong>Email</strong> {leadserveyData.EmailId}
                </p>
            </div>
            <hr />
            <div className='form-details2' style={{ lineHeight: '1' }}>
                <p className='current-address-since'>
                    <strong>Current Address Since Years : </strong> {leadserveyData.CurrAddSince}
                </p>
                <p className='bussiness-hour'>
                    <strong>Bussiness Hour : </strong> {leadserveyData.BizHourFrom} AM to {leadserveyData.BizHourTo} PM
                </p>
                <p className='approx-quantity-buy-in-kg'>
                    <strong>Approx Quantity Buy In Kg : </strong> {leadserveyData.ApproxQtyBuyinKg}
                </p>
                <p className='daily-purchase-value'>
                    <strong>Daily Purchase Value : </strong> {leadserveyData.DaPurVal}
                </p>
                <p className='key-skus'>
                    <strong>Key SKU's : </strong> {leadserveyData.KeySkus ? leadserveyData.KeySkus.join(',') : ''}
                </p>

            </div>
        </div>
    );
}

export default Assigning;
