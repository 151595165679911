import React, { useState, useEffect } from 'react';
import './Leadprocesing.css';
import axios from 'axios';
import Select from 'react-select';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { Table, Modal, Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

function Leadprocessing() {
    const [leadprocessingData, setLeadprocessingData] = useState([]);
    const [users, setUsers] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [stateFilter, setStateFilter] = useState(null);
    const [cityFilter, setCityFilter] = useState(null);
    const [areaFilter, setAreaFilter] = useState(null);
    const [usernameFilter, setUsernameFilter] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [selectedLeadId, setSelectedLeadId] = useState(null);
    const [isInterested, setIsInterested] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleRadioChange = (leadId, interested) => {
        setSelectedLeadId(leadId);
        setIsInterested(interested);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleInterestUpdate = () => {
        if (selectedLeadId !== null && isInterested !== null) {
            const currentDate = new Date().toISOString();
            axios
                .put(`https://test-server-o94j.onrender.com/leadprocessing/leadprocessing/${selectedLeadId}`, {
                    interested: isInterested,
                    date: currentDate
                })
                .then(response => {
                    console.log(`Lead ${selectedLeadId} marked as ${isInterested ? 'interested' : 'not interested'}`);
                    setLeadprocessingData(prevData =>
                        prevData.map(item => {
                            if (item._id === selectedLeadId) {
                                return { ...item, isRemoved: true };
                            }
                            return item;
                        })
                    );
                    setShowModal(false);
                })
                .catch(error => {
                    console.error('Error updating interest status:', error);
                });
        }
    };

    const fetchleadprocessing = () => {
        const username = localStorage.getItem('username');
        axios.get('https://test-server-o94j.onrender.com/leadprocessing/leadprocessing')
            .then(response => response.data.filter(item => item.username === username && (!item.interested && !item.notInterested)))
            .then(data => setLeadprocessingData(data))
            .catch(error => console.error('Error fetching leadprocessing', error));
    };

    const fetchusers = () => {
        axios.get('https://test-server-o94j.onrender.com/user/users')
            .then(response => response.data)
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    };

    const fetchState = () => {
        axios.get('https://test-server-o94j.onrender.com/state/states')
            .then(response => response.data)
            .then(data => setState(data))
            .catch(error => console.error('Error fetching state:', error))
    }

    const fetchcity = () => {
        axios.get('https://test-server-o94j.onrender.com/city/cities')
            .then(response => response.data)
            .then(data => setCity(data))
            .catch(error => console.error('Errro fetching cities', error))
    }

    const fetcharea = () => {
        axios.get('https://test-server-o94j.onrender.com/area/areas')
            .then(response => response.data)
            .then(data => setArea(data))
            .catch(error => console.error('Error fetching areas', error))
    }

    useEffect(() => {
        fetchleadprocessing();
        fetchusers();
        fetchState();
        fetchcity();
        fetcharea()
    }, [])

    const handleStateFilterChange = selectedOptions => {
        setStateFilter(selectedOptions);
    };

    const handleCityFilterChange = selectedOptions => {
        setCityFilter(selectedOptions);
    };

    const handleAreaFilterChange = selectedOptions => {
        setAreaFilter(selectedOptions);
    };

    const handleUsernameFilterChange = selectedOptions => {
        setUsernameFilter(selectedOptions);
    };

    const handleStartDateChange = date => {
        setStartDate(date);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
    };

    const filteredItems = leadprocessingData.filter(item => {
        if (startDate && endDate) {
            const itemDateTime = new Date(item.createdAt).getTime();
            const startDateTime = new Date(startDate).getTime();
            const endDateTime = new Date(endDate).getTime();
            return itemDateTime >= startDateTime && itemDateTime <= endDateTime;
        }
        return true;
    }).filter(item =>
        (!stateFilter || stateFilter.value === item.leadserveyDetails.State) &&
        (!cityFilter || cityFilter.value === item.leadserveyDetails.City) &&
        (!areaFilter || areaFilter.value === item.leadserveyDetails.Area) &&
        (!usernameFilter || usernameFilter.value === item.leadserveyDetails.username)
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div className='userreportleadserveyfrom'>
            <Header />
            <h5 style={{ fontSize: '1rem' }}>LEAD-PROCESSING REPORTS</h5>
            <div className='userreportleadserveyfrom-table'>
                <div className='userreportleadserveyfrom-forms' style={{ fontSize: '0.6rem' }}>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} className='mt-2 leadprocessingfilterdate' md="2" controlId="startDate">
                                <Form.Label style={{ fontSize: '0.8rem' }}>From Date</Form.Label>
                                <Form.Control
                                    className='mt-2'
                                    style={{ fontSize: '0.6rem' }}
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => handleStartDateChange(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='mt-2 leadprocessingfilterdate' md="2" controlId="endDate">
                                <Form.Label style={{ fontSize: '0.8rem' }}>To Date</Form.Label>
                                <Form.Control
                                    className='mt-2'
                                    style={{ fontSize: '0.6rem' }}
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => handleEndDateChange(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='mt-2 cityfilter' md="4" controlId="city">
                                <Form.Label>Cities</Form.Label>
                                <Select
                                    style={{ fontSize: '0.8rem' }}
                                    className='mt-2  custom-select'
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            height: '30px',
                                            minHeight: '30px',
                                        }),
                                    }}
                                    placeholder="City"
                                    options={city.map(city => ({
                                        value: city.cityname,
                                        label: city.cityname
                                    }))}
                                    value={cityFilter}
                                    onChange={handleCityFilterChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='mt-2 areafilter' md="4" controlId="area">
                                <Form.Label>Areas</Form.Label>
                                <Select
                                    style={{ fontSize: '0.8rem' }}
                                    className='mt-2  custom-select'
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            height: '30px',
                                            minHeight: '30px',
                                        }),
                                    }}
                                    placeholder="Area"
                                    options={area.map(area => ({
                                        value: area.areaname,
                                        label: area.areaname
                                    }))}
                                    value={areaFilter}
                                    onChange={handleAreaFilterChange}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
                <Table style={{ fontSize: '0.6rem' }} striped bordered hover responsive>
                    <thead>
                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <th>Sr.No</th>
                            <th>AssignDate</th>
                            <th>LSID</th>
                            <th>BizName</th>
                            <th>CustName</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Area</th>
                            <th>Call</th>
                            <th>What'sup</th>
                            <th>Interested</th>
                            <th>NotInterested</th>
                            <th>Preview</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{new Date(item.createdAt).toLocaleDateString('en-IN')}</td>
                                <td>{item.leadserveyDetails.leadserveyid}</td>
                                <td>{item.leadserveyDetails.CustName}</td>
                                <td>{item.leadserveyDetails.FName}</td>
                                <td>{item.leadserveyDetails.State}</td>
                                <td>{item.leadserveyDetails.City}</td>
                                <td>{item.leadserveyDetails.Area}</td>
                                <td>
                                    <button className='phone-call'>
                                        <a href={`tel:${item.leadserveyDetails.MobNum}`}>
                                            <i className="fa-solid fa-phone fa-beat-fade fa-2xl" style={{ color: 'blue', verticalAlign: 'middle' }}></i>
                                        </a>
                                    </button>
                                </td>

                                <td>
                                    <button className='whatsup-message'>
                                        <a href={`https://api.whatsapp.com/send?phone=${item.leadserveyDetails.MobNum}&text=Dear%20Sir/Madam,%20Its%20regarding%20Product%20Inquiry%20For%20Amoxil%20Tablets.%20-%20Please%20Revert%20Back%20to%20Us%20on%20Same%20Whatsapp%20Number.%20-%20Whatsapp%20Visitor%20[Combiotic%20Global%20Caplet%20Pvt.Ltd.]%20-%20https://www.combioticglobal.com`} target="_blank" rel="noopener noreferrer">
                                            <i className="fa-brands fa-whatsapp fa-beat-fade fa-2xl" style={{ color: 'green', verticalAlign: 'middle' }}></i>
                                        </a>
                                    </button>
                                </td>

                                <td>
                                    <input
                                        type="radio"
                                        name={`interest-${item._id}`}
                                        id={`interested-${item._id}`}
                                        value="interested"
                                        onChange={() => handleRadioChange(item._id, true)}
                                    />
                                    <label htmlFor={`interested-${item._id}`}></label>
                                </td>
                                <td>
                                    <input
                                        type="radio"
                                        name={`interest-${item._id}`}
                                        id={`not-interested-${item._id}`}
                                        value="not-interested"
                                        onChange={() => handleRadioChange(item._id, false)}
                                    />
                                    <label htmlFor={`not-interested-${item._id}`}></label>
                                </td>
                                <td>
                                    <Link to={`/particular-leadservey-reports/${item.leadserveyDetails.leadserveyid}`}>
                                        <button className='btn btn-warning view'>
                                            <i className="fa-solid fa-eye fa-beat fa-lg"></i>
                                        </button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="pagination-container">
                    {Array.from({ length: Math.ceil(filteredItems.length / itemsPerPage) }, (_, index) => (
                        <button
                            className="pagination-button mx-2"
                            key={index}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Interest</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to mark this lead as {isInterested ? 'interested' : 'not interested'}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleInterestUpdate}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='footers'>
                <Footer />
            </div>
        </div>
    )
}

export default Leadprocessing;
