import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, Modal } from 'react-bootstrap'; // Import Modal
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo2.png';
import './Login.css';

function Login() {
    const [mobilenumber, setMobilenumber] = useState('');
    const [password, setPassword] = useState('');
    const [loginMessage, setLoginMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://test-server-o94j.onrender.com/user/login', {
                mobilenumber,
                password
            });

            if (response && response.data) {
                let message = '';
                let isAdmin = false;

                if (response.data.department === 'Admin Department') {
                    message = 'Login Successfully';
                    isAdmin = true;
                } else {
                    message = 'Login Successfully';
                }

                setLoginMessage(message);

                // Check if hasUnreadMessages is true
                if (response.data.hasUnreadMessages) {
                    alert('Admin Send Lead Processing Please Check It!');
                }

                const token = response.data.token;
                localStorage.setItem('token', token);

                localStorage.setItem('username', response.data.username);
                localStorage.setItem('userid', response.data.userid);
                localStorage.setItem('department',response.data.department)
                localStorage.setItem('lastLogin',response.data.lastLogin)

                if (response.data.branches) {
                    localStorage.setItem('branches', JSON.stringify(response.data.branches));
                }

                if (response.data.template && response.data.template.modules) {
                    localStorage.setItem('userModules', JSON.stringify(response.data.template.modules));
                }

                if (response.data.forms) {
                    localStorage.setItem('userForms', JSON.stringify(response.data.forms));
                }

                if (response.data.forms) {
                    localStorage.setItem('userReports', JSON.stringify(response.data.reports))
                }

                setTimeout(() => {
                    setLoginMessage('');
                    navigate(isAdmin ? "/dashboard" : "/frontpage");
                }, 1000);
            } else {
                console.error('Undefined response from login API');
                setLoginMessage('Error: Undefined response from server');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setLoginMessage(error.response?.data?.message || 'An error occurred during login');
        }
    };

    return (
        <div className='login'>
            <Container fluid className="d-flex align-items-center justify-content-center vh-100">
                <Card.Body>
                    <Row>
                        <Col md='10' lg='5' className='d-flex align-items-center'>
                            <Card.Img src={logo} fluid="true" />
                        </Col>

                        <Col md='10' lg='6' className='d-flex flex-column align-items-center custom-border'>
                            <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">LOG IN </p>
                            <div className="d-flex flex-row align-items-center mb-4  w-50">
                                <Form.Text className="me-3">
                                    <i className="fa-solid fa-user fa-lg"></i>
                                </Form.Text>
                                <Form.Control style={{ fontSize: '0.8rem' }} type='number' placeholder='Number' onChange={(e) => setMobilenumber(e.target.value)} />
                            </div>
                            <div className="d-flex flex-row align-items-center mb-4  w-50">
                                <Form.Text className="me-3">
                                    <i className="fas fa-lock fa-lg"></i>
                                </Form.Text>
                                <Form.Control style={{ fontSize: '0.8rem' }} type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <Button onClick={handleLogin} className='mb-4' size='lg' variant='danger'>SIGN IN</Button>
                            {loginMessage && (
                                <div className="alert alert-success" role="alert">
                                    {loginMessage}
                                </div>
                            )}
                        </Col>

                    </Row>
                </Card.Body>
            </Container>
        </div>
    );
}

export default Login;
