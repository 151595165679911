import React, { useState } from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import axios from 'axios';
import './Changeuserpassword.css';

function Changeuserpassword() {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [verificationMessage, setVerificationMessage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [changePasswordMessage, setChangePasswordMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://test-server-o94j.onrender.com/user/send', { email });
            setMessage(response.data.message);
            setTimeout(() => {
                setMessage('');
            }, 2000); // Clear message after 2 seconds
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setMessage('Email not found. Please check your email address and try again.');
            } else {
                setMessage('Error sending OTP email');
                console.error(error);
            }
        }
    };


    const handleVerification = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://test-server-o94j.onrender.com/user/verify-otp', { email, enteredOtp: otp });
            setVerificationMessage(response.data.message);

            if (response.data.message === 'OTP verification successful') {
                // If OTP verification successful, show input fields for new password
                setNewPassword('');
                setConfirmPassword('');
                setChangePasswordMessage('');
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setVerificationMessage('Incorrect OTP. Please try again.');
            } else {
                setVerificationMessage('Error verifying OTP');
                console.error(error);
            }
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setChangePasswordMessage('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post('https://test-server-o94j.onrender.com/user/change-password', { email, newPassword });
            setChangePasswordMessage(response.data.message);
            window.alert(response.data.message);
            setEmail('')
            setMessage('')
            setOtp('')
            setVerificationMessage('')
            setNewPassword('')
            setConfirmPassword('')
        } catch (error) {
            setChangePasswordMessage('Error changing password');
            console.error(error);
        }
    };


    return (
        <div>
            <Header />

            <div className='changeuserpassword-container'>
                <h2>Change User Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor='email'>Email:</label>
                        <input
                            type='email'
                            className='form-control'
                            id='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button className='email-button' type='submit'>Send OTP</button>
                </form>
                {message && <p>{message}</p>}

                <form className='mt-2' onSubmit={handleVerification}>
                    <div className='form-group'>
                        <label htmlFor='otp'>OTP:</label>
                        <input
                            type='text'
                            className='form-control'
                            id='otp'
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                        />
                    </div>
                    <button className='otp-button' type='submit'>Verify OTP</button>
                </form>
                {verificationMessage && <p>{verificationMessage}</p>}

                {/* Display input fields for new password after successful OTP verification */}
                {verificationMessage === 'OTP verification successful' && (
                    <form className='mt-2' onSubmit={handleChangePassword}>
                        <div className='form-group'>
                            <label htmlFor='newPassword'>New Password:</label>
                            <input
                                type='password'
                                className='form-control'
                                id='newPassword'
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <label htmlFor='confirmPassword'>Confirm Password:</label>
                            <input
                                type='password'
                                className='form-control'
                                id='confirmPassword'
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type='submit' className='changepassword-button'>Change Password</button>
                        {changePasswordMessage && <p>{changePasswordMessage}</p>}
                    </form>
                )}
            </div>

            <Footer />
        </div>
    );
}

export default Changeuserpassword;




