import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import Select from 'react-select';

function Register() {
    const [userid, setUserid] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [mobilenumber, setMobilenumber] = useState('');
    const [department, setDepartment] = useState('');
    const [branches, setBranches] = useState([]); // State for storing the fetched branches
    const [selectedBranches, setSelectedBranches] = useState([]); // State for storing the selected branches
    const [image, setImage] = useState(null); // State for storing the selected image file
    const [registerMessage, setRegisterMessage] = useState('');
    const [selectedForms, setSelectedForms] = useState([]);
    const [selectedReports, setSelectedReports] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch branches from the backend
        axios.get('https://test-server-o94j.onrender.com/branches/branches')
            .then(response => {
                setBranches(response.data);
            })
            .catch(error => {
                console.error('Error fetching branches:', error);
            });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('image', image);
            formData.append('username', username);
            formData.append('userid', userid);
            formData.append('password', password);
            formData.append('email', email);
            formData.append('mobilenumber', mobilenumber);
            formData.append('department', department);
            
            // Append each selected branch value individually to formData
            selectedBranches.forEach(branch => {
                formData.append('branches[]', branch.value);
            });

            // Append each selected form value individually to formData
            selectedForms.forEach(form => {
                formData.append('forms[]', form.value);
            });

            // Append each selected report value individually to formData
            selectedReports.forEach(report => {
                formData.append('reports[]', report.value);
            });

            const response = await axios.post('https://test-server-o94j.onrender.com/user/register', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setRegisterMessage(response.data.message);

            setTimeout(() => {
                setRegisterMessage('');
            }, 1000);
        } catch (error) {
            setRegisterMessage('Error during Register: ' + error.response.data.message);
        }
    };

    const handleFormSelect = (selectedOptions) => {
        setSelectedForms(selectedOptions);
    };

    const handleReportSelect = (selectedOptions) => {
        setSelectedReports(selectedOptions);
    };

    const handleBranchSelect = (selectedOptions) => {
        setSelectedBranches(selectedOptions);
    };

    // Function to handle image file selection
    const handleImageChange = (e) => {
        setImage(e.target.files[0]); // Update the image state with the selected file
    };

    return (
        <div>
            <h1 className='head'>USER-REGISTERATION</h1>
            <div className="form-container">
                <div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>User Name</label>
                            <input className='mt-1' required type='text' placeholder='User name' onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>User ID</label>
                            <input className='mt-1' required type='id' placeholder='User id' onChange={(e) => setUserid(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input className='mt-1' required type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Email ID</label>
                            <input className='mt-1' required type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Mobile Number</label>
                            <input className='mt-1' required type='number' placeholder='Mobile Number' onChange={(e) => setMobilenumber(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Forms</label>
                            <Select
                                className='mt-1'
                                isMulti
                                required
                                placeholder="Select Form"
                                styles={{ // Add custom styles to override default height
                                    control: (provided) => ({
                                        ...provided,
                                        height: '45px', // Adjust the height as needed
                                        minHeight: '30px', // Adjust the minHeight as needed
                                    }),
                                }}
                                options={[
                                    { value: 'LEAD SURVEY FORM', label: 'LEAD SURVEY FORM' },
                                    { value: 'LEAD PROCESSING', label: 'LEAD PROCESSING' },
                                ]}
                                value={selectedForms}
                                onChange={handleFormSelect}
                            />
                        </div>
                        <div className="form-group">
                            <label>Reports</label>
                            <Select
                                className='mt-1'
                                isMulti
                                required
                                placeholder="Select Reports"
                                styles={{ // Add custom styles to override default height
                                    control: (provided) => ({
                                        ...provided,
                                        height: '45px', // Adjust the height as needed
                                        minHeight: '30px', // Adjust the minHeight as needed
                                    }),
                                }}
                                options={[
                                    { value: 'LEAD SURVEY FORM REPORT', label: 'LEAD SURVEY FORM REPORT' },
                                ]}
                                value={selectedReports}
                                onChange={handleReportSelect}
                            />
                        </div>
                        <div className="form-group">
                            <label>Department</label>
                            <select required className="mt-1 select-roles" onChange={(e) => setDepartment(e.target.value)}>
                                <option>Department</option>
                                <option value="Admin Department">Admin Department</option>
                                <option value="User Department">User Department</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Branch</label>
                            <Select
                                className='mt-1'
                                isMulti
                                required
                                placeholder="Select Branches"
                                styles={{ // Add custom styles to override default height
                                    control: (provided) => ({
                                        ...provided,
                                        height: '45px', // Adjust the height as needed
                                        minHeight: '30px', // Adjust the minHeight as needed
                                    }),
                                }}
                                options={branches.map(branch => ({ value: branch.branchname, label: branch.branchname }))}
                                value={selectedBranches}
                                onChange={handleBranchSelect}
                            />
                        </div>
                        <div className="form-group">
                            <label>Image</label>
                            <input className='mt-1' type='file' accept="image/*" onChange={handleImageChange} />
                        </div>
                    </div>
                    <Button onClick={(e) => handleSubmit(e)} className='mb-4' size='lg' variant='danger'>Register</Button>
                    {registerMessage && (
                        <div className="alert alert-success mt-3" role="alert">
                            {registerMessage}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Register;


