import React, { useState, useEffect } from 'react';
import './Leadserveyreport.css';
import axios from 'axios';
import Select from 'react-select';
import { Modal, Button, Form } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import companylogo from '../../../../assets/images/logo2.png';

function Leadserveyreport() {
    const [leadserveyData, setLeadserveyData] = useState([]);
    const [users, setUsers] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [product, setProduct] = useState([]);
    const [stateFilter, setStateFilter] = useState([]);
    const [cityFilter, setCityFilter] = useState([]);
    const [areaFilter, setAreaFilter] = useState([]);
    const [usernameFilter, setUsernameFilter] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedLeadSurveyData, setSelectedLeadSurveyData] = useState(null);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updatedCustName, setUpdatedCustName] = useState('');
    const [updatedSince, setUpdatedSince] = useState('');
    const [updatedBizType, setUpdatedBizType] = useState('');
    const [updatedBizCatge, setUpdatedBizCatge] = useState('')
    const [updatedFName, setUpdatedFName] = useState('');
    const [updatedLName, setUpdatedLName] = useState('');
    const [updatedAddr1, setUpdatedAddr1] = useState('');
    const [updatedAddr2, setUpdatedAddr2] = useState('');
    const [updatedState, setUpdatedState] = useState('');
    const [updatedCity, setUpdatedCity] = useState('');
    const [updatedArea, setUpdatedArea] = useState('');
    const [updatedPincode, setUpdatedPincode] = useState('');
    const [updatedMobNum, setUpdatedMobNum] = useState('');
    const [updatedAltMobNum, setUpdatedAltMobNum] = useState('');
    const [updatedEmailId, setUpdatedEmailId] = useState('');
    const [updatedBizExp, setUpdatedBizExp] = useState('');
    const [updatedCurrAddSince, setUpdatedCurrAddSince] = useState('');
    const [updatedBizHourFrom, setUpdatedBizHourFrom] = useState('');
    const [updatedBizHourTo, setUpdatedBizHourTo] = useState('');
    const [updatedApproxQtyBuyinKg, setUpdatedApproxQtyBuyinKg] = useState('');
    const [updatedDaPurval, setUpdatedDaPurval] = useState('');
    const [updatedKeyskus, setUpdatedKeyskus] = useState('');

    const fetchleadservey = () => {
        axios.get('https://test-server-o94j.onrender.com/leadservey/leadserveys')
            .then(response => response.data)
            .then(data => setLeadserveyData(data))
            .catch(error => console.error('Error fetching leadservey:', error));
    };

    const fetchusers = () => {
        axios.get('https://test-server-o94j.onrender.com/user/users')
            .then(response => response.data)
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    };

    const fetchState = () => {
        axios.get('https://test-server-o94j.onrender.com/state/states')
            .then(response => response.data)
            .then(data => setState(data))
            .catch(error => console.error('Error fetching state:', error))
    }

    const fetchcity = () => {
        axios.get('https://test-server-o94j.onrender.com/city/cities')
            .then(response => response.data)
            .then(data => setCity(data))
            .catch(error => console.error('Errro fetching cities', error))
    }

    const fetcharea = () => {
        axios.get('https://test-server-o94j.onrender.com/area/areas')
            .then(response => response.data)
            .then(data => setArea(data))
            .catch(error => console.error('Error fetching areas', error))
    }

    const fetchproduct = () => {
        axios.get('https://test-server-o94j.onrender.com/products/products')
            .then(response => response.data)
            .then(data => setProduct(data))
            .catch(error => console.error('Error fetching product', error))
    }

    useEffect(() => {
        fetchleadservey();
        fetchusers();
        fetchState();
        fetchcity();
        fetcharea();
        fetchproduct();
    }, []);

    const handleStateFilterChange = selectedOptions => {
        setStateFilter(selectedOptions);
    };

    const handleCityFilterChange = selectedOptions => {
        setCityFilter(selectedOptions);
    };

    const handleAreaFilterChange = selectedOptions => {
        setAreaFilter(selectedOptions);
    };

    const handleUsernameFilterChange = selectedOptions => {
        setUsernameFilter(selectedOptions);
    };

    const handleStartDateChange = date => {
        setStartDate(date);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
    };

    const handleDeleteIconClick = (item) => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirmation = () => {
        axios.post('https://test-server-o94j.onrender.com/leadservey/delete-leadservey', {
            leadserveyid: selectedItem.leadserveyid,
        })
            .then(() => {
                fetchleadservey()
                setSelectedItem(null)
                setShowDeleteModal(false)
            })
            .catch(error => console.error('Error deleting leadservey:', error));
    }

    const handleDeleteAllLeadserveys = () => {
        axios.post('https://test-server-o94j.onrender.com/leadservey/delete-all-leadservey')
            .then(() => {
                fetchleadservey();
                setShowDeleteAllModal(false);
            })
            .catch(error => console.error('Error deleting all leadserveys:', error));
    };

    const filteredItems = leadserveyData.filter(item => {
        if (startDate && endDate) {
            const itemDateTime = new Date(item.createdAt).getTime();
            const startDateTime = new Date(startDate).getTime();
            const endDateTime = new Date(endDate).getTime();
            return itemDateTime >= startDateTime && itemDateTime <= endDateTime;
        }
        return true;
    }).filter(item =>
        (stateFilter.length === 0 || stateFilter.some(state => state.value === item.State)) &&
        (cityFilter.length === 0 || cityFilter.some(city => city.value === item.City)) &&
        (areaFilter.length === 0 || areaFilter.some(area => area.value === item.Area)) &&
        (usernameFilter.length === 0 || usernameFilter.some(username => username.value === item.username))
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const imageToBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            };
            img.onerror = (err) => {
                reject(err);
            };
        });
    };


    const downloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });

        const logo = new Image();
        logo.src = companylogo;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        logo.onload = async function () {
            canvas.width = logo.width;
            canvas.height = logo.height;
            ctx.drawImage(logo, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            doc.addImage(dataURL, 'PNG', 5, 2, 18, 12);

            doc.setFontSize(12);
            const companyName = 'PRIME FRESH LIMITED (Vashi/DC)';
            const companyNameX = 24;
            const companyNameY = 6;
            doc.text(companyName, companyNameX, companyNameY);

            const companyNameWidth = doc.getTextWidth(companyName);
            const lineX1 = companyNameX;
            const lineX2 = companyNameX + companyNameWidth;
            const lineY = companyNameY + 1;
            doc.line(lineX1, lineY, lineX2, lineY);

            const leadReportText = 'Lead Survey Reports';
            const leadReportTextX = companyNameX;
            const leadReportTextY = lineY + 5;
            doc.setFontSize(12);
            doc.text(leadReportText, leadReportTextX, leadReportTextY);

            doc.setFontSize(12);
            const currentDate = new Date().toLocaleDateString('en-IN');
            doc.text('Date: ' + currentDate, doc.internal.pageSize.width - 15, 7, null, null, 'right');

            const tableColumns = [
                "LsId", "LsDate", "BussinessName / CustomerName", "Area", "City", "State", "Mobile", "Email"
            ];
            const tableRows = [];
            filteredItems.forEach((item, index) => {
                const lsId = item.BizCatge === 'General Trade' ? `GT/${item.leadserveyid}` : `MT/${item.leadserveyid}`;
                const lsDate = new Date(item.createdAt).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: '2-digit' });
                const rowData = [
                    lsId,
                    lsDate,
                    `${item.CustName} / ${item.FName + item.LName}`,
                    item.Area,
                    item.City,
                    item.State,
                    { content: item.MobNum, fontStyle: 'courier', fontSize: '1rem' },
                    item.EmailId,
                ];
                tableRows.push(rowData);
            });

            const totalRecords = tableRows.length;
            const recordsPerPage = 43;

            doc.autoTable({
                head: [tableColumns],
                body: tableRows,
                startY: leadReportTextY + 3,
                margin: { left: 5 },
                didParseCell: function (data) {
                    const cell = data.cell;
                    if (tableColumns.includes(data.cell.raw)) {
                        cell.styles.fillColor = [204, 204, 204];
                        cell.styles.textColor = [0, 0, 0];
                        cell.styles.fontSize = 9;
                        cell.styles.fontStyle = 'bold';
                        cell.styles.halign = 'center';
                    } else {
                        cell.styles.fillColor = [255, 255, 255];
                        cell.styles.textColor = [0, 0, 0];
                        cell.styles.fontSize = 6;
                        if (data.column.index === 1 || data.column.index === 6) {
                            cell.styles.font = 'courier';
                        }
                    }
                    cell.styles.lineWidth = 0.2;
                    cell.styles.lineColor = [0, 0, 0];
                },
                didDrawPage: function (data) {
                    const pageCount = doc.internal.getNumberOfPages();
                    const pageNumber = data.pageNumber;
                    const pageHeight = doc.internal.pageSize.height;

                    const startRecord = (pageNumber - 1) * recordsPerPage + 1;
                    const endRecord = Math.min(pageNumber * recordsPerPage, totalRecords);
                    const recordsText = `${startRecord} to ${endRecord}`;

                    const pageWidth = doc.internal.pageSize.width;
                    const marginRight = 15;
                    const marginLeft = 200;


                    doc.setFontSize(10);
                    doc.text(`Page ${pageNumber} of ${pageCount}`, pageWidth - marginLeft, pageHeight - 14, null, null, 'left');
                    doc.text(`No of Records: ${recordsText}`, pageWidth - marginRight, pageHeight - 14, null, null, 'right');
                }
            });

            doc.setLineWidth(0.5);
            doc.line(10, doc.internal.pageSize.height - 20, doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 20);
            const preparedBy = 'admin';
            const currentDateFooter = new Date().toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });

            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const ipAddress = response.data.ip;
                const footerText = `Prepared by ${preparedBy} on ${currentDate} ${currentDateFooter} | IP Address: ${ipAddress}`;
                doc.setFontSize(10);
                doc.text('Prime Fresh Limited (Vashi/DC)', 10, doc.internal.pageSize.height - 9);
                doc.setFontSize(10);
                doc.text(footerText, 10, doc.internal.pageSize.height - 4);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }

            doc.save('lead_survey_reports.pdf');
        };
    };

    const downloadExcel = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Lead Survey Reports');

            // Adding company logo
            const logoBase64 = await imageToBase64(companylogo);
            const logoImage = workbook.addImage({
                base64: logoBase64,
                extension: 'png',
            });

            worksheet.addImage(logoImage, {
                tl: { col: 0, row: 0 },
                ext: { width: 150, height: 100 }
            });

            worksheet.mergeCells('A1:K1');

            const logoCell = worksheet.getCell('A1');
            logoCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFFFF' } // White color
            };
            logoCell.font = {
                color: { argb: 'FFFFFFFF' }, // Black color
                size: 16,
                bold: true
            };

            worksheet.mergeCells('A2:I2');
            const companyCell = worksheet.getCell('A2');
            companyCell.value = 'PRIME FRESH LIMITED (Vashi/DC)';
            companyCell.font = { size: 16, bold: true };
            companyCell.alignment = { vertical: 'middle', horizontal: 'center' };

            const currentDates = new Date().toLocaleDateString('en-IN');
            worksheet.mergeCells('J2:K2');
            const companyCells = worksheet.getCell('J2');
            companyCells.value = `Date: ${currentDates}`;
            companyCells.font = { size: 16, bold: true };
            companyCells.alignment = { vertical: 'middle', horizontal: 'center' };

            worksheet.mergeCells('A3:I3');
            const reportCell = worksheet.getCell('A3');
            reportCell.value = 'Lead Survey Reports';
            reportCell.font = { size: 14, bold: true };
            reportCell.alignment = { vertical: 'middle', horizontal: 'center' };

            // Adding spacing rows
            worksheet.addRow([]);
            worksheet.addRow([]);

            // Defining columns
            const excelColumns = [
                { header: 'Sr.No', key: 'srno', width: 10 },
                { header: 'Ls ID', key: 'lsid', width: 10 },
                { header: 'Date', key: 'date', width: 10 },
                { header: 'CustName', key: 'custname', width: 20 },
                { header: 'FName', key: 'fname', width: 15 },
                { header: 'LName', key: 'lname', width: 15 },
                { header: 'State', key: 'state', width: 15 },
                { header: 'City', key: 'city', width: 15 },
                { header: 'Area', key: 'area', width: 15 },
                { header: 'MobNum', key: 'mobnum', width: 10 },
                { header: 'EmailId', key: 'emailid', width: 10 },
            ];

            worksheet.columns = excelColumns;

            // Adding header row with styles
            const headerRow = worksheet.addRow(excelColumns.map(col => col.header));
            headerRow.eachCell((cell) => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF000000' }
                };
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });

            // Adding data rows
            filteredItems.forEach((item, index) => {
                const row = worksheet.addRow({
                    srno: index + 1,
                    lsid: item.leadserveyid,
                    date: new Date(item.createdAt).toLocaleDateString(),
                    custname: item.CustName,
                    fname: item.FName,
                    lname: item.LName,
                    state: item.State,
                    city: item.City,
                    area: item.Area,
                    mobnum: item.MobNum,
                    emailid: item.EmailId,
                });
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                });
            });

            // Adding footer
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString();
            const currentDateFooter = currentDate.toLocaleString('en-IN', { weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true });
            const preparedBy = 'admin';

            const response = await axios.get('https://api.ipify.org?format=json');
            const ipAddress = response.data.ip;

            const footerText = `Prepared by ${preparedBy} on ${formattedDate}, ${currentDateFooter}  | IP Address: ${ipAddress}`;

            // Add an empty row before footer
            worksheet.addRow([]);
            const footerRow = worksheet.addRow([footerText]);

            // Merge cells for the footer
            const lastRowNumber = footerRow.number;
            worksheet.mergeCells(`A${lastRowNumber}:K${lastRowNumber}`);

            footerRow.getCell(1).font = { italic: true };
            footerRow.alignment = { vertical: 'middle', horizontal: 'center' };

            // Adjust column widths
            worksheet.columns.forEach((column) => {
                if (column.header) {
                    column.width = Math.max(10, column.header.length + 10);
                }
            });

            // Generate and download the Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'lead_survey_reports.xlsx';
            link.click();
        } catch (error) {
            console.error("Error generating Excel file:", error);
        }
    };



    const handleShowPreview = (leadSurveyData) => {
        setSelectedLeadSurveyData(leadSurveyData);
        setShowPreview(true);
    };

    const handleClosePreview = () => setShowPreview(false);

    const handleUpdateIconClick = (item) => {
        setSelectedItem(item);
        setUpdatedCustName(item.CustName);
        setUpdatedSince(item.Since);
        setUpdatedBizType(item.BizType);
        setUpdatedBizCatge(item.BizCatge)
        setUpdatedFName(item.FName);
        setUpdatedLName(item.LName);
        setUpdatedAddr1(item.Addr1);
        setUpdatedAddr2(item.Addr2);
        setUpdatedState(item.State);
        setUpdatedCity(item.City);
        setUpdatedArea(item.Area);
        setUpdatedPincode(item.PinCode);
        setUpdatedMobNum(item.MobNum);
        setUpdatedAltMobNum(item.AltMobNum);
        setUpdatedEmailId(item.EmailId);
        setUpdatedBizExp(item.BizExp);
        setUpdatedCurrAddSince(item.CurrAddSince);
        setUpdatedBizHourFrom(item.BizHourFrom);
        setUpdatedBizHourTo(item.BizHourTo);
        setUpdatedApproxQtyBuyinKg(item.ApproxQtyBuyinKg);
        setUpdatedDaPurval(item.DaPurVal);
        setUpdatedKeyskus(item.KeySkus);
        setShowUpdateModal(true);
    }
    
    const handleUpdateSubmit = () => {
        axios.post('https://test-server-o94j.onrender.com/leadservey/update-leadservey', {
            leadserveyid: selectedItem.leadserveyid,
            newCustName: updatedCustName,
            newSince: updatedSince,
            newBizType: updatedBizType,
            newBizCatge: updatedBizCatge,
            newFName: updatedFName,
            newLName: updatedLName,
            newAddr1: updatedAddr1,
            newAddr2: updatedAddr2,
            newState: updatedState,
            newCity: updatedCity,
            newArea: updatedArea,
            newPincode: updatedPincode,
            newMobNum: updatedMobNum,
            newAltMobNum: updatedAltMobNum,
            newEmailId: updatedEmailId,
            newBizExp: updatedBizExp,
            newCurrAddSince: updatedCurrAddSince,
            newBizHourFrom: updatedBizHourFrom,
            newBizHourTo: updatedBizHourTo,
            newApproxQtyBuyinKg: updatedApproxQtyBuyinKg,
            newDaPurval: updatedDaPurval,
            newKeyskus: updatedKeyskus
        })
            .then(() => {
                fetchleadservey()
                setShowUpdateModal(false);
            })
            .catch(error => console.error('Error updating leeadservey:', error));
    }
    const printUserDetails = () => {
        const printContent = document.getElementById('printleadsurvey').innerHTML;
        const originalContent = document.body.innerHTML;

        // Get the current date and week starting day
        const currentDate = new Date().toLocaleDateString('en-IN');

        const printableContentWithLogo = `
        <div style="font-family: Arial, sans-serif; margin-left: 20px;">
            <div style="display: flex; align-items: center;  margin-bottom: 10px;">
                <img src="${companylogo}" alt="Company Logo" style="width: 100px; height: auto; margin-right: 20px;">
                    <div>
                        <h1 style="font-size: 18px; margin: 0;">Prime Fresh Limited (Vashi/DC)</h1>
                        <hr style="margin: 5px 0;">
                        <h2 style="font-size: 16px; margin: 0;">Lead Processing Reports</h2>
                    </div>
                <div>
                    <h1 style="margin-left: 270px;">${currentDate}</h1>
                </div>
            </div>
            ${printContent}
            <div>
                <div class="print-footer">
                  <hr style="border: 0; border-top: 0.5px solid #000;">
                  <div style="padding: 5px 10px; text-align: left; font-size: 10px; margin-top: -10px;">
                  <p>Prime Fresh Limited (Vashi/DC)</p>
                  <p style="margin-top: -8px;">Prepared by admin on ${currentDate} ${currentTime},${weekStartingDay}</p>
               </div>
            </div>
        </div>
    `;


        // Combine printable content with the footer
        const printableContentWithFooter = printableContentWithLogo;

        // Replace the current body content with the printable content including the logo, company name, hr, and user details
        document.body.innerHTML = printableContentWithFooter;

        // Print the content
        window.print();

        // Restore the original content after printing
        document.body.innerHTML = originalContent;
        window.location.reload(); // Refresh the page to restore original content
    };

    // Function to get the starting day of the week
    function getWeekStartingDay() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = new Date().getDay();
        return days[currentDayIndex];
    }

    const currentTime = new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const currentDate = new Date().toLocaleDateString('en-IN');
    const weekStartingDay = getWeekStartingDay();

    return (
        <div className='leadserveyreports'>
            <h1 className='head'>LEAD SURVEY REPORTS</h1>
            <div className='leadserveyreport-table'>
                <div className='leadservey-pdfexcel-button'>
                    <Button onClick={downloadPDF}>
                        <i className="fa-solid fa-file-pdf fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={downloadExcel}>
                        <i className="fa-solid fa-file-excel fa-fade fa-xl"></i>
                    </Button>
                    <Button className='mx-2' onClick={printUserDetails}>
                        <i className="fa-solid fa-print fa-fade fa-xl"></i>
                    </Button>
                    <Button variant="danger" onClick={() => setShowDeleteAllModal(true)}>
                        <i className="fa-solid fa-trash fa-fade fa-xl" style={{ color: "#050505" }}></i>
                    </Button>
                </div>
                <div className='leadserveyreport-forms' style={{ fontSize: '0.8rem' }}>
                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter States</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='State'
                            options={state.map(state => ({
                                value: state.statename,
                                label: state.statename
                            }))}
                            value={stateFilter}
                            onChange={handleStateFilterChange}
                        />
                    </div>

                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Cities</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='City'
                            options={city.map(city => ({
                                value: city.cityname,
                                label: city.cityname
                            }))}
                            value={cityFilter}
                            onChange={handleCityFilterChange}
                        />
                    </div>


                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Areas</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Area'
                            options={area.map(area => ({
                                value: area.areaname,
                                label: area.areaname
                            }))}
                            value={areaFilter}
                            onChange={handleAreaFilterChange}
                        />
                    </div>


                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Username</label>
                        <Select
                            className='mt-2'
                            isMulti
                            placeholder='Username'
                            options={users.map(user => ({
                                value: user.username,
                                label: user.username
                            }))}
                            value={usernameFilter}
                            onChange={handleUsernameFilterChange}
                        />
                    </div>
                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Date:</label>
                        <Form.Group>
                            <Form.Control
                                className='mt-2'
                                type="date"
                                value={startDate}
                                onChange={(e) => handleStartDateChange(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                    <div className='flex items-center justify-end rounded-5 mx-2'>
                        <label>Filter Date:</label>
                        <Form.Group>
                            <Form.Control
                                className='mt-2'
                                type="date"
                                value={endDate}
                                onChange={(e) => handleEndDateChange(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                </div>
                <Table className="mt-2 custom-table" style={{ fontSize: '0.8rem' }} striped bordered hover responsive>
                    <thead>
                        <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <th>SR.NO</th>
                            <th>LSID</th>
                            <th>LSDATE</th>
                            <th>SUBMITTEDBY</th>
                            <th>BIZNAME</th>
                            <th>CUSTNAME</th>
                            <th>MOBNUM</th>
                            <th>STATE</th>
                            <th>CITY</th>
                            <th>AREA</th>
                            <th>PREVIEW</th>
                            <th>DELETE</th>
                            <th>UPDATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.leadserveyid}</td>
                                <td>{new Date(item.createdAt).toLocaleDateString('en-IN')}</td>
                                <td>{item.username}</td>
                                <td>{item.CustName}</td>
                                <td>{item.FName} {item.LName}</td>
                                <td>{item.MobNum}</td>
                                <td>{item.State}</td>
                                <td>{item.City}</td>
                                <td>{item.Area}</td>
                                <td>
                                    <button onClick={() => handleShowPreview(item)} className='btn btn-warning view'>
                                        <i className="fa-solid fa-eye fa-beat fa-xl"></i>
                                    </button>
                                </td>
                                <td>
                                    <button onClick={() => handleDeleteIconClick(item)} className='btn btn-danger delete'>
                                        <i className="fa-solid fa-trash fa-fade fa-xl" style={{ color: "#050505" }}></i>
                                    </button>
                                </td>
                                <td>
                                    <a onClick={() => handleUpdateIconClick(item)}>
                                        <button className='btn btn-light'>
                                            <i className="fa-solid fa-pen fa-beat fa-xl" style={{ color: "blue" }}></i>
                                        </button>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="pagination-container">
                    {Array.from({ length: Math.ceil(filteredItems.length / itemsPerPage) }, (_, index) => (
                        <button
                            className="pagination-button mx-2"
                            key={index}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                <div className="total-records" style={{ textAlign: 'center', marginTop: '1rem', fontWeight: 'bold' }}>
                    Total No. of Records: {filteredItems.length}
                </div>
            </div>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this leadservey?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirmation}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteAllModal} onHide={() => setShowDeleteAllModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete All LeadServey</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete all leadserveys?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteAllModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteAllLeadserveys}>
                        Delete All
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPreview} onHide={handleClosePreview} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Lead Survey Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedLeadSurveyData && <LeadserveyPreview leadserveyData={selectedLeadSurveyData} />}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={handleClosePreview}>
                        Back
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>UPDATE LEAD SURVEY</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col">
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Customer Name</Form.Label>
                                    <Form.Control type="text" value={updatedCustName} onChange={(e) => setUpdatedCustName(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Since</Form.Label>
                                    <Form.Control type="Number" value={updatedSince} onChange={(e) => setUpdatedSince(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Bussiness Type</Form.Label>
                                    <Form.Select value={updatedBizType} onChange={(e) => setUpdatedBizType(e.target.value)}>
                                        <option value="">Select Bussiness Type</option>
                                        <option value="Proprietor">Proprietor</option>
                                        <option value="Partnership">Partnership</option>
                                        <option value="LLP(Limited Liability Partnership)">LLP(Limited Liability Partnership)</option>
                                        <option value="PVT LTD(Private Limited)">PVT LTD(Private Limited)</option>
                                        <option value="LTD(Limited Company)">LTD(Limited Company)</option>
                                        <option value="HUF(Hindu Undivided Family)">HUF(Hindu Undivided Family)</option>
                                        <option value="Ownership">Ownership</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Bussiness Category</Form.Label>
                                    <Form.Control type="text" value={updatedBizCatge} onChange={(e) => setUpdatedBizType(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New First Name</Form.Label>
                                    <Form.Control type="text" value={updatedFName} onChange={(e) => setUpdatedFName(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Last Name</Form.Label>
                                    <Form.Control type="text" value={updatedLName} onChange={(e) => setUpdatedLName(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Address 1</Form.Label>
                                    <Form.Control type="text" value={updatedAddr1} onChange={(e) => setUpdatedAddr1(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Address 2</Form.Label>
                                    <Form.Control type="text" value={updatedAddr2} onChange={(e) => setUpdatedAddr2(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col">
                                <Form.Group controlId="formState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select value={updatedState} onChange={(e) => setUpdatedState(e.target.value)}>
                                        <option value="">Select State</option>
                                        {state.map((stateItem, index) => (
                                            <option key={index} value={stateItem.statename}>{stateItem.statename}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group controlId="formCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select value={updatedCity} onChange={(e) => setUpdatedCity(e.target.value)}>
                                        <option value="">Select City</option>
                                        {city.map((cityItem, index) => (
                                            <option key={index} value={cityItem.cityname}>{cityItem.cityname}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className='col'>
                                <Form.Group controlId="formArea">
                                    <Form.Label>Area</Form.Label>
                                    <Form.Select value={updatedArea} onChange={(e) => setUpdatedArea(e.target.value)}>
                                        <option value="">Select Area</option>
                                        {area.map((areaItem, index) => (
                                            <option key={index} value={areaItem.areaname}>{areaItem.areaname}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Pincode</Form.Label>
                                    <Form.Control type="Number" value={updatedPincode} onChange={(e) => setUpdatedPincode(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Mobile Number</Form.Label>
                                    <Form.Control type="Number" value={updatedMobNum} onChange={(e) => setUpdatedMobNum(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Alternate Mobile Number</Form.Label>
                                    <Form.Control type="Number" value={updatedAltMobNum} onChange={(e) => setUpdatedAltMobNum(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Email Id</Form.Label>
                                    <Form.Control type="text" value={updatedEmailId} onChange={(e) => setUpdatedEmailId(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Bussiness Experience</Form.Label>
                                    <Form.Control type="text" value={updatedBizExp} onChange={(e) => setUpdatedBizExp(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Current Address Since</Form.Label>
                                    <Form.Control type="Number" value={updatedCurrAddSince} onChange={(e) => setUpdatedCurrAddSince(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Bussiness Hour From</Form.Label>
                                    <Form.Control type="time" value={updatedBizHourFrom} onChange={(e) => setUpdatedBizHourFrom(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Bussiness Hour From</Form.Label>
                                    <Form.Control type="time" value={updatedBizHourTo} onChange={(e) => setUpdatedBizHourTo(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Approx Quantity Buy In Kg</Form.Label>
                                    <Form.Control type="text" value={updatedApproxQtyBuyinKg} onChange={(e) => setUpdatedApproxQtyBuyinKg(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Daily Purchase Value</Form.Label>
                                    <Form.Control type="Number" value={updatedDaPurval} onChange={(e) => setUpdatedDaPurval(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>New Key Sku's</Form.Label>
                                    <Form.Control type="text" value={updatedKeyskus} onChange={(e) => setUpdatedKeyskus(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpdateSubmit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="printleadsurvey" style={{ display: 'none' }}>
                <Table>
                    <thead>
                        <tr style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.6rem' }}>
                            <th>Sr.No</th>
                            <th>LSID</th>
                            <th>LSDATE</th>
                            <th>BussinessName / CustomerName</th>
                            <th>MOBNUM</th>
                            <th>STATE</th>
                            <th>CITY</th>
                            <th>AREA</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems.map((item, index) => (
                            <tr key={index} style={{ fontSize: '0.5rem' }}>
                                <td>{index + 1}</td>
                                <td>{item.leadserveyid}</td>
                                <td>{new Date(item.createdAt).toLocaleDateString('en-IN')}</td>
                                <td>{item.CustName} / {item.FName} {item.LName}</td>
                                <td>{item.State}</td>
                                <td style={{ width: '10%' }}>{item.City}</td>
                                <td>{item.Area}</td>
                                <td>{item.MobNum}</td>
                                <td>{item.EmailId}</td>
                            </tr>
                        ))}
                        {/* Footer Row */}
                        {/* <tr>
                            <td colSpan="8" style={{ fontSize: '0.5rem' }}>
                                Prepared by admin on {currentDate} {currentTime},{weekStartingDay}
                            </td>
                        </tr> */}
                    </tbody>
                </Table>
            </div>
        </div >
    );
}

function LeadserveyPreview({ leadserveyData }) {
    return (
        <div style={{ fontSize: '0.8rem', display: 'flex', flexDirection: 'column' }}>
            <div className='form-details' style={{ lineHeight: '1' }}>
                <p className='company-details'>
                    <strong>Company Name : M/s.</strong> {leadserveyData.CustName} ({leadserveyData.Since})
                </p>
                <p className='bussiness-type'>
                    <strong>Bussiness Type : </strong> {leadserveyData.BizType}
                </p>
                <p className='bussiness-category'>
                    <strong>Bussiness Category : </strong> {leadserveyData.BizCatge}
                </p>
                <p className='bussiness-experience'>
                    <strong>Bussiness Experience : </strong> {leadserveyData.BizExp}
                </p>
                <p className='fl'>
                    <strong>Customer Name : Mr/Ms</strong> {leadserveyData.FName} {leadserveyData.LName}
                </p>
                <p className='addr1'>
                    <strong>Address :</strong>
                    <span className="address-data">
                        {leadserveyData.Addr1}, {leadserveyData.Addr2}
                    </span>
                </p>
                <p className='acpsc'>
                    {leadserveyData.Area}, {leadserveyData.City} - {leadserveyData.PinCode},  {leadserveyData.State} ({leadserveyData.Country})
                </p>
                <p className='MobNum'>
                    <strong>Mob :</strong> {leadserveyData.MobNum},  {leadserveyData.AltMobNum}
                </p>
                <p className='EmailId'>
                    <strong>Email</strong> {leadserveyData.EmailId}
                </p>
            </div>
            <hr />
            <div className='form-details2' style={{ lineHeight: '1' }}>
                <p className='current-address-since'>
                    <strong>Current Address Since Years : </strong> {leadserveyData.CurrAddSince}
                </p>
                <p className='bussiness-hour'>
                    <strong>Bussiness Hour : </strong> {leadserveyData.BizHourFrom} AM to {leadserveyData.BizHourTo} PM
                </p>
                <p className='approx-quantity-buy-in-kg'>
                    <strong>Approx Quantity Buy In Kg : </strong> {leadserveyData.ApproxQtyBuyinKg}
                </p>
                <p className='daily-purchase-value'>
                    <strong>Daily Purchase Value : </strong> {leadserveyData.DaPurVal}
                </p>
                <p className='key-skus'>
                    <strong>Key SKU's : </strong> {leadserveyData.KeySkus ? leadserveyData.KeySkus.join(',') : ''}
                </p>

            </div>
        </div>
    );
}

export default Leadserveyreport;

