import React from 'react'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'
import './Faq.css';

function Faq() {
    return (
        <div>
            <Header />
            <div className='user-faq-container'>
                <h3 className='user-faq-heading'>Faq's</h3>
            </div>

            <div className='footers'>
                <Footer />
            </div>
        </div>
    )
}

export default Faq