import React from 'react'
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import Carousel from 'react-bootstrap/Carousel';
import './Contactus.css';

function Contactus() {
    return (
        <div>
            <Header />
            <div className='user-contactus-container'>
                <h3 className='user-contactus-heading'>Contact Us</h3>
            </div>
            <div>
                <Carousel data-bs-theme="dark">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="https://img.freepik.com/free-photo/tasty-fruits-assortment-textures-collage_23-2149763842.jpg?t=st=1714122546~exp=1714126146~hmac=c493fd0c1ae3992d98d629783f3243fcabe434bba8a4c062d81c9fe66dc38174&w=1380"
                            alt="Second slide"
                        />
                        <Carousel.Caption>

                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="https://img.freepik.com/free-photo/flat-lay-tasty-fruits-textures-collage_23-2149763827.jpg?t=st=1713974422~exp=1713978022~hmac=b8f93cac6747b92d4156be0fe870fc93ac5b190fd84bd343c9f85eaab62ff81c&w=1380"
                            alt="Third slide"
                        />
                        <Carousel.Caption>

                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="https://img.freepik.com/free-photo/tasty-fruits-textures-collage-flat-lay_23-2149763830.jpg?t=st=1714123380~exp=1714126980~hmac=f34468afa59174c8adab48282f1191a0833a494624ff38dc863f79521210db1f&w=1800"
                            alt="Third slide"
                        />
                        <Carousel.Caption>

                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            <div className='footers'>
                <Footer />
            </div>
        </div>
    )
}

export default Contactus