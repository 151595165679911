
import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Dashboard from './components/dashboard/Dashboard';
import Category from './components/category/Category';
import Subcategory from './components/subcategory/Subcategory';
import Packing from './components/packing/Packing';
import Product from './components/product/Product';
import Register from './components/register/Register';
import Login from './components/login/Login';
import Frontpage from './components/frontpage/Frontpage';
import Report from './components/report/Report';
import Leadservey from './components/forms/leadservey/Leadservey';
import Leadserveyreport from './components/report/leadserveyoffline/leadserveyreport/Leadserveyreport';
import Userreportleadserveyform from './components/userreport/userreportleadserveyform/Userreportleadserveyform';
import State from './components/state/State';
import City from './components/city/City';
import Area from './components/area/Area';
import Leadprocessingreport from './components/report/leadserveyoffline/leadprocessingreport/Leadprocessingreport';
import Leadprocessing from './components/forms/leadprocessing/Leadprocessing';
import Userparticularleadserveyform from './components/userreportforparticularform/userparticularleadserveyform.js/Userparticularleadserveyform';
import Loader from './components/loader/Loader';
import Userdetails from './components/userdetails/Userdetails';
import Particularuser from './components/userdetails/particularuser/Particularuser';
import Aboutus from './components/profile/aboutus/Aboutus';
import Changeuserpassword from './components/profile/changeuserpassword/Changeuserpassword';
import Contactus from './components/profile/contactus/Contactus';
import Faq from './components/profile/faq/Faq';
import Userchat from './components/profile/userchat/Userchat';
import Userprofile from './components/profile/userprofile/Userprofile';
import Leadprogressreport from './components/report/leadserveyoffline/leadprogressreport/Leadprogressreport';
import Assigning from './components/assigning/leadassigning/Assigning';
import Pagenotfound from './components/pagenotfound/Pagenotfound';
import Homepage from './components/home/homepage/Homepage';
import Branches from './components/branches/Branches';


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    // Check if the user is logged in by checking the token in localStorage
    const token = localStorage.getItem('token');
    const department = localStorage.getItem('department');
    setIsLoggedIn(token); // Update isLoggedIn based on token presence
    setIsAdmin(department === 'Admin Department');
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {showLoader ? (
        <Loader />
      ) : (
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/dashboard" element={isLoggedIn && isAdmin ? <Sidebar><Dashboard /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/category" element={isLoggedIn && isAdmin ? <Sidebar><Category /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/subcategory" element={isLoggedIn && isAdmin ? <Sidebar><Subcategory /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/packing" element={isLoggedIn && isAdmin ? <Sidebar><Packing /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/product" element={isLoggedIn && isAdmin ? <Sidebar><Product /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/state" element={isLoggedIn && isAdmin ? <Sidebar><State /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/city" element={isLoggedIn && isAdmin ? <Sidebar><City /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/area" element={isLoggedIn && isAdmin ? <Sidebar><Area /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/register" element={isLoggedIn && isAdmin ? <Sidebar><Register /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/branches" element={isLoggedIn && isAdmin ? <Sidebar><Branches/></Sidebar> : <Navigate to="/login" />}/>
            <Route path="/reports" element={isLoggedIn && isAdmin ? <Sidebar><Report /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/assigning" element={isLoggedIn && isAdmin ? <Sidebar><Assigning /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/leadservey-form-report" element={isLoggedIn && isAdmin ? <Sidebar><Leadserveyreport /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/leadprocessing-report" element={isLoggedIn && isAdmin ? <Sidebar><Leadprocessingreport /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/leadprogressing-report" element={isLoggedIn && isAdmin ? <Sidebar><Leadprogressreport /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/userdetails" element={isLoggedIn && isAdmin ? <Sidebar><Userdetails /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/user/:userid" element={isLoggedIn && isAdmin ? <Sidebar><Particularuser /></Sidebar> : <Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/lead-servey-form" element={isLoggedIn ? <Leadservey /> : <Navigate to="/login" />} />
            <Route path="/lead-processing" element={isLoggedIn ? <Leadprocessing /> : <Navigate to="/login" />} />
            <Route path="/frontpage" element={isLoggedIn ? <Frontpage /> : <Navigate to="/login" />} />
            <Route path="/lead-servey-form-report" element={isLoggedIn ? <Userreportleadserveyform /> : <Navigate to="/login" />} />
            <Route path="/particular-leadservey-reports/:leadserveyid" element={isLoggedIn ? <Userparticularleadserveyform /> : <Navigate to="/login" />} />
            <Route path="/aboutus" element={isLoggedIn ? <Aboutus /> : <Navigate to="/login" />} />
            <Route path="/changeuserpassword" element={isLoggedIn ? <Changeuserpassword /> : <Navigate to="/login" />} />
            <Route path="/contactus" element={isLoggedIn ? <Contactus /> : <Navigate to="/login" />} />
            <Route path="/faq" element={isLoggedIn ? <Faq /> : <Navigate to="/login" />} />
            <Route path="/userchat" element={isLoggedIn ? <Userchat /> : <Navigate to="/login" />} />
            <Route path="/userprofile" element={isLoggedIn ? <Userprofile /> : <Navigate to="/login" />} />
            <Route path="*" element={<Pagenotfound />} />
          </Routes>
        </BrowserRouter>
      )}
  
    </div>
  );
}

export default App;


